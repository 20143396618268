import axios from "axios";

export const fetchData = async () => {
  // Define the fetchData function
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/assistance",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching data from API: " + error.message);
  }
};

export const Repondre = async (data) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/assistance/repondre/${data.id}`,
      {
        Reponse: data.Reponse,
        Email: data.Email,
        Sujet: data.Sujet,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    if (response.status === 200) {
      alert("Message envoyé avec succés");
    }
  } catch (error) {
    console.error(error);
    alert("Erreur lors de l'ajout du message");
    // Handle the error or show an error reponse to the user
  }
};
