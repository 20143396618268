import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { registerUser } from "../../../actions/authActions";
import Header from "../../../components/header/Header";
import "../Auth.css";

import Footer from "../../../components/footer/Footer";
import RegisterForm from "../../../components/auth/RegisterForm";
import "./Register.css"

class Register extends Component {
  constructor() {
    super();
    this.state = {
      nom:"",
      prenom:"",
      numero:"",
      email: "",
      password1: "",
      type:null,
      addresse:null,
      

      
      
      errors: null,
    };
  }

  componentDidMount() {
    // If logged in and user navigates to Register page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/mon-compte");
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.errors) {
      console.log(nextProps.errors);
      this.setState({
        errors: nextProps.errors,
      });
    }
  }
  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.setState({ errors: null });
  };

  onSubmit = (e) => {
    e.preventDefault();

    const validations = [
      {
        field: "email",
        validationRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Email invalide",
      },
      { field: "password1", minLength: 8, message: "Mot de passe trop court" },

    ];

    let validationErrors = "";

    for (const validation of validations) {
      if (validation.field === "email") {
        if (!validation.validationRegex.test(this.state[validation.field])) {
          validationErrors = validation.message;
          break;
        }
      } else {
        if (this.state[validation.field].length < validation.minLength) {
          validationErrors = validation.message;
          break;
        }
      }
    }
    
    
    // password dont contain number
    // password dont contain uppercase
    // password dont contain lowercase
    if (this.state.password1.match(/[0-9]/g) === null) {
      validationErrors = "Le mot de passe doit contenir au moins un chiffre";
    }
    if (this.state.password1.match(/[A-Z]/g) === null) {
      validationErrors =
        "Le mot de passe doit contenir au moins une lettre majuscule";
    }
    if (this.state.password1.match(/[a-z]/g) === null) {
      validationErrors =
        "Le mot de passe doit contenir au moins une lettre minuscule";
    }

    if (Object.keys(validationErrors).length > 0) {
      this.setState({
        errors: { error: validationErrors },
      });
      return;
    }

    const newUser = {
      email: this.state.email,
      password1: this.state.password1,
      nom: this.state.nom,
      prenom: this.state.prenom,
      numero: this.state.numero,
      
    };
    
    
    

    this.props.registerUser(newUser, this.props.history);
  };

  render() {
    return (
      <div>
        <div className="az">
        <Header />
        <div className="bodyAuth ">
          <div className="bg1"></div>
          <div className="bg3">
          <div className='bg'> 
            <div className="bg2">
            <div className="register3">
          
          <RegisterForm className='register1'
            onSubmit={this.onSubmit}
            errors={this.state.errors}
            onChange={this.onChange}
            email={this.state.email}
            password1={this.state.password1}
            nom={this.state.nom}
            prenom={this.state.prenom}
            numero={this.state.numero}
          /></div>
            </div>
            </div>
          </div>
          </div>
          <Footer />
        </div>
        
      </div>
    );
  }
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { registerUser })(Register);
