import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "../../components/header/Header";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from "react-router-dom";
import "./GestionCompte.css";
import EmailAndPasswordForm from "./EmailAndPasswordForm";
import Footer from "../../components/footer/Footer";

const GestionCompte = (props) => {
  const { user, isAuthenticated } = props.auth;

  return (
    <div>
      <Header isLoggedIn={isAuthenticated} user={user} />

      <div className="container w-100 ">
        <div className="row w-100 ">
          <Link to={`../../mon-compte`}>
            <h5 className="pt-5 offset-1 ">
              {" "}
              <i className="bi bi-arrow-left-circle-fill custom-icon   p-2"></i>
              <span className="custom-icon1 ">Retour</span>
            </h5>
          </Link>

          <div className="col-lg-8 col-md-12 col-sm-12 mx-lg-5 pt-5 align-items-center">
            <h1 className="lg-offset-2 fs-1">Gestion de compte</h1>
            <EmailAndPasswordForm user={user} />
            <div className="text-center pt-4">
              <p>Besoin de supprimer votre compte ?</p>
              <Link to="/contact">
                {" "}
                <h6>Envoyer un message</h6>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

GestionCompte.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(GestionCompte);
