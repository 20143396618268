import React from "react";
import { useTranslation } from "react-i18next";

function AdminSideBar() {
  const { t } = useTranslation();
  // State to handle the Email input and success message
  return (
    <div
      className="offcanvas offcanvas-start"
      tabIndex="-1"
      id="offcanvasExample"
      aria-labelledby="offcanvasExampleLabel"
    >
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasExampleLabel">
          Offcanvas
        </h5>
        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="offcanvas"
          aria-label="Close"
        ></button>
      </div>
      <div className="offcanvas-body">
        <a
          style={{ textDecoration: "none" }}
          className="alert alert-info d-none d-lg-block"
          href="/espace-admin/liste-de-users"
        >
          {" "}
          {t("Espace.Admin.UsersList.title")}
        </a>
        <a
          style={{ textDecoration: "none" }}
          className="alert alert-info d-none d-lg-block"
          href="/espace-admin/liste-de-profiles"
        >
          {" "}
          {t("Espace.Admin.ProfilesList.title")}
        </a>
        <a
          style={{ textDecoration: "none" }}
          className="alert alert-info d-none d-lg-block"
          href="/espace-admin/subscriptions"
        >
          {t("Espace.Admin.SubscribersList.title")}
        </a>
        <a
          style={{ textDecoration: "none" }}
          className="alert alert-info d-none d-lg-block"
          href="/espace-admin/adresses"
        >
          Liste des adresses
        </a>
        <a
          style={{ textDecoration: "none" }}
          className="alert alert-info d-none d-lg-block"
          href="/espace-admin/assistance"
        >
          Assistances
        </a>
        <a
          style={{ textDecoration: "none" }}
          className="alert alert-info d-none d-lg-block"
          href="/espace-admin/blogs"
        >
          Blogs
        </a>
      </div>
    </div>
  );
}

export default AdminSideBar;
