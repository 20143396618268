import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "../SideBar.css";

const SideBar = (props) => {
  const { user } = props.auth;

  return (
    <div className="sidebar-container d-flex flex-column flex-shrink-0 p-3 ">
      <a
        href="/"
        className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
      >
        <svg className="bi pe-none me-2" width="40" height="32"></svg>
        <span className="fs-4">Vendeur Dashboard</span>
      </a>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <Link to="/espace-vendeur" className="nav-link" aria-current="page">
            <svg className="bi pe-none me-2" width="16" height="16"></svg>
            Home
          </Link>
        </li>
        <li>
          <Link to="/espace-vendeur/mes-adresses" className="nav-link ">
            <svg className="bi pe-none me-2" width="16" height="16"></svg>
            Mes Adresses
          </Link>
        </li>
        <li>
          <Link to="/espace-vendeur/mes-contrats" className="nav-link" active>
            <svg className="bi pe-none me-2" width="16" height="16"></svg>
            Mes Contrats
          </Link>
        </li>
        <li>
          <Link to="/espace-vendeur/mon-profile" className="nav-link ">
            <svg className="bi pe-none me-2" width="16" height="16"></svg>
            Mon Profil
          </Link>
        </li>
        <li>
          <Link to="/help" className="nav-link">
            <svg className="bi pe-none me-2" width="16" height="16"></svg>
            HelpCenter
          </Link>
        </li>
      </ul>
      <hr />
      <div className="dropdown">
        <a
          href="#"
          className="d-flex align-items-center text-black text-decoration-none dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img
            src="https://github.com/mdo.png"
            alt=""
            width="32"
            height="32"
            className="rounded-circle me-2"
          />
          <strong>{user.Email}</strong>
        </a>
        <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
          <li>
            <a className="dropdown-item" href="#">
              Ajouter une adresse
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="#">
              Profile
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="#">
              Help Center
            </a>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a className="dropdown-item" href="#">
              Sign out
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SideBar);
