import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { changeField } from "../../../utils/changeFieldPageVendeur";

const Prix = (props) => {

  const [formValues, setFormValues] = useState({
    PrixEntreprise: "",
    PrixSCI: "",
    PrixAE: ""
  });

  useEffect(() => {  
    if (props.Prix) {
      setFormValues({
        PrixEntreprise: props.Prix.PrixEntreprise,
        PrixSCI: props.Prix.PrixSCI,
        PrixAE: props.Prix.PrixAE
      });
    }
  }, [props.Prix]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleNextClick = () => {
    // Store formValues in local storage
    let adresse = JSON.parse(localStorage.getItem("adresse"));
    adresse = { ...adresse, ...formValues };
    localStorage.setItem("adresse", JSON.stringify(adresse));
    

    // Call your changeField function
    changeField("Offres");
  };

  return (
    <form className="row g-3">
      <h2 className="text-center">Les Prix</h2>
      <hr style={{ color: "white" }} />

      <div className="col-md-4">
        <label className="prix-label">Prix pour les Entreprises :</label>
      </div>
      <div className="col-md-5">
        <input
          id="PrixEntreprise"
          name="PrixEntreprise"
          type="number"
          className="inputs"
          value={formValues.PrixEntreprise}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-md-4">
        <label className="prix-label">Prix pour les SCI :</label>
      </div>
      <div className="col-md-5">
        <input
          id="PrixSCI"
          name="PrixSCI"
          type="number"
          className="inputs"
          value={formValues.PrixSCI}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-md-4">
        <label className="prix-label">Prix pour les Auto-Entrepreneur :</label>
      </div>
      <div className="col-md-5">
        <input
          id="PrixAE"
          name="PrixAE"
          type="number"
          className="inputs"
          value={formValues.PrixAE}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 d-flex justify-content-center">
        <button
          type="button"
          className="mon-boutonVendeur"
          value={"Offres"}
          onClick={handleNextClick}
        >
          Next
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Prix);
