import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./BlogDetails.css";

import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import LatestBlog from "../../../components/Blogs/LatestBlog/LatestBlog";
import { DateConvertion } from "../../../utils/DateTimeConvertion";

const BlogDetail = (props) => {
  const { user, isAuthenticated } = props.auth;
  const { id } = useParams();
  const [blog, setBlog] = useState({});

  useEffect(() => {
    window.scrollTo(0, 0);

    const fetchData = async () => {
      try {
        //get id from url
        const response = await axios.get(
          `http://localhost:5000/api/blogs/${id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
            },
          }
        );
        document.title = response.data.Title;
        setBlog(response.data);
      } catch (error) {
        console.error("Error fetching addressData:", error);
      }
    };

    fetchData(); // Invoke fetchData directly here
  }, [id]); // Include id in the dependency array

  return (
    <>
      {" "}
      <Header isLoggedIn={isAuthenticated} user={user} />
      <div className="Blogs ">
        <div className="container " style={{ paddingTop: "150px" }}>
          <div className="row align-items-center">
            <button disabled className="blogDetails-Date-Category">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="27"
                height="24"
                viewBox="0 0 27 24"
                fill="none"
              >
                <path
                  d="M21.9375 7.64679V0.857143H17.7188V3.98839L13.5 0L0 12.8571H3.375V24H10.9688V15.4286H16.0312V24H23.625V12.8571H27L21.9375 7.64679Z"
                  fill="white"
                />
              </svg>
              {DateConvertion(blog.updatedAt)} | {blog.Category}
            </button>
            <h1 className="title-1Home-h1 pt-4">{blog.Title}</h1>
          </div>
        </div>
      </div>
      <section>
        <div
          className="container"
          style={{ marginTop: "-150px", marginBottom: "150px" }}
        >
          <div className="row">
            <img
              src={`http://localhost:5000/api/blogs/get-blog-image/${blog._id}`}
              alt="blog-image"
              className="blog-detail-image"
            />
          </div>
          <div className="row">
            <div
              className="blog-detail-contain"
              dangerouslySetInnerHTML={{ __html: blog.Content }}
            />
          </div>
          <div className="row" style={{ width: "100%" }}>
            <hr
              align="center"
              style={{
                color: "background: #D9D9D9 !important",
                width: "60%",
                marginInline: "auto",
                marginTop: "70px",
                marginBottom: "30px",
              }}
            />
          </div>
          <div className="row">
            <div class="d-flex justify-content-start">
              <div class="p-2 bd-highlight">
                Mots clé: sedomiciler,france ,domicile
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="p-2 bd-highlight">Partager:</div>
              <div class="p-2 bd-highlight">
                <i class="bi bi-facebook"></i>
              </div>
              <div class="p-2 bd-highlight">
                <i class="bi bi-twitter"></i>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container"></div>
      </section>
      <Footer />
    </>
  );
};
BlogDetail.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(BlogDetail);
