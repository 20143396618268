import React from "react";
import { Link } from "react-router-dom";
import "./Section2.css";
import image1 from "./images/section2-1.jpg";
import image2 from "./images/section2-2.jpg";
const Section2 = (props) => {
  return (
    <div>
      <div className="section2">
        <div className="container">
          <div
            className="container-fluid"
            style={{ paddingBottom: "50px", paddingTop: "50px" }}
          >
            <h2 className="text-center carouselservice-h2">
              La domiciliation professionnelle à votre mesure
            </h2>
            {
              //first row
            }
            <div className="row justify-content-center">
              <div className="col-lg-6 col-md-12" style={{ maxWidth: "90%" }}>
                <div
                  className="section2-img justify-content-center"
                  style={{ maxWidth: "100%" }}
                >
                  <img src={image1} alt="" style={{ maxWidth: "100%" }} />
                </div>
              </div>
              <div
                className="col-lg-6 col-md-12"
                style={{ maxWidth: "100%", padding: "40px 40px 80px 40px" }}
              >
                <div className="section2-text" style={{ maxWidth: "100%" }}>
                  <h3 className="section2-h3">
                    Solution de domiciliation légale et professionnelle{" "}
                  </h3>
                  <p className="section2-p">
                    Notre service est conçue pour répondre à toutes vos
                    exigences en matière d'adresse commerciale. Que vous soyez
                    une petite entreprise à la recherche d'une adresse
                    prestigieuse pour renforcer votre image de marque ou une
                    entreprise en croissance ayant besoin d'une adresse légale
                    et professionnelle distincte, notre service est là pour
                    vous. Avec notre solution, vous bénéficiez de bien plus
                    qu'une simple adresse.
                  </p>
                </div>
              </div>
            </div>

            {
              //second row
            }

            <div className="row justify-content-center">
              <div
                className="col-lg-6 col-md-12"
                style={{ maxWidth: "100%", padding: "40px" }}
              >
                <div className="section2-text" style={{ maxWidth: "100%" }}>
                  <h3 className="section2-h3">
                    Service de numérisation de courrier
                  </h3>
                  <p className="section2-p">
                    Grâce à cette solution efficace, vous ne manquerez jamais un
                    seul document important, même lorsque vous êtes en
                    déplacement. Notre équipe dédiée prend en charge la
                    réception de tout votre courrier postal, qu'il s'agisse de
                    lettres, de colis ou de documents importants. Une fois reçu,
                    nous numérisons rapidement et de manière sécurisée chaque
                    élément, vous permettant ainsi d'y accéder à tout moment, où
                    que vous soyez.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-12" style={{ maxWidth: "90%" }}>
                <div className="section2-img" style={{ maxWidth: "100%" }}>
                  <img src={image2} alt="" style={{ maxWidth: "100%" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {
        // demi circle
      }

      <div className="btnwithcircle">
        <Link to={"/adresses"} style={{ textDecoration: "none " }}>
          <button className="btn-btnwithcircle">
            Choisir mon adresse de domiciliation
          </button>
        </Link>
      </div>
    </div>
  );
};
export default Section2;
