import React, { useState } from "react";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import Header from "../../../../../components/header/Header";
import Footer from "../../../../../components/footer/Footer";
import { useNavigate } from "react-router-dom";

function AddBlog() {
  const [userInfo, setuserInfo] = useState({
    title: "",
    category: "",
    image: null,
  });

  const navigate = useNavigate();

  const onChangeValue = (e) => {
    if (e.target.name === "file") {
      setuserInfo({ ...userInfo, [e.target.name]: e.target.files[0] });
    }
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };

  let editorState = EditorState.createEmpty();
  const [description, setDescription] = useState(editorState);
  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  const [isError, setError] = useState(null);
  const addDetails = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      if (userInfo.description.value.length < 50) {
        setError("Required, Add description minimum length 50 characters");
        return;
      }

      const formData = new FormData();

      formData.append("Title", userInfo.title);
      formData.append("Content", userInfo.description.value);
      formData.append("Category", userInfo.category);
      formData.append("Image", document.getElementsByName("image")[0].files[0]);

      axios
        .post(`${process.env.REACT_APP_API_URL}/api/blogs`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        })
        .then((res) => {
          navigate("/espace-admin/blogs");
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <>
      <Header />
      <div className="App">
        <div className="container">
          <div className="row">
            <form onSubmit={addDetails} className="update__forms">
              <h3 className="myaccount-content"> Add </h3>
              <div className="form-row">
                <div className="form-group col-md-12">
                  <label className="font-weight-bold">
                    {" "}
                    Title <span className="required"> * </span>{" "}
                  </label>
                  <input
                    type="text"
                    name="title"
                    value={userInfo.title}
                    onChange={onChangeValue}
                    className="form-control"
                    placeholder="Title"
                    required
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="font-weight-bold">
                    {" "}
                    Categorie <span className="required"> * </span>{" "}
                  </label>
                  <input
                    type="text"
                    name="category"
                    value={userInfo.category}
                    onChange={onChangeValue}
                    className="form-control"
                    placeholder="Categorie"
                    required
                  />
                </div>
                <div className="form-group col-md-12">
                  <label className="font-weight-bold">
                    {" "}
                    Image principale (couverture){" "}
                    <span className="required"> * </span>{" "}
                  </label>
                  <input
                    type="file"
                    name="image"
                    onChange={onChangeValue}
                    className="form-control"
                    accept="image/*"
                    required
                  />
                </div>
                <div className="form-group col-md-12 editor">
                  <label className="font-weight-bold">
                    {" "}
                    Description <span className="required"> * </span>{" "}
                  </label>
                  <Editor
                    editorState={description}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                  />
                  <textarea
                    style={{ display: "none" }}
                    disabled
                    ref={(val) => (userInfo.description = val)}
                    value={draftToHtml(
                      convertToRaw(description.getCurrentContent())
                    )}
                  />
                </div>
                {isError !== null && <div className="errors"> {isError} </div>}
                <div className="form-group col-sm-12 text-right">
                  <button type="submit" className="btn btn__theme">
                    {" "}
                    Submit{" "}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default AddBlog;
