import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "../../components/header/Header";
import "./Blogs.css";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/Footer";
import blogimg from "./images/blog-image.png";
import { useTranslation } from "react-i18next";
import MainBlog from "../../components/Blogs/MainBlog/MainBlog";
import LatestBlog from "../../components/Blogs/LatestBlog/LatestBlog";
import axios from "axios";

const Blogs = (props) => {
  const { t } = useTranslation();
  const { user, isAuthenticated } = props.auth;
  // latest blog
  const [latestBlog, setLatestBlog] = useState([]);
  // latest blogs
  const [latestBlogs, setLatestBlogs] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Domicil Pro - Blogs";
    document.getElementById("blogs").classList.add("active");
    axios(`${process.env.REACT_APP_API_URL}/api/blogs/get-latests-blogs`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    })
      .then((response) => {
        //first blog in the array
        setLatestBlog(response.data[0]);
        //rest of the blogs
        setLatestBlogs(response.data.slice(1));
      })
      .catch((error) => console.error("Error fetching latest blogs:", error));
  }, []);

  return (
    <div>
      <Header isLoggedIn={isAuthenticated} user={user} />

      <div className="Blogs " style={{ marginBottom: "100px" }}>
        <div className="container ">
          <div className="row align-items-center">
            <div className=" col-lg-6 col-12 col-md-12  text-center text-lg-start">
              <p className="title-1Home-h1">Nos Blogs</p>
              <p className="my-4 text-1Home">
                Simple, rapide et efficace.
                <br /> Domicil’Pro vous assiste également dans vos démarches
                judiciaires !
              </p>

              <div className="row align-items-center  py-5">
                <div className="col-auto">
                  <Link to={`mon-compte`} className="textDecoration">
                    {" "}
                    <button className="btn-1Home">
                      {t("home.buton1")}
                      <i className="bi bi-arrow-right-circle-fill"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-12 grp-img text-center mt-5">
              <img src={blogimg} alt="blog-image" className="blog-image" />
            </div>
          </div>
        </div>
      </div>
      <div className="bgleftbanner"></div>
      <div className="bgrightbanner"></div>
      <section className="pt-5">
        <div className="container">
          <MainBlog blog={latestBlog} />
        </div>
      </section>

      <hr
        align="center"
        style={{
          color: "background: #D9D9D9 !important",
          width: "50%",
          marginInline: "auto",
          marginTop: "70px",
          marginBottom: "70px",
        }}
      />

      <section>
        <div className="container">
          <LatestBlog blogs={latestBlogs} />
        </div>
      </section>

      <Footer />
    </div>
  );
};

Blogs.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Blogs);
