import React, { useState, useEffect } from "react";
import Header from "../../../../../components/header/Header";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SideBar from "../../../../../components/SideBars/VendeurSideBar/SideBar";
import "./MesAdresses.css";
import "../../../Espace.css";
import LoadingScreen from "../../../../../components/Loading-Screen/LoadingScreen";
import axios from "axios";

const getData = async (user) => {
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL +
        "/api/adresses/seller/" +
        user.Profiles.Vendeur,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching data from API: " + error.message);
  }
};

const MesAdresses = (props) => {
  const { user, isAuthenticated } = props.auth;
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const adresses = await getData(user);
        setData(adresses);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Set loading to false when done fetching
      }
    };

    fetchData();
  }, [user]);

  return (
    <div className="espace-container">
      <Header isLoggedIn={isAuthenticated} user={user} />
      <div className="espace-vendeur">
        <SideBar className="side-bar" />
        <div className="mes-adresses">
          <h1>Mes Adresses</h1>
          {loading ? (
            <LoadingScreen />
          ) : (
            <table className="table">
              <thead>
                <tr>
                  <th>Ville</th>
                  <th>Rue</th>
                  <th>CodePostal</th>
                  <th>Verifié</th>
                  <th>Voir</th>
                  <th>Modifier</th>
                  <th>Supprimer</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item) => (
                  <tr key={item._id}>
                    <td>{item?.Location?.City}</td>
                    <td>
                      {item?.Location?.HouseNumber}
                      {", "}
                      {item?.Location?.Street}
                    </td>
                    <td>{item?.Location?.PostCode}</td>
                    <td>{item?.Verifie}</td>
                    <td>
                      <Link to={`/adresse/${item._id}`}>
                        <button className="mes-adresses-btn btn-modifier">
                          Voir
                        </button>
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/espace-vendeur/modifier-une-adresse/${item._id}`}
                      >
                        {item?.Verifie === "En attente" ? (
                          <button className="mes-adresses-btn" disabled>
                            Modifier
                          </button>
                        ) : (
                          <button className="mes-adresses-btn btn-modifier">
                            {item?.Verifie === "Refusé"
                              ? "Modifier"
                              : "Demander la modification"}
                          </button>
                        )}
                      </Link>
                    </td>
                    <td>
                      <Link
                        to={`/espace-vendeur/supprimer-une-adresse/${item._id}`}
                      >
                        <button className="mes-adresses-btn btn-supprimer ">
                          {item?.Verifie === "Validé"
                            ? "Demander la suppression"
                            : "Supprimer"}
                        </button>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

MesAdresses.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(MesAdresses);
