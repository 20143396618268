import axios from "axios";
import setAuthToken from "../utils/setAuthToken";
import jwt_decode from "jwt-decode";
import { GET_ERRORS, SET_CURRENT_USER, USER_LOADING } from "./types";
import store from "../store";
import { Navigate } from "react-router-dom";
const apiUrl = process.env.REACT_APP_API_URL;
// Register User
export const registerUser = (userData) => (dispatch) => {
  axios
    .post(apiUrl + "/api/auth/signup", userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
      //redirect to dashboard
      window.location.href = "./mon-compte";
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};

// Login - get user token
export const loginUser = (userData) => (dispatch) => {
  axios
    .post(apiUrl + "/api/auth/signin", userData)
    .then((res) => {
      // Save to localStorage
      // Set token to localStorage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to Auth header
      setAuthToken(token);
      // Decode token to get user data
      const decoded = jwt_decode(token);
      // Set current user
      dispatch(setCurrentUser(decoded));
    })
    .catch((err) =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data,
      })
    );
};
// Function to refresh the token
export const refreshToken = async () => {
  try {
    const res = await axios.post(`${apiUrl}/api/auth/refresh`, {
      token: localStorage.jwtToken,
    });
    const refreshedToken = res.data.token;
    // Update the stored token
    localStorage.setItem("jwtToken", refreshedToken);
    // Set token to Auth header
    setAuthToken(refreshedToken);
    // Update the decoded token
    const refreshedDecoded = jwt_decode(refreshedToken);
    return refreshedDecoded;
  } catch (error) {
    console.error("Failed to refresh token:", error);
    throw error;
  }
};

export const refreshtochangestatus = async () => {
  try {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    const refreshedDecoded = await refreshToken();
    store.dispatch(setCurrentUser(refreshedDecoded));
  } catch (error) {
    console.error("Failed to refresh token:", error);
    throw error;
  }
};

// checkTokenExpiration checks if the token is expired and refreshes it if it is
export const checkTokenExpiration = async () => {
  if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwt_decode(token);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));
    // Check for token expiration
    const currentTime = Date.now() / 1000; // to get in seconds
    const tokenExpiration = decoded.exp;

    // refresh the token if it's expired or about to expire
    // revoke it if it's expired for more than 15 minutes
    if (
      currentTime - tokenExpiration <= 900 &&
      tokenExpiration - currentTime <= 300
    ) {
      try {
        const refreshedDecoded = await refreshToken();
        store.dispatch(setCurrentUser(refreshedDecoded));
      } catch (error) {
        console.error("Failed to refresh token:", error);
        store.dispatch(logoutUser());
        window.location.href = "./login";
      }
    } else if (currentTime - tokenExpiration > 900) {
      store.dispatch(logoutUser());
      window.location.href = "./login";
    }
  }
};

// Set logged in user
export const setCurrentUser = (decoded) => {
  return {
    type: SET_CURRENT_USER,
    payload: decoded,
  };
};
// User loading
export const setUserLoading = () => {
  return {
    type: USER_LOADING,
  };
};
// Log user out
export const logoutUser = () => (dispatch) => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current user to empty object {} which will set isAuthenticated to false
  dispatch(setCurrentUser({}));
};
