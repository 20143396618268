import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { changeField } from "../../../utils/changeFieldPageVendeur";
import axios from "axios";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const Location = (props) => {
  const [formValues, setFormValues] = useState({
    Label: "",
    PostCode: "",
    HouseNumber: "",
    City: "",
    Street: "",
    Longitude: "",
    Latitude: "",
  });

  const [options, setOptions] = useState([]);
  const previousController = useRef();

  const getData = (searchTerm) => {
    if (searchTerm.length < 5) return [];
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios(
      `${process.env.REACT_APP_API_URL}/api/locations/api-gouv/` + searchTerm,
      {
        signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(function (response) {
        const updatedOptions = response.data.map((p) => {
          return {
            label: p.label,
            street: p.street,
            postcode: p.postcode,
            city: p.city,
            housenumber: p.housenumber,
            x: p.x,
            y: p.y,
          };
        });
        setOptions(updatedOptions);
        return response.data;
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          // Handle canceled request
          console.log("Request canceled:", error.message);
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
        }
      });
  };

  const handleOptionChange = (event, newValue) => {
    if (newValue) {
      // When an option is selected, set the relevant fields in formValues
      setFormValues((prevValues) => ({
        ...prevValues,
        Label: newValue.label,
        PostCode: newValue.postcode,
        HouseNumber: newValue.housenumber,
        City: newValue.city,
        Street: newValue.street,
        Latitude: newValue.x,
        Longitude: newValue.y,
      }));
    }
    console.log(newValue);
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    //get data from props
    if (props.Location) {
      setFormValues({
        Ville: props.Location.Ville,
        CodePostal: props.Location.CodePostal,
        Rue: props.Location.Rue,
        Numero: props.Location.Numero,
      });
    }
  }, [props.Location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleNextClick = () => {
    // Store formValues in local storage
    let adresse = JSON.parse(localStorage.getItem("adresse"));
    adresse = { ...adresse, Location: formValues };
    localStorage.setItem("adresse", JSON.stringify(adresse));

    // Call your changeField function
    changeField("Prix");
  };

  return (
    <form className="row g-3">
      <h2 className="text-center">Localisation </h2>
      <div className="col-md-4">
        <label className="prix-label">Département:</label>
      </div>

      <div className="col-md-5">
        <select
          id="Ville"
          name="Ville"
          className=" inputs"
          value={formValues.Ville}
          onChange={handleInputChange}
        >
          <option value="75001">Paris (75)</option>
          <option value="77000">Seine-et-Marne (77)</option>
          <option value="78000">Yvelines (78)</option>
          <option value="91000">Essonne (91)</option>
          <option value="92000">Hauts-de-Seine (92)</option>
          <option value="93000">Seine-Saint-Denis (93)</option>
          <option value="94000">Val-de-Marne (94)</option>
          <option value="95000">Val-d'Oise (95)</option>
        </select>
      </div>

      <hr style={{ color: "white" }} />
      <div className="col-md-4">
        <label className="prix-label">Rue :</label>
      </div>

      <div className="col-md-5">
        <Autocomplete
          id="combo-box-demo"
          options={options}
          onInputChange={onInputChange}
          getOptionLabel={(option) => option?.label || ""}
          style={{ width: 300 }}
          value={formValues.Label} // Set the value directly from formValues
          onChange={handleOptionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Label"
              id="Label"
              name="Label"
              placeholder="50 Rue Lecourbe 75015 Paris"
            />
          )}
        />
      </div>
      <hr style={{ color: "white" }} />

      <div className="col-12 d-flex justify-content-center">
        <button
          type="button"
          className="mon-boutonVendeur "
          value={"Prix"}
          onClick={handleNextClick}
        >
          Next
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Location);
