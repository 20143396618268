import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { changeField } from "../../utils/changeFieldPageVendeur";


const apiUrl = process.env.REACT_APP_API_URL;

const Prix = (props) => {
    const { user } = props.auth;
    const { t } = useTranslation();
  
    const [formValues, setFormValues] = useState({
      PrixEntreprise: "",
      PrixSCI: "",
      PrixAE: ""
    });
  
    useEffect(() => {  
      if (props.Prix) {
        setFormValues({
          PrixEntreprise: props.Prix.PrixEntreprise,
          PrixSCI: props.Prix.PrixSCI,
          PrixAE: props.Prix.PrixAE
        });
      }
    }, [props.Prix]);
    const [errors, setErrors] = useState({
      PrixEntreprise: '',
      PrixSCI: '',
      PrixAE: ''
      // other errors...
    });
  
    
    const handleInputChange = (e) => {
      const { name, value } = e.target;
  
      // Ensure the entered value is at least 0
      const numericValue = parseFloat(value) >= 0 ? parseFloat(value) : 0;
  
      setFormValues({ ...formValues, [name]: numericValue });
  
      // Clear errors for the current field
      setErrors({ ...errors, [name]: '' });
    };
   
    const handleNextClick = () => {
        // Store formValues in local storage
        let adresse = JSON.parse(localStorage.getItem("adresse"));
        adresse = { ...adresse, ...formValues };

        localStorage.setItem("adresse", JSON.stringify(adresse));
        
        const errorFields = {};

    // Validate prices
    if (formValues.PrixEntreprise < 0) {
      errorFields['PrixEntreprise'] = 'Prix Entreprise doit être au moins égal à 0';
    }

    if (formValues.PrixSCI < 0) {
      errorFields['PrixSCI'] = 'Prix SCI doit être au moins égal à 0';
    }

    if (formValues.PrixAE < 0) {
      errorFields['PrixAE'] = 'Prix AE doit être au moins égal à 0';
    }
    if (formValues.PrixEntreprise.length == 0) {
      errorFields['PrixEntreprise'] = 'champs obligatoire';
    }

    if (formValues.PrixSCI.length == 0) {
      errorFields['PrixSCI'] = 'champs obligatoire';
    }

    if (formValues.PrixAE.length == 0) {
      errorFields['PrixAE'] = 'champs obligatoire';
    }

    setErrors(errorFields);
  
      // If there are errors, return without proceeding
      if (Object.keys(errorFields).length > 0) {
        return;
      } 

    // If there are errors, return without proceeding
    
    changeField("OffreCourrier");
        // Call your changeField function
        
      };
     
  

    return (
      
        <div  style={{ marginTop: "2.5rem", width: "100%" }} className="row login-div">
          <div className=" col-md-11 ">
            <form noValidate >
             
              <br />
              <br />
              <div className="row">
              
                <div className="CrAnAtt">Prix Entreprise</div>
                <div >
                <input
                onChange={handleInputChange}
                  type="number"
                  label="PrixEntreprise"
                  id="PrixEntreprise"
                  name="PrixEntreprise"
                  value={formValues.PrixEntreprise}
                  className="CreateAnnonce-input "
                  placeholder="PrixEntreprise"
                  />
                                   {errors?.PrixEntreprise && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "100%", marginTop: "5px"}}
              role="alert"
            >
              {errors.PrixEntreprise}
            </div>
          )}

                </div>  
                  
              </div>
              <div className="row">
              <div className="CrAnAtt">Prix SCI</div>
               <div > 
                <input
                onChange={handleInputChange}
                  type="number"
                  label="PrixSCI"
                  id="PrixSCI"
                  name="PrixSCI"
                  value={formValues.PrixSCI}
                  className="CreateAnnonce-input   "
                  placeholder="PrixSCI"
                  />
                    {errors?.PrixSCI && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "100%", marginTop: "5px"}}
              role="alert"
            >
              {errors.PrixSCI}
            </div>
          )}
       
                  </div>
              </div>
              
              
              
              
              
              <div className="row">
              <div className="CrAnAtt">Prix AE</div>
               <div > 
               <input
          id="PrixAE"
          name="PrixAE"
          type="number"
          className="CreateAnnonce-input"
          placeholder="PrixAE"
          value={formValues.PrixAE}
          onChange={handleInputChange}
        />
          {errors?.PrixAE && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "100%", marginTop: "5px"}}
              role="alert"
            >
              {errors.PrixAE}
            </div>
          )}
                
                  </div>
              </div>
             
              
              <div  >
        <button
          type="button"
          className="btn button creeAnnonceBtn"
          value={"CreateAnnoncePage2"}
          onClick={handleNextClick}
        >
          Suivant
        </button>
      </div>
              
            </form>
          </div>
        </div>
        
      );
    
      
    };

  const mapStateToProps = (state) => ({
    auth: state.auth,
  });

  export default connect(mapStateToProps)(Prix);