import React,  { useEffect } from 'react'
import PropTypes from "prop-types";
import Footer from "../../../../components/footer/Footer";
import NavBarDash from "../../../../components/navbarDash/navBarDash";
import SideBar1 from '../../../../components/sidebarDash/Sidebar';
import "./creeAnnonce1.css"
import { connect } from "react-redux";
import CreeAnnonceForm from '../../../../components/createAnnonce/CreateAnnonceForm';
import CreateAnnoncePage2 from '../../../../components/createAnnonce/CreateAnnoncePage2';
import OffreCourrier1 from '../../../../components/createAnnonce/OffreCourrier';
import Prix from '../../../../components/createAnnonce/Prix';


  const CreeAnnonce1 = (props) => {
  
    useEffect(() => {
      document.title ="Ajouter une adresse";
    }, []);
  
    
    return (
        <>
        <div className="background ">
        </div>
        <div className="row m-0 p-0 fixationItem">
          
          
        <div className="col-1 col-lg-1 col-xl-2 col-md-1 col-sm-1 sidebar-compte">
        <SideBar1/>
        </div>
        
        <div className="col-11 col-md-11 col-lg-11 col-xl-10 col-sm-11">
          <div className="row">
        <NavBarDash/>
        </div>
        <div className='bodycreate'>
        <div className='row'>
            <div className='textcreeAnnonce'>Creer une annonce</div>
        </div>
        <div className="row">
        <div  className=" ">
        <div className="bodyDash5 ">
          <div className='row'>
            <div className='col-11 col-md-10 col-lg-7 col-xl-7'>
            <div className="add-adresse"  id="general-info">
            <div className="container field active" id="CreeAnnonceForm" name="field" style={{display: "block"}}>
          <CreeAnnonceForm />
        </div>
        <div className="container field" id="Prix" style={{display:"none"}} name="field">
          <Prix />
        </div>
        <div className="container field" id="OffreCourrier" style={{display:"none"}} name="field">
          <OffreCourrier1 />
        </div>
        
        <div className="container field" id="CreateAnnoncePage2" style={{display:"none"}} name="field">
          <CreateAnnoncePage2 />
        </div>
        
             
            </div>
         </div>
          </div>
            </div>
          
          </div>
          </div>
        </div>
        
        </div>
        </div>
         
        <Footer/>
        </>
    )
    }

  
    CreeAnnonce1.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(CreeAnnonce1);
