import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Login.css";


const LoginForm = ({ onSubmit, errors, onChange, email, password }) => {
  const [isPasswordShown ,setIsPasswordVisibles]=useState(false);
  return (
    <div style={{  width: "90%" }} className="row login-div">
      <div className="col-md-11 offset-md-1 ">
        <form noValidate onSubmit={onSubmit}>
          <h1 className="title">se connecter</h1>
          <div className="inline-container">
            
          </div>
          {errors?.error && ( // Check if errors and errors.error are defined
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "90%" }}
              role="alert"
            >
              {errors.error}
            </div>
          )}
          <div className="conta">
          
          
          <div className="Att2">Email</div>
          <input
            onChange={onChange}
            value={email}
            id="email"
            type="email"
            className="login-input"
            placeholder="exemple@gmail.com"
          />
          <div className="Att2">Mot de passe</div>
          <div>
          
          
          <input
            onChange={onChange}
            value={password}
            id="password"
            type={ isPasswordShown ? 'text' : 'password'}
            className="login-input"
            placeholder="votre mot de passe"
          />
           <div className="imgeye" onClick={() => setIsPasswordVisibles(!isPasswordShown)}>

           </div>
          </div>
          <Link to="/reinitialisation-mot-de-passe" className="passf1" >
              Mot de passe oublié
            </Link>
          <div className="d-grid gap-2">
            <button type="submit" className="btn button btn1" >
              se connexion
            </button>
            <h4 className="inscrivez-vous">
              Vous n’avez pas de compte ?
              <Link to="/register" className="inscrivez-vous link-inscription">
                Inscrivez-vous
              </Link>
            </h4>
            
          </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default LoginForm;
