import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
import Header from "../../../../components/header/Header";
import { useQuery } from "react-query"; // Import the useQuery hook from React Query
import Popup from "./pop-up/pop-up";
import AdminSideBar from "../../../../components/EspaceAdmin/AdminSideBar";
import { fetchData } from "./SubscribersListFunctions";
import DateTimeConvertion from "../../../../utils/DateTimeConvertion";

const SubscribersList = (props) => {
  const { user, isAuthenticated } = props.auth;
  // Create a unique query key based on the user's ID
  const queryKey = ["subcribers", user.id];

  const { data = [], isLoading, error } = useQuery(queryKey, fetchData); // Use the useQuery hook to fetch data

  const [showPopup, setShowPopup] = React.useState(false);
  const [selectedprofile, setSelectedprofile] = React.useState(null);

  const handleVoirClick = (profile) => {
    setSelectedprofile(profile);
    setShowPopup(!showPopup);
  };

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };

  if (isLoading) {
    return (
      <div>
        <Header
          isLoggedIn={isAuthenticated}
          user={user}
          onLogoutClick={onLogoutClick}
        />
        Loading...
      </div>
    ); // Display a loading message while data is being fetched
  }

  if (error) {
    return <div>Error fetching data from API: {error.message}</div>; // Display an error message if there's an error
  }

  return (
    <div>
      <Header
        isLoggedIn={isAuthenticated}
        user={user}
        onLogoutClick={onLogoutClick}
      />
      <button
        className="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"
      >
        Admin Dashboard
      </button>
      <AdminSideBar />
      <div style={{ padding: "20px" }}>
        <h1>Liste de Subscribers</h1>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Email</th>
              <th scope="col">Date de subcribtions</th>
              <th scope="col">Etat</th>
              <th scope="col">Voir</th>
            </tr>
          </thead>
          <tbody>
            {data.length === 0 ? (
              <tr>
                <td colSpan="6">No profiles found</td>
              </tr>
            ) : (
              data.map((item, index) => (
                <tr key={item.id}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.Email}</td>
                  <td>{DateTimeConvertion(item.createdAt)}</td>
                  <td>{item.Verifié}</td>
                  <td>
                    <button
                      onClick={() => handleVoirClick(item)}
                      className="btn btn-primary"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Voir
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
        <Popup profile={selectedprofile} onClose={() => setShowPopup(false)} />
      </div>
    </div>
  );
};

SubscribersList.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(SubscribersList);
