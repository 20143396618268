import React,  { useState,Component,useEffect } from 'react'
import PropTypes from "prop-types";
import Footer from "../../../../components/footer/Footer";
import NavBarDash from "../../../../components/navbarDash/navBarDash";
import SideBar1 from '../../../../components/sidebarDash/Sidebar';
import "./Validation.css"
import { connect } from "react-redux";
import Validations from '../../../../components/createAnnonce/Validation';

  const Validation = (props) => {
    const { user, isAuthenticated } = props.auth;
  
    useEffect(() => {
      document.title ="Ajouter une adresse";
    }, []);
  
    
    return (
        <>
        <div className="background ">
        </div>
        <div className="row m-0 p-0 fixationItem">
          
          
        <div className="col-1 col-lg-1 col-xl-2 col-md-1 col-sm-1 sidebar-compte">
        <SideBar1/>
        </div>
        
        <div className="col-11 col-md-11 col-lg-11 col-xl-10 col-sm-11">
          <div className="row">
        <NavBarDash/>
        </div>
        <div className='bodycreate'>
        <div className='row'>
            <div className='textcreeAnnonce'>Validation</div>
        </div>
        <div className="row">
        <div  className=" ">
        <div className="bodyDash6 ">
          
          <Validations/>
          
            </div>
          
          </div>
          </div>
        </div>
        
        </div>
        </div>
         
        <Footer/>
        </>
    )
    }

  
    Validation.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Validation);



