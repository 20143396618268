import axios from 'axios';

export const toggleIsPinned = (adresseId, isPinned) => async dispatch => {
  try {
    const res = await axios.put(`/api/adresses/${adresseId}/pin`, { isPinned }); // Appel de l'API Backend pour épingler/désépingler
    dispatch({ type: 'TOGGLE_ISPINNED_SUCCESS', payload: res.data });
  } catch (error) {
    dispatch({ type: 'TOGGLE_ISPINNED_FAILURE', payload: error.response.data });
  }
};
