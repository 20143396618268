import React, { useEffect, useState } from "react";
import Header from "../../components/header/Header";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";
import "./Contact.css";
import Footer from "../../components/footer/Footer";
import Questions from "../../components/Questions/Questions";

const Contact = (props) => {
  const { user, isAuthenticated } = props.auth;
  const { t } = useTranslation();
  const [response, setResponse] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Domicil Pro - Contactez-nous ";
    document.getElementById("contact").classList.add("active");
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Get the form data
    const formData = {
      Nom: e.target.name.value,
      Email: e.target.email.value,
      Number: e.target.number.value,
      Sujet: e.target.sujet.value,
      Message: e.target.message.value,
    };
    if (formData.Nom === "" || formData.Nom.length < 3) {
      setResponse("S'il vous plaît un doit être supérieur à 3 caractères");
      return;
    }
    if (formData.Email === "" || formData.Email.length < 5) {
      setResponse("S'il vous plaît entrer une adresse e-mail valide");
      return;
    }
    if (formData.Number === "" || formData.Number.length < 5) {
      setResponse("S'il vous plaît entrer un numéro de téléphone valide");
      return;
    }
    if (formData.Sujet === "" || formData.Sujet.length < 3) {
      setResponse("S'il vous plaît un doit être supérieur à 3 caractères");
      return;
    }
    if (formData.Message === "" || formData.Message.length < 5) {
      setResponse("S'il vous plaît un doit être supérieur à 5 caractères");
      return;
    }

    try {
      // Make an Axios POST request to your API endpoint
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/assistance/create",
        formData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      // Handle the response if needed (e.g., show a success message)
      console.log("Response:", response.data);
      setResponse("Votre message a été envoyé avec succès");
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error("Error:", error);
      setResponse("Une erreur s'est produite, veuillez réessayer");
    }
  };

  return (
    <div>
      <Header isLoggedIn={isAuthenticated} user={user} />
      <div className="contact-main">
        <div
          className="container "
          style={{ paddingBottom: "50px", paddingTop: "150px", width: "100%" }}
        >
          <div className="row justify-content-center" style={{ width: "100%" }}>
            <div className="col-lg-6 col-md-12">
              <div className="left-side-description">
                <h1 className="contact-h1">
                  Contactez-nous pour plus d'informations
                </h1>
                <h3 className="contact-text mb-5">
                  Nous sommes là pour répondre à vos questions . questions
                  judiciaires peuvent être complexes , nos experts sont là pour
                  vous aider à informer sur tous les aspects concernant votre
                  sujet .
                </h3>
                <div className="contact-details mb-5">
                  <div className="mail">
                    <div className="overlap-group">
                      <div className="background" />
                      <div className="content">
                        <div className="text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="37"
                            height="28"
                            viewBox="0 0 37 28"
                            fill="none"
                          >
                            <path
                              d="M32.375 0H4.625C3.39878 0.00126336 2.22315 0.470986 1.35608 1.3061C0.489008 2.14122 0.0013117 3.27351 0 4.45455V23.5455C0.0013117 24.7265 0.489008 25.8588 1.35608 26.6939C2.22315 27.529 3.39878 27.9987 4.625 28H32.375C33.6012 27.9987 34.7768 27.529 35.6439 26.6939C36.511 25.8588 36.9987 24.7265 37 23.5455V4.45455C36.9987 3.27351 36.511 2.14122 35.6439 1.3061C34.7768 0.470986 33.6012 0.00126336 32.375 0ZM31.2039 7.3683L19.311 16.2774C19.0791 16.451 18.7937 16.5453 18.5 16.5453C18.2063 16.5453 17.9209 16.451 17.689 16.2774L5.79612 7.3683C5.65639 7.26666 5.53902 7.1393 5.45084 6.9936C5.36266 6.84791 5.30542 6.68678 5.28245 6.51959C5.25948 6.35241 5.27124 6.18249 5.31704 6.01972C5.36285 5.85695 5.44178 5.70457 5.54926 5.57143C5.65673 5.43829 5.79061 5.32706 5.94311 5.24418C6.09561 5.16131 6.26368 5.10846 6.43757 5.08869C6.61146 5.06892 6.7877 5.08264 6.95604 5.12904C7.12438 5.17544 7.28146 5.2536 7.41817 5.35898L18.5 13.6603L29.5818 5.35898C29.8589 5.15743 30.2073 5.06903 30.5515 5.1129C30.8958 5.15677 31.2082 5.32939 31.4214 5.59342C31.6345 5.85745 31.7312 6.19167 31.6905 6.52381C31.6498 6.85595 31.475 7.15931 31.2039 7.3683Z"
                              fill="#00A4B8"
                            />
                          </svg>
                          <a
                            href="mailto:contact@domicilpro.fr"
                            className="text-wrapper"
                          >
                            contact@domicilpro.fr
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="overlap-wrapper">
                    <div className="overlap-group">
                      <div className="background" />
                      <div className="content">
                        <div className="text">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="29"
                            height="30"
                            viewBox="0 0 29 30"
                            fill="none"
                          >
                            <path
                              d="M23.2372 29.4998C21.9734 29.4998 20.1981 29.0427 17.5396 27.5578C14.3069 25.7452 11.8064 24.0719 8.59118 20.8657C5.49117 17.7682 3.9826 15.7628 1.87126 11.9215C-0.513962 7.58442 -0.107361 5.311 0.347152 4.33936C0.888423 3.17805 1.68738 2.48346 2.72007 1.79406C3.30663 1.40983 3.92736 1.08045 4.57438 0.810117C4.63912 0.782282 4.69934 0.755742 4.75308 0.731791C5.07357 0.587436 5.55915 0.369286 6.17424 0.602325C6.58472 0.756389 6.95118 1.07164 7.52483 1.63805C8.70125 2.79807 10.3089 5.38156 10.9019 6.65032C11.3001 7.50544 11.5636 8.06991 11.5643 8.703C11.5643 9.44419 11.1914 10.0158 10.7388 10.6327C10.654 10.7486 10.5698 10.8593 10.4882 10.9667C9.99551 11.614 9.88738 11.8011 9.9586 12.1351C10.103 12.8064 11.1797 14.8047 12.9492 16.57C14.7187 18.3353 16.6597 19.3438 17.3337 19.4875C17.6821 19.562 17.8731 19.4493 18.5413 18.9392C18.6371 18.8661 18.7355 18.7903 18.8384 18.7146C19.5286 18.2013 20.0738 17.8381 20.7976 17.8381H20.8015C21.4315 17.8381 21.9708 18.1113 22.8643 18.5618C24.0297 19.1496 26.6914 20.7362 27.8588 21.9137C28.4266 22.4859 28.7432 22.851 28.8979 23.2608C29.131 23.8777 28.9115 24.3612 28.7684 24.6849C28.7445 24.7386 28.7179 24.7976 28.6901 24.8629C28.4176 25.5087 28.0862 26.128 27.7001 26.713C27.0119 27.7423 26.3146 28.5391 25.1505 29.0809C24.5527 29.3636 23.8985 29.5068 23.2372 29.4998Z"
                              fill="#00A4B8"
                            />
                          </svg>
                          <a
                            href="tel:06 02 05 01 04"
                            className="text-wrapper mb-0"
                          >
                            06 02 05 01 04
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 col-md-12">
              <div className="contact-form-container">
                <form
                  className="contact-form w-100 "
                  style={{ padding: "50px" }}
                  onSubmit={handleSubmit}
                >
                  <div className="row mb-0">
                    <h2 className="contact-form-h2">Contacter nous</h2>
                  </div>
                  <div className="row ">
                    <h3 className="contact-form-text">
                      Domiciliation & services aux entrepreneurs
                    </h3>
                  </div>
                  <div className="row mb-0">
                    <p className="contact-form-label">Nom</p>
                  </div>
                  <div className="row">
                    <input
                      className="contact-form-input"
                      id="name"
                      name="name"
                      placeholder="paul  domicile"
                      type="text"
                      required
                    />
                  </div>
                  <div className="row mb-0">
                    <p className="contact-form-label">Email</p>
                  </div>
                  <div className="row">
                    <input
                      className="contact-form-input"
                      id="email"
                      name="email"
                      placeholder="votre@email.com"
                      type="email"
                      required
                    />
                  </div>
                  <div className="row mb-0">
                    <p className="contact-form-label">Numéro de téléphone</p>
                  </div>
                  <div className="row">
                    <input
                      className="contact-form-input"
                      id="number"
                      name="number"
                      placeholder="0000 62 1 454 45"
                      type="text"
                      required
                    />
                  </div>
                  <div className="row mb-0">
                    <p className="contact-form-label">Sujet</p>
                  </div>
                  <div className="row">
                    <input
                      className="contact-form-input"
                      id="sujet"
                      name="sujet"
                      placeholder={t("contact.subject")}
                      rows="5"
                      required
                    />
                  </div>
                  <div className="row mb-0">
                    <p className="contact-form-label">Message</p>
                  </div>
                  <div className="row">
                    <textarea
                      className="contact-form-textarea"
                      id="message"
                      name="message"
                      placeholder={t("contact.message")}
                      rows="5"
                      required
                    />
                  </div>
                  <br />
                  <div className="row">
                    <button className="contact-form-button" type="submit">
                      {t("contact.send")}
                    </button>
                  </div>
                  {response && (
                    <div className="row">
                      <div className="alert alert-primary">{response}</div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Questions />
      <Footer />
    </div>
  );
};

Contact.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Contact);
