import React, { useEffect, useState,useRef } from "react";
import { connect } from "react-redux";
import { changeField } from "../../utils/changeFieldPageVendeur";
import './CreateAnonanceForm.css';
import axios from "axios";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";


const CreeAnnonceForm = (props) => {
  
  const [formData, setformData] = useState({
    APropos: "",
    ConditionDAcces: ""
  });
  const [formValues, setFormValues] = useState({
    
    Label: "",
    PostCode: "",
    HouseNumber: "",
    City: "",
    Street: "",
    Longitude: "",
    Latitude: "",
    
  });

  useEffect(() => {
    //get data from props
    if(props.CreeAnnonceForm){
      setformData({
        APropos: props.CreeAnnonceForm.APropos,
        ConditionDAcces: props.CreeAnnonceForm.ConditionDAcces,
        
      });
    }
    
  }, [props.CreeAnnonceForm]);

  
  const handleNextClick = () => {
    localStorage.clear();
    // Store formValues in local storage
    let adresse = JSON.parse(localStorage.getItem("adresse"));
    const adresseComplete = { ...adresse, ...formData };
  
    const adresseLoc = {
      ...adresse,
      Location: {
        Label: formValues.Label,
        PostCode: formValues.PostCode,
        HouseNumber: formValues.HouseNumber,
        City: formValues.City,
        Street: formValues.Street,
        Longitude: formValues.Longitude,
        Latitude: formValues.Latitude,
      },
      ...formData
    };
    localStorage.setItem("adresse", JSON.stringify(adresseLoc));
    
  
  
    



        // Check if there are any errors
        const errorField = {};

        // Validate CIN and SIRET lengths
        if (formData.APropos.length == 0) {
          errorField['APropos'] = 'Champs obligatoire';
        }
        if (formData.ConditionDAcces.length == 0) {
          errorField['ConditionDAcces'] = 'Champs obligatoire';
        }
        
        
        
    
        setErrors(errorField);
    
        // If there are errors, return without proceeding
        if (Object.keys(errorField).length > 0) {
          return;
        }
    
    const errorFields = Object.keys(errors).filter((key) => errors[key] !== '');

    if (errorFields.length > 0) {
      // If there are errors, highlight the input fields with red border
      const updatedErrors = {};
      errorFields.forEach((field) => {
        updatedErrors[field] = 'champs obligatoire'; // You can define a CSS class for error styles
      });
      setErrors({ ...errors, ...updatedErrors });
    } else {
    // Call your changeField function
    changeField("Prix");}
  };
  const [options, setOptions] = useState([]);
  const previousController = useRef();

  const getData = (searchTerm) => {
    if (searchTerm.length < 5) return [];
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios(
      `${process.env.REACT_APP_API_URL}/api/locations/api-gouv/` + searchTerm,
      {
        signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(function (response) {
        const updatedOptions = response.data.map((p) => {
          return {
            label: p.label,
            street: p.street,
            postcode: p.postcode,
            city: p.city,
            housenumber: p.housenumber,
            x: p.x,
            y: p.y,
          };
        });
        setOptions(updatedOptions);
        return response.data;
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          // Handle canceled request
          console.log("Request canceled:", error.message);
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
        }
      });
  };
  const handleOptionChange = (event, newValue) => {
    if (newValue) {
      // When an option is selected, set the relevant fields in formValues
      setFormValues((prevValues) => ({
        ...prevValues,
        Label: newValue.label,
        PostCode: newValue.postcode,
        HouseNumber: newValue.housenumber,
        City: newValue.city,
        Street: newValue.street,
        Latitude: newValue.x,
        Longitude: newValue.y,
      }));
    }
    console.log(newValue);
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
    } else {
      setOptions([]);
    }
  };

  useEffect(() => {
    //get data from props
    if (props.Location) {
      setFormValues({
        Ville: props.Location.Ville,
        CodePostal: props.Location.CodePostal,
        Rue: props.Location.Rue,
        Numero: props.Location.Numero,
      });
    }
  }, [props.Location]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const [errors, setErrors] = useState({
    APropos: '',
    ConditionDAcces: '',
    Autocomplete: '',

    Ville: '',
    Label: '',
    
  });
  const handleInputChange2 = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    // Check for validation
    if (name === 'APropos' && value.length < 3) {
      setErrors({ ...errors, APropos: 'APropos doit contenir au moins 3 caractères' });
    } else {
      setErrors({ ...errors, APropos: '' });
    }
    
  };
  const handleInputChange1 = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
    if (name === 'ConditionDAcces' && value.length < 3) {
      setErrors({ ...errors, ConditionDAcces: "Condition D'acces doit contenir au moins 3 caractères"});
    } else {
      setErrors({ ...errors, ConditionDAcces: '' });
    }};

  const handleOptionChange1 = (event, newValue) => {
    setFormValues({ ...formValues, Label: newValue });
    
    // Validation for Autocomplete (not empty)
    if (!newValue || !newValue.label) {
      setErrors((prevErrors) => ({ ...prevErrors, Label: 'Please select a valid option' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, Label: '' }));
    }
  };

  const handleFileChange1 = (e) => {
    const { name, files } = e.target;

    // Validation for Images (at least one file)
    if (files.length === 0) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: 'Please select at least one image' }));
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }

    setFormValues({ ...formValues, [name]: files });
  };


  const handleOptionChange2 = (event, newValue) => {
    setFormValues(newValue);
    if (!newValue) {
      setErrors({ ...errors, Autocomplete: 'Autocomplete is required' });
    } else {
      setErrors({ ...errors, Autocomplete: '' });
    }
  };
  

 

 



  
    return (
      <div style={{ marginTop: "2.5rem", width: "100%" }} className="row login-div">
        <div className=" col-md-11 ">
          <form   enctype="multipart/form-data">
           
            
            <div className="">
            
              <div className="CrAnAtt">A Propos</div>
              <div >
              <textarea
          id="APropos"
          name="APropos"
          className=" inputApropos"
          placeholder={"description"}
          value={formData.APropos}
          onChange={handleInputChange2}
        />
         {errors?.APropos && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "100%" }}
              role="alert"
            >
              {errors.APropos}
            </div>
          )}
       
              </div>  
                
            </div>
           
            
            
            
            
            
            <div className="row">
            <div className="CrAnAtt">Condition d'Acces</div>
             <div > 
              <input
              
                type="text"
                name="ConditionDAcces"
                label="ConditionDAcces"
                id="ConditionDAcces"
                value={formData.ConditionDAcces}
                className="CreateAnnonce-input   "
                placeholder="ConditionDAcces"
                onChange={handleInputChange1}
                />
                 {errors?.ConditionDAcces && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "100%", marginTop: "5px"}}
              role="alert"
            >
              {errors.ConditionDAcces}
            </div>
          )}
                
                </div>
            </div>
            <form className="row depar">
            <div className="">
        <div className="CrAnAtt">Département</div>
      </div>

      <div className="">
        <select
          id="Ville"
          name="Ville"
          className="   CreateAnnonce-select CreateAnnonce-input "
          value={formValues.Ville}
          onChange={handleInputChange}
        >
          <option value="75001">Paris (75)</option>
          <option value="77000">Seine-et-Marne (77)</option>
          <option value="78000">Yvelines (78)</option>
          <option value="91000">Essonne (91)</option>
          <option value="92000">Hauts-de-Seine (92)</option>
          <option value="93000">Seine-Saint-Denis (93)</option>
          <option value="94000">Val-de-Marne (94)</option>
          <option value="95000">Val-d'Oise (95)</option>
        </select>
      </div>
      </form>
            <div className="row">
            <div className="">
        <div className="CrAnAtt ">Rue </div>
      </div>

      <div className="">
        <Autocomplete 
          id="combo-box-demo"
          options={options}
          onInputChange={onInputChange}
          getOptionLabel={(option) => option?.label || ""}
          
          value={formValues.Label} // Set the value directly from formValues
          onChange={handleOptionChange}
          renderInput={(params) => (
            <TextField
              {...params}
              className="CreateAnnonce-autocomplete " 
              id="Label"
              name="Label"
              placeholder="50 Rue Lecourbe 75015 Paris"
            />
          )}
        />
        {errors?.Autocomplete && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "100%", marginTop: "5px"}}
              role="alert"
            >
              {errors.Autocomplete}
            </div>
          )}

          </div>
            </div>


           



            
            <div  >
        <button
          type="button"
          className="btn button creeAnnonceBtn"
          value={"CreateAnnoncePage2"}
          onClick={handleNextClick}
        >
          Suivant
        </button>
      </div>
            
          </form>
        </div>
      </div>
    );
  
    
  };
  
const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(CreeAnnonceForm);

  

  