import React, { useState } from "react";
import PropTypes from "prop-types";

import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import { useNavigate } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";

function EditBlog(props) {
  let navigate = useNavigate();
  const [userInfo, setuserInfo] = useState({
    title: props.post.Title,
    category: props.post.Category,
    image: null,
  });
  const onChangeValue = (e) => {
    if (e.target.name === "image") {
      setuserInfo({ ...userInfo, [e.target.name]: e.target.files[0] });
    }
    setuserInfo({
      ...userInfo,
      [e.target.name]: e.target.value,
    });
  };
  let editorState = EditorState.createWithContent(
    ContentState.createFromBlockArray(convertFromHTML(props.post.Content))
  );
  const [description, setDescription] = useState(editorState);

  const onEditorStateChange = (editorState) => {
    setDescription(editorState);
  };

  const [isError, setError] = useState(null);
  const PoemAddbooks = async (event) => {
    try {
      event.preventDefault();
      event.persist();
      if (userInfo.description.value.length < 50) {
        setError("Required, Add description minimum length 50 characters");
        return;
      }
      axios
        .put(`${process.env.REACT_APP_API_URL}/api/blogs/${props.post._id}`, {
          Title: userInfo.title,
          Content: userInfo.description.value,
          Category: userInfo.category,
        })
        .then((res) => {
          // then print response status
          navigate("/espace-admin/blogs");
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const updateImage = async (event) => {
    try {
      event.preventDefault();
      event.persist();

      const formData = new FormData();
      formData.append("Image", document.getElementsByName("image")[0].files[0]);

      axios
        .put(
          `${process.env.REACT_APP_API_URL}/api/blogs/update-blog-image/${props.post._id}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((res) => {
          alert("image mis à jour avec succes");
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };
  return (
    <div className="App">
      <div className="container">
        <div className="row">
          <form onSubmit={PoemAddbooks} className="update__forms">
            <h3 className="myaccount-content"> Edit </h3>
            <div className="form-row">
              <div className="form-group col-md-12">
                <label className="font-weight-bold">
                  {" "}
                  Title <span className="required"> * </span>{" "}
                </label>
                <input
                  type="text"
                  name="title"
                  value={userInfo.title}
                  onChange={onChangeValue}
                  className="form-control"
                  placeholder="Title"
                  required
                />
              </div>
              <div className="form-group col-md-12">
                <label className="font-weight-bold">
                  {" "}
                  Categorie <span className="required"> * </span>{" "}
                </label>
                <input
                  type="text"
                  name="category"
                  value={userInfo.category}
                  onChange={onChangeValue}
                  className="form-control"
                  placeholder="Categorie"
                  required
                />
              </div>
              <div className="form-group col-md-12">
                <label className="font-weight-bold">
                  {" "}
                  Image <span className="required"> * </span>{" "}
                </label>
                <input
                  type="file"
                  name="image"
                  onChange={onChangeValue}
                  className="form-control"
                  placeholder="Image"
                />
              </div>
              <button disabled={userInfo.Image === null} onClick={updateImage}>
                Changer l'image
              </button>
              <div className="form-group col-md-12 editor">
                <label className="font-weight-bold">
                  {" "}
                  Description <span className="required"> * </span>{" "}
                </label>
                <Editor
                  editorState={description}
                  toolbarClassName="toolbarClassName"
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  onEditorStateChange={onEditorStateChange}
                />
                <textarea
                  style={{ display: "none" }}
                  disabled
                  ref={(val) => (userInfo.description = val)}
                  value={draftToHtml(
                    convertToRaw(description.getCurrentContent())
                  )}
                />
              </div>
              {isError !== null && <div className="errors"> {isError} </div>}
              <div className="form-group col-sm-12 text-right">
                <button type="submit" className="btn btn__theme">
                  {" "}
                  Submit{" "}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

EditBlog.propTypes = {
  post: PropTypes.object.isRequired,
};

export default EditBlog;
