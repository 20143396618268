import React from "react";
import { Carousel, Stack } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "./Testimonials.css";

import testimonialsavatar0 from "./images/tmoinal-avatar-0.svg";
import testimonialsavatar1 from "./images/tmoinal-avatar-1.svg";
import testimonialsavatar2 from "./images/tmoinal-avatar-2.svg";

const Testimonials = () => {
  //translate
  const { t } = useTranslation();
  const nbrtestimonials = t("home.authortestimonials", {
    returnObjects: true,
  }).length;

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 col-md-12">
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12 mt-5">
            <span className="tmonialstitlesection my-4">
              {t("home.testimonials")}
            </span>
            <div className="tmonials-intro">
              <p>
                {t("home.testimonials_pl1")}
                <span>{t("home.testimonials_pl2")}</span>
                {t("home.testimonials_pl3")}
              </p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12 my-1">
            <div className="row">
              <div className="col-sm-12 col-lg-12 col-md-12 ">
                <Carousel
                  className="tmonials-carousel d-flex"
                  autoPlay={true}
                  interval={7000000}
                  controls={false}
                >
                  {t("home.professionaltestimonials", {
                    returnObjects: true,
                  }).map((review, index) => (
                    <Carousel.Item
                      key={{ index }}
                      className="tmonials-carouselItem flex-fill"
                    >
                      <Stack
                        direction="horizontal"
                        className="justify-content-center align-items-center"
                        gap={2}
                      >
                        <div
                          className="row flex-fill"
                          style={{ marginBottom: "0px" }}
                        >
                          <div className="col-sm-12 col-lg-6 col-md-6 my-1 flex-fill">
                            <div className="tmonials-img">
                              <img
                                src={testimonialsavatar0}
                                alt={
                                  t("home.authortestimonials", {
                                    returnObjects: true,
                                  })[index]
                                }
                              />
                            </div>
                            <div className="tmonials-block">
                              <div className="tmonials-content">
                                <p>
                                  {
                                    t("home.contenttestimonials", {
                                      returnObjects: true,
                                    })[index]
                                  }
                                </p>
                              </div>
                              <hr className="tmonials-hr" />
                              <div className="tmonials-ft d-flex flex-row">
                                <div className="tmonials-author">
                                  <span className="tmonials-author-name">
                                    {
                                      t("home.authortestimonials", {
                                        returnObjects: true,
                                      })[index]
                                    }
                                  </span>
                                  <span className="tmonials-author-professional">
                                    {
                                      t("home.professionaltestimonials", {
                                        returnObjects: true,
                                      })[index]
                                    }
                                  </span>
                                </div>
                                <div className="tmonials-rate">
                                  <span
                                    className={`ratestar${
                                      t("home.ratetestimonials", {
                                        returnObjects: true,
                                      })[index]
                                    }`}
                                  ></span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {index < nbrtestimonials - 1 ? (
                            <div className="col-sm-12 col-lg-6 col-md-6 my-1 flex-fill">
                              <div className="tmonials-img">
                                <img
                                  src={testimonialsavatar1}
                                  alt={
                                    t("home.authortestimonials", {
                                      returnObjects: true,
                                    })[index + 1]
                                  }
                                />
                              </div>
                              <div className="tmonials-block">
                                <div className="tmonials-content">
                                  <p>
                                    {
                                      t("home.contenttestimonials", {
                                        returnObjects: true,
                                      })[index + 1]
                                    }
                                  </p>
                                </div>
                                <hr className="tmonials-hr" />
                                <div className="tmonials-ft d-flex flex-row">
                                  <div className="tmonials-author">
                                    <span className="tmonials-author-name">
                                      {
                                        t("home.authortestimonials", {
                                          returnObjects: true,
                                        })[index + 1]
                                      }
                                    </span>
                                    <span className="tmonials-author-professional">
                                      {
                                        t("home.professionaltestimonials", {
                                          returnObjects: true,
                                        })[index + 1]
                                      }
                                    </span>
                                  </div>
                                  <div className="tmonials-rate">
                                    <span
                                      className={`ratestar${
                                        t("home.ratetestimonials", {
                                          returnObjects: true,
                                        })[index + 1]
                                      }`}
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="col-sm-12 col-lg-6 col-md-6 my-1 flex-fill">
                              <div className="tmonials-img">
                                <img
                                  src={testimonialsavatar2}
                                  alt={
                                    t("home.authortestimonials", {
                                      returnObjects: true,
                                    })[0]
                                  }
                                />
                              </div>
                              <div className="tmonials-block">
                                <div className="tmonials-content">
                                  <p>
                                    {
                                      t("home.contenttestimonials", {
                                        returnObjects: true,
                                      })[0]
                                    }
                                  </p>
                                </div>
                                <hr className="tmonials-hr" />
                                <div className="tmonials-ft d-flex flex-row">
                                  <div className="tmonials-author">
                                    <span className="tmonials-author-name">
                                      {
                                        t("home.authortestimonials", {
                                          returnObjects: true,
                                        })[0]
                                      }
                                    </span>
                                    <span className="tmonials-author-professional">
                                      {
                                        t("home.professionaltestimonials", {
                                          returnObjects: true,
                                        })[0]
                                      }
                                    </span>
                                  </div>
                                  <div className="tmonials-rate">
                                    <span
                                      className={`ratestar${
                                        t("home.ratetestimonials", {
                                          returnObjects: true,
                                        })[0]
                                      }`}
                                    ></span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </Stack>
                    </Carousel.Item>
                  ))}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
