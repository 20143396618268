import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./AdresseDetails.css";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";

import Questions from "../../../components/Questions/Questions";
import Section1 from "../../../components/Adresse/AdresseDetails/Section1/Section1";

const AddressDetails = (props) => {
  const { isAuthenticated, user } = props.auth;

  return (
    <div>
      <Header isLoggedIn={isAuthenticated} user={user} />
      <div className="adresse-detail-background"></div>

      <Section1 />

      <Questions />
      <Footer />
    </div>
  );
};

AddressDetails.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AddressDetails);
