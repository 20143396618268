import React from "react";
import { Route, Routes } from "react-router-dom";

import Register from "../pages/Auth/Register/Register";
import Login from "../pages/Auth/Login/Login";
import Contact from "../pages/Contact/Contact";
import ForgotPassword from "../pages/Auth/ForgotPassword/ForgotPassword";

import MonCompte from "../pages/espaces/EspaceVendeur/MonCompte";
import AcceuilDash from "../pages/espaces/EspaceVendeur/AcceuilDash/AcceuilDash";
import SupportDash from "../pages/espaces/EspaceVendeur/SupportDash/SupportDash";
import AnnonceDash from "../pages/espaces/EspaceVendeur/AnnonceDash/AnnonceDash";
import CompteDash  from "../pages/espaces/EspaceVendeur/CompteDash/CompteDash";
import DomicilezDash from "../pages/espaces/EspaceVendeur/DomicilezDash/DomicilezDash";
import ParametreDash from "../pages/espaces/EspaceVendeur/ParametreDash/ParametreDash";
import CreeAnnonce1 from "../pages/espaces/EspaceVendeur/CreeAnnonce1/CreeAnnonce1";
import CreeAnnonce2 from "../pages/dashboard/creeAnnonce2/creeAnnonce2";
import Paiement from "../pages/espaces/EspaceVendeur/Paiement/Paiement";
import Validation from "../pages/espaces/EspaceVendeur/Validation/Validation";
import EditProfile from "../pages/espaces/EspaceVendeur/CompteDash/EditeProfile/EditeProfile";
import Notification from "../pages/espaces/EspaceVendeur/CompteDash/Notification/Notification";
import EditValid from "../pages/espaces/EspaceVendeur/CompteDash/EditValid/EditValid";
import ModifierCompte from "../components/Modifier-Compte/ModifierCompte";
import EditAnnonce from "../components/EditAnnonce/EditAnnonce";

import Home from "../pages/Home/Home";
import Tarifs from "../pages/Tarifs/Tarifs";
import Services from "../pages/Services/Services";
import EspaceVendeur from "../pages/espaces/EspaceVendeur/OLD/Home/EspaceVendeur";
import EspaceAcheteur from "../pages/espaces/EspaceAcheteur/Home/EspaceAcheteur";
import DemandeAcheteur from "../pages/demandes/DemandeAcheteur";
import DemandeVendeur from "../pages/demandes/DemandeVendeur";
import GestionCompte from "../pages/GestionCompte/GestionCompte";
import AdresseList from "../pages/Adresse/AdressesList/AdresseList";

import AcheteurProfileRoute from "./privateroute/AcheteurProfileRoute";
import VendeurProfileRoute from "./privateroute/VendeurProfileRoute";

import DemandeAcheteurRoute from "./privateroute/DemandeAcheteurRoute";
import DemandeVendeurRoute from "./privateroute/DemandeVendeurRoute";

import PrivateRoute from "./privateroute/PrivateRoute";
import AdminRoute from "./privateroute/AdminRoute";
import AcheteurRoute from "./privateroute/AcheteurRoute";
import VendeurRoute from "./privateroute/VendeurRoute";

import ProfilesList from "../pages/espaces//EspaceAdmin/ProfilesList/ProfilesList";
import UsersList from "../pages/espaces/EspaceAdmin/UsersList/UsersList";
import SubscribersList from "../pages/espaces/EspaceAdmin/SubscribersList/SubscribersList";
import AdressesList from "../pages/espaces/EspaceAdmin/AdressesList/AdressesList";

import MesAdresses from "../pages/espaces/EspaceVendeur/OLD/MesAdresses/MesAdresses";
import AjoutAdresse from "../pages/espaces/EspaceVendeur/OLD/AjoutAdresse/AjoutAdresse";
import ModifAdresse from "../pages/espaces/EspaceVendeur/OLD/ModifAdresse/ModifAdresse";
import MonProfile from "../pages/espaces/EspaceVendeur/OLD/MonProfile/MonProfile";
import MonProfileAcheteur from "../pages/espaces/EspaceAcheteur/MonProfile/MonProfileAcheteur";
import MesContrats from "../pages/espaces/EspaceVendeur/OLD/MesContrats/MesContrats";
import MesContratsAcheteur from "../pages/espaces/EspaceAcheteur/MesContrats/MesContratsAcheteur";
import AdresseDetails from "../pages/Adresse/AdresseDetails/AdresseDetails";
import AssistanceList from "../pages/espaces/EspaceAdmin/AssistanceList/AssistanceList";
import Errorpage from "../pages/Errorpage";
import ResetPassword from "../pages/Auth/ResetPassword/ResetPassword";
import Blogs from "../pages/Blog/Blogs";
import BlogList from "../pages/espaces/EspaceAdmin/Blog/BlogList/BlogList";
import AddBlog from "../pages/espaces/EspaceAdmin/Blog/AddBlog/AddBlog";
import UpdateBlog from "../pages/espaces/EspaceAdmin/Blog/UpdateBlog/UpdateBlog";
import BlogDetails from "../pages/Blog/BlogDetails/BlogDetails";

const RouteList = () => {
  return (
    <Routes>
      <Route path="*" element={<Errorpage />} />
      <Route exact path="/register" element={<Register />} />
      <Route exact path="/login" element={<Login />} />
      <Route
        exact
        path="/reinitialisation-mot-de-passe"
        element={<ForgotPassword />}
      />
      <Route
        exact
        path="/reinitialisation-mot-de-passe/reset-password/:code"
        element={<ResetPassword />}
      />
      <Route exact path="/" element={<Home />} />
      <Route exact path="/home" element={<Home />} />
      <Route exact path="/Contact" element={<Contact />} />
      <Route exact path="/adresses" element={<AdresseList />} />
      <Route exact path="/adresse/:id" element={<AdresseDetails />} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/tarifs" element={<Tarifs />} />
      <Route exact path="/Blogs" element={<Blogs />} />

      <Route exact path="/blog/:id" element={<BlogDetails />} />

      <Route
        exact
        path="/mon-compte"
        element={
          <PrivateRoute>
            {" "}
            <MonCompte />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/mon-compte/gestion"
        element={
          <PrivateRoute>
            {" "}
            <GestionCompte />{" "}
          </PrivateRoute>
        }
      />

      <Route
        exact
        path="/profile/acheteur"
        element={
          <DemandeAcheteurRoute>
            {" "}
            <DemandeAcheteur />{" "}
          </DemandeAcheteurRoute>
        }
      />
      <Route
        exact
        path="/profile/vendeur"
        element={
          <DemandeVendeurRoute>
            {" "}
            <DemandeVendeur />{" "}
          </DemandeVendeurRoute>
        }
      />

      <Route
        exact
        path="/espace-admin/liste-de-users"
        element={
          <AdminRoute>
            {" "}
            <UsersList />{" "}
          </AdminRoute>
        }
      />
      <Route
        exact
        path="/espace-admin/liste-de-profiles"
        element={
          <AdminRoute>
            {" "}
            <ProfilesList />{" "}
          </AdminRoute>
        }
      />
      <Route
        exact
        path="/espace-admin/subscriptions"
        element={
          <AdminRoute>
            {" "}
            <SubscribersList />{" "}
          </AdminRoute>
        }
      />
      <Route
        exact
        path="/espace-admin/adresses"
        element={
          <AdminRoute>
            {" "}
            <AdressesList />{" "}
          </AdminRoute>
        }
      />
      <Route
        exact
        path="/espace-admin/assistance"
        element={
          <AdminRoute>
            {" "}
            <AssistanceList />{" "}
          </AdminRoute>
        }
      />

      <Route
        exact
        path="/espace-admin/blogs"
        element={
          <AdminRoute>
            {" "}
            <BlogList />{" "}
          </AdminRoute>
        }
      />

      <Route
        exact
        path="/espace-admin/ajouter-un-blog"
        element={
          <AdminRoute>
            {" "}
            <AddBlog />{" "}
          </AdminRoute>
        }
      />

      <Route
        exact
        path="/espace-admin/modifier-un-blog/:id"
        element={
          <AdminRoute>
            {" "}
            <UpdateBlog />{" "}
          </AdminRoute>
        }
      />

      <Route
        exact
        path="/espace-vendeur"
        element={
          <VendeurProfileRoute>
            {" "}
            <EspaceVendeur />{" "}
          </VendeurProfileRoute>
        }
      />
      <Route
        exact
        path="/espace-vendeur/mon-profile"
        element={
          <VendeurProfileRoute>
            {" "}
            <MonProfile />{" "}
          </VendeurProfileRoute>
        }
      />

      <Route
        exact
        path="/espace-vendeur/mes-adresses"
        element={
          <VendeurRoute>
            {" "}
            <MesAdresses />{" "}
          </VendeurRoute>
        }
      />
      <Route
        exact
        path="/espace-vendeur/mes-contrats"
        element={
          <VendeurRoute>
            {" "}
            <MesContrats />{" "}
          </VendeurRoute>
        }
      />
      <Route
        exact
        path="/espace-vendeur/ajouter-une-adresse"
        element={
          <VendeurRoute>
            {" "}
            <AjoutAdresse />{" "}
          </VendeurRoute>
        }
      />
      <Route
        exact
        path="/espace-vendeur/modifier-une-adresse/:id"
        element={
          <VendeurRoute>
            {" "}
            <ModifAdresse />{" "}
          </VendeurRoute>
        }
      />

      <Route
        exact
        path="/espace-acheteur"
        element={
          <AcheteurProfileRoute>
            {" "}
            <EspaceAcheteur />{" "}
          </AcheteurProfileRoute>
        }
      />
      <Route
        exact
        path="/espace-acheteur/mon-profile"
        element={
          <AcheteurProfileRoute>
            {" "}
            <MonProfileAcheteur />{" "}
          </AcheteurProfileRoute>
        }
      />

      <Route
        exact
        path="/espace-acheteur/mes-contrats"
        element={
          <AcheteurRoute>
            {" "}
            <MesContratsAcheteur />{" "}
          </AcheteurRoute>
        }
      />
      <Route 
      exact
      path="/dashboard/dashboardAccueil"
      element={
        <PrivateRoute>
          {" "}
          <AcceuilDash />{" "}
        </PrivateRoute>
      }/>
      <Route 
      exact
      path="/dashboard/ParametreDash"
      element={
        <PrivateRoute>
          {" "}
          <ParametreDash />{" "}
        </PrivateRoute>
      }/>
      <Route 
      exact
      path="/dashboard/SupportDash"
      element={
        <PrivateRoute>
          {" "}
          <SupportDash />{" "}
        </PrivateRoute>
      }/>
      <Route 
      exact
      path="/dashboard/AnnonceDash"
      element={
        <PrivateRoute>
          {" "}
          <AnnonceDash />{" "}
        </PrivateRoute>
      }/>
      <Route 
      exact
      path="/dashboard/CompteDash"
      element={
        <PrivateRoute>
          {" "}
          <CompteDash />{" "}
        </PrivateRoute>
      }/>
      <Route 
      exact
      path="/dashboard/DomicilezDash"
      element={
        <PrivateRoute>
          {" "}
          <DomicilezDash />{" "}
        </PrivateRoute>
      }/>
      <Route 
      exact
      path="/dashboard/CreeAnnonce1"
      element={
        <PrivateRoute>
          {" "}
          <CreeAnnonce1 />{" "}
        </PrivateRoute>
      }/>
      <Route 
      exact
      path="/espace-vendeur/Paiement"
      element={
        <PrivateRoute>
          {" "}
          <Paiement />{" "}
        </PrivateRoute>
      }/>
      <Route 
      exact
      path="/espace-vendeur/Validation"
      element={
        <PrivateRoute>
          {" "}
          <Validation />{" "}
        </PrivateRoute>
      }/>
      <Route
        exact
        path="/espace-vendeur/EditeProfile"
        element={
          <PrivateRoute>
            {" "}
            <EditProfile />{" "}
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/espace-vendeur/Notification"
        element={
          <PrivateRoute>
            {" "}
            <Notification />{" "}
          </PrivateRoute>
        }
      />
      
      <Route
        exact
        path="/espace-vendeur/EditeProfile/EditValid"
        element={
          <PrivateRoute>
            {" "}
            <EditValid />{" "}
          </PrivateRoute>
        }
      />
      
      <Route
        exact
        path="/espace-vendeur/EditAnnonce/:id"
        element={
          <PrivateRoute>
            {" "}
            <EditAnnonce />{" "}
          </PrivateRoute>
        }
      />

      
        
    
    </Routes>
    
  );
};

export default RouteList;
