import React from "react";
import Header from "../../../../components/header/Header";
import { Link } from "react-router-dom";
import imgmesannonces from "../../../../images/imgmesannonces.png"; 
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
import SideBar from "../../../../components/SideBars/AcheteurSideBar/SideBar";
import "./MesContratsAcheteur.css";
import "../../Espace.css"

const MesContratsAcheteur = (props) => {
  const { user, isAuthenticated } = props.auth;

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };
  return (
    <div className="espace-container">
      <Header isLoggedIn={isAuthenticated} user={user} onLogoutClick={onLogoutClick}/>
        <div className="espace-vendeur">
        <SideBar className="side-bar"/>
      <div className="home" >
      
      <img src={imgmesannonces} alt="ph" width="400" height="300" />
        <h2 >Vous n’avez pas d’annonces déposées sur DomicilPro (pour l’instant !)</h2>
        <p >Service 100% gratuit, photos illimitées, des conseils à toutes les étapes de votre projet. Vous pourrez gérer vos annonces depuis cet espace.</p>

        <Link to="/ee" className="btn btn-primary" style={{margin:"30px"}}>Déposer vos annonces</Link>
      </div>
      </div>
    </div>
  );
};

MesContratsAcheteur.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(MesContratsAcheteur);
