import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import axios from "axios";
import "../Auth.css";
import "./ForgotPassword.css";

const ForgotPassword = (props) => {
  const [sent, setSent] = useState(false);

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      window.location.href = "/mon-compte";
    }
  }, [props.auth, props.history]);

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Get the email input value
    const email = document.getElementById("email").value;

    // Define the data to be sent in the POST request
    const data = {
      Email: email,
    };

    try {
      // Make the POST request using Axios
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/auth/generate-code",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      if (response.status === 200) {
        setSent(true);
      }

      // Handle the response (e.g., show a success message)
      console.log("POST request successful", response.data);
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error("Error making POST request", error);
    }
  };

  return (
    <div>
      <div className="overbgimg "></div>
      <Header />
      <div className="forgot-ground">
        <div
          style={{ marginTop: "4rem", width: "90%" }}
          className="row login-div"
        >
          <div className="col-md-5 offset-md-2">
            <h1 className="title">Trouvez votre compte</h1>
            {sent === true ? (
              <div className="alert alert-success" role="alert">
                Un code d'activation vous a été envoyé par e-mail.
              </div>
            ) : (
              <form noValidate onSubmit={handleFormSubmit}>
                <div className="inline-container"></div>
                <input
                  id="email"
                  type="email"
                  className="login-input"
                  placeholder="Veuillez entrer votre adresse e-mail"
                />
                <div className="d-grid gap-2">
                  <button type="submit" className="btn button">
                    Recevoir mon code d'activation
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

ForgotPassword.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ForgotPassword);
