import React, { useState, useEffect } from 'react';
import { BsPinAngleFill } from 'react-icons/bs';
import { PiDotsThreeBold } from 'react-icons/pi';
import './AnnonceEnAttente.css';

const Annonce = (props) => {
  const { titre, date, onEdit, onPin, onDelete, data, isPinned } = props;
  const [annonceHovered, setAnnonceHovered] = useState(false);
  const [showActions, setShowActions] = useState(false);
  useEffect(() => {
  }, [isPinned]);

  const handleMoreOptionsClick = () => {
    setShowActions(!showActions);
  };

  return (
    <div
      className={`annonce bd-content ps-lg-2 widthAnnonce ${annonceHovered ? 'hovered' : ''} ${isPinned ? 'pinned' : ''}`}
      onMouseEnter={() => setAnnonceHovered(true)}
      onMouseLeave={() => setAnnonceHovered(false)}
    >
      <div className={`annonce-details ${isPinned ? 'pinned' : ''}`}>
        <h3 className={`annonce-titre ${isPinned ? 'pinned' : ''}`}>{titre}</h3>
        <p className={`annonce-date ${isPinned ? 'pinned' : ''}`}>{date}</p>
      </div>
      <div className="">
        <div className="annonce-actions">
          <button
            className={`annonce-edit ${isPinned ? 'pinned' : ''} ${isPinned ? 'active' : ''}`}
            title={isPinned ? 'Unpin Address' : 'Pin Address'}
            onClick={() => onPin(data._id)}
            style={{ color: data.isPinned ? '#00A4B8' : 'inherit' }}

          >
            <BsPinAngleFill />
          </button>
          <button
            onClick={handleMoreOptionsClick}
            className={`annonce-options ${annonceHovered ? 'hovered' : ''}`}
            title="Plus d'options"
          >
            <PiDotsThreeBold />
          </button>
          <ul className={`dropdown-menu dropdown-menu-dark text-small shadow ${showActions ? 'show' : ''}`}>
            <li>
              <div onClick={() => onEdit(data._id)}>Modifier</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Annonce;
