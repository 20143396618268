

import React, { Component } from 'react'
import SideBar1 from '../../../../../components/sidebarDash/Sidebar1'
import NavBarDash from '../../../../../components/navbarDash/NavbarDash1'
import Footer from '../../../../../components/footer/Footer'
import './EditeProfile.css'
import ModifierCompte from '../../../../../components/Modifier-Compte/ModifierCompte'

export default class EditeProfile extends Component {
  render() {
    return (
      <>
        <div className="background ">
        </div>
        <div className="row m-0 p-0 fixationItem">
          
          
        <div className="col-1 col-lg-1 col-xl-2 col-md-1 col-sm-1 sidebar-compte">
        <SideBar1/>
        </div>
        
        <div className="col-11 col-md-11 col-lg-11 col-xl-10 col-sm-11">
          <div className="row">
        <NavBarDash/>
        </div>
        <div className='bodycreate'>
        <div className=''>
            <div className='textcreeAnnonce textCompte'>Mon Compte</div>
        </div>
        <div className="row">
        <div  className=" ">
        <div className="bodyDashMonCompte ">
          <div className='row'>
          <ModifierCompte/>
          </div>
            </div>
          
          </div>
          </div>
        </div>
        
        </div>
        </div>
         
        <Footer/>
        </>
    )
  }
}
