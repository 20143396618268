import React, { Component } from 'react'
import SideBar1 from '../../../../components/sidebarDash/Sidebar'
import "./AcceuilDash.css"
import NavBarDash from "../../../../components/navbarDash/navBarDash";
import ListeAnnonce from '../../../../components/ListeAnnonce/ListeAnnonces';
import DashboardApp from '../../../../components/informationDash/DashboardApp';
import Footer from "../../../../components/footer/Footer";

export default class AcceuilDash extends Component {
  render() {
    return (
      <>
      <div className="background ">
      </div>
      <div className="row m-0 p-0 fixationItem">
        
        
      <div className="col-1 col-lg-1 col-xl-2 col-md-1 col-sm-1 sidebar-compte">
      <SideBar1/>
      </div>
      
      <div className="col-11 col-md-11 col-lg-11 col-xl-10 col-sm-11">
        <div className="row">
      <NavBarDash/>
      </div>
      <div className="row xlam">
        <div className='col-11  col-sm-9	col-md-10 col-lg-8'>
           <div className=''>
           <DashboardApp/>
           </div>
           </div>
          </div>
          
          </div>
        </div>
        
        <Footer/>
        </>
    )
  }
}
