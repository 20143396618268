import React from "react";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

const Errorpage = (props) => {
  const { isAuthenticated, user } = props.auth;

  return (
    <div classNameName="vh-100">
      <Header isLoggedIn={isAuthenticated} user={user} />
      <div
        className="d-flex align-items-center justify-content-center "
        style={{ height: "60vh" }}
      >
        <div className="text-center">
          <h1 className="display-1 fw-bold">404</h1>
          <p className="fs-3">
            <span className="text-danger">Opps!</span> Page not found.
          </p>
          <p className="lead">The page you’re looking for doesn’t exist.</p>
          <Link to="/" className="btn btn-primary">
            Go Home
          </Link>
        </div>
      </div>
      <Footer />
    </div>
  );
};

Errorpage.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Errorpage);
