import React from 'react';
import { BiSearch } from 'react-icons/bi';
import { MdMessage } from "react-icons/md";
import { IoIosNotifications } from "react-icons/io";
import { IoIosArrowDown } from "react-icons/io";
import './navBarDash.css';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import PropTypes from "prop-types";

const NavBarDash = (props) => {
  const { user } = props.auth;
  const isAdmin = props.auth.isAdmin;

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };

  return (
    <header className="content">
      <div className="bd-gutter flex-wrap flex-lg-nowrap">
        <div className='content--header bo'>
          <div className="search--box">
            <input className='recherche' type="text" placeholder='Recherche' />
            <BiSearch className='icon icon1 serche' />
          </div>
          <div className="header--activity">
            <div className='notify'>
              <IoIosNotifications className='icon icon1 icomNotif' />
            </div>
            <div className='message'>
              <MdMessage className='icon icon1' />
            </div>
            <div className='compte'>
              <div className="d-flex align-items-center text-decoration-none " id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                <img className='profimg' src={"https://images.unsplash.com/photo-1682687218904-de46ed992b58?q=80&w=1843&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} alt="" className='imga' />
                <div className='zoneNom'>
                  {user.Profiles.Nom}{' '}{user.Profiles.Prenom}
                </div>
                <IoIosArrowDown className='icon' />
              </div>
              <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
                {isAdmin && (
                  <>
                  <li>
                    <Link className="dropdown-item" to={"/espace-admin/liste-de-users"}>
                      Espace Admin
                    </Link>
                  </li>
                  <li><hr className="dropdown-divider" /></li>
                  </>
                )}
                
                <li>
                  <button className="dropdown-item" onClick={onLogoutClick}>
                    Sign out
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className='trait'></div>
      </div>
    </header>
  );
};

NavBarDash.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(NavBarDash);
