import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "../../../../components/header/Header";
import { useQuery } from "react-query";
import AdminSideBar from "../../../../components/EspaceAdmin/AdminSideBar";
import { fetchData, getaccountsnumber } from "./UserList";
import DateTimeConvertion from "../../../../utils/DateTimeConvertion";

const UsersList = (props) => {
  const { user, isAuthenticated } = props.auth;

  const [accountsnumber, setAccountsnumber] = useState(0);
  const [currentPage, setCurrentPage] = useState(1); // Added state for current page
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const { data, isLoading, error } = useQuery("profiles", fetchData);

  useEffect(() => {
    async function fetchAccountsNumber() {
      const accountsNum = await getaccountsnumber();
      setAccountsnumber(accountsNum);
    }
    fetchAccountsNumber();
  }, []);

  const sortData = (data, column, order) => {
    return [...data].sort((a, b) => {
      if (column === "Email") {
        if (order === "asc") {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      } else if (column === "createdAt" || column === "updatedAt") {
        const dateA = new Date(a[column]);
        const dateB = new Date(b[column]);
        if (order === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      }
      return 0;
    });
  };

  const [sortColumn, setSortColumn] = useState("Email");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortClick = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  if (isLoading) {
    return (
      <div>
        <Header isLoggedIn={isAuthenticated} user={user} />
        Loading...
      </div>
    );
  }

  if (error) {
    return (
      <div>
        <Header isLoggedIn={isAuthenticated} user={user} />
        Error fetching data from API: {error.message}
      </div>
    );
  }

  // Calculate total number of pages based on data length and rows per page
  const totalPages = Math.ceil(accountsnumber / rowsPerPage);

  // This function handles the change in page
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Calculate the index range for current page
  const sortedData = sortData(data, sortColumn, sortOrder);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, sortedData.length);
  const visibleData = sortedData.slice(startIndex, endIndex);

  return (
    <div>
      <Header isLoggedIn={isAuthenticated} user={user} />

      <AdminSideBar />

      <div style={{ margin: "50px" }}>
        <button
          className="btn btn-primary"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasExample"
          aria-controls="offcanvasExample"
        >
          Admin Dashboard
        </button>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col" onClick={() => handleSortClick("Email")}>
                Email
                {sortColumn === "Email" && (
                  <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                )}
              </th>
              <th scope="col" onClick={() => handleSortClick("createdAt")}>
                Date Ajout
                {sortColumn === "createdAt" && (
                  <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                )}
              </th>
              <th scope="col" onClick={() => handleSortClick("updatedAt")}>
                Date Modif
                {sortColumn === "updatedAt" && (
                  <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                )}
              </th>
              <th scope="col">Roles</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            {visibleData.map((item, index) => (
              <tr key={item.id}>
                <th scope="row">{index + 1}</th>
                <td>{item.Email}</td>
                <td>{DateTimeConvertion(item.createdAt)}</td>
                <td>
                  {item.createdAt !== item.updatedAt
                    ? DateTimeConvertion(item.updatedAt)
                    : ""}
                </td>
                <td>{item.Roles.map((role) => role.Type).join(", ")}</td>
                <td>
                  <button
                    onClick={() => {}}
                    className="btn btn-primary"
                    /*data-bs-toggle="modal"
                    data-bs-target="#exampleModal"*/
                  >
                    Voir
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {/* Render pagination */}
        <div>
          <select
            className="justify-content-left"
            style={{ width: "50px" }}
            onChange={(e) => {
              setRowsPerPage(Number(e.target.value));
              setCurrentPage(1);
            }}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>

          <ul className="pagination justify-content-center">
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                &laquo;
              </button>
            </li>
            {Array.from({ length: totalPages }).map((_, index) => (
              <li key={index} className="page-item">
                <button
                  className={`page-link ${
                    index + 1 === currentPage ? "active" : ""
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </button>
              </li>
            ))}
            <li className="page-item">
              <button
                className="page-link"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                &raquo;
              </button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

UsersList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(UsersList);
