import { useState } from "react";
import Icon from "react-icons-kit";
import { basic_eye } from "react-icons-kit/linea/basic_eye";
import { basic_eye_closed } from "react-icons-kit/linea/basic_eye_closed";
import { arrows_exclamation } from "react-icons-kit/linea/arrows_exclamation";
import { arrows_circle_check } from "react-icons-kit/linea/arrows_circle_check";
import axios from "axios";

function Validation({ userid }) {
  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState("");
  const [ErrorMessage, setErrorMessage] = useState(null);

  const handleChange = (value) => {
    const lower = /(?=.*[a-z])/;
    const upper = /(?=.*[A-Z])/;
    const number = /(?=.*\d)/;
    const length = /(?=.{8,})/;
    if (lower.test(value)) {
      setLowerValidated(true);
    } else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    } else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    } else {
      setNumberValidated(false);
    }

    if (length.test(value)) {
      setLengthValidated(true);
    } else {
      setLengthValidated(false);
    }
    setPassword(value);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if any of the validations fail
    if (
      !lowerValidated ||
      !upperValidated ||
      !numberValidated ||
      !lengthValidated
    ) {
      setErrorMessage("Please fix the password issues before submitting.");
      return;
    }

    //axios put
    //setErrorMessage(false);
    try {
      const response = await axios.put(
        process.env.REACT_APP_API_URL + "/api/users/updatepassword/" + userid,
        { Password: password },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      // Check the response status code to confirm the update was successful.
      if (response.status === 200) {
        alert("Password updated successfully");
      } else {
        alert("Failed to update password");
      }
    } catch (error) {
      // Handle any errors that occur during the Axios request.
      console.error("Error updating password:", error);
      alert("An error occurred while updating the password");
    }
  };
  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h6 className="pt-3">Votre mot de passe </h6>
        <div className="mb-3  col-lg-6 col-md-6 ">
          <input
            id="password"
            className="input-box"
            type={passwordVisible ? "text" : "password"}
            onChange={(e) => handleChange(e.target.value)}
            style={{ marginRight: "10px" }}
          />
          <button
            type="button"
            onClick={() => setPasswordVisible((prevVisible) => !prevVisible)}
            className="password-toggle-button"
            style={{
              border: "none",
              background: "transparent",
              cursor: "pointer",
            }}
          >
            {passwordVisible ? (
              <Icon icon={basic_eye_closed} />
            ) : (
              <Icon icon={basic_eye} />
            )}
          </button>
          <button
            type="submit"
            className="btn-submit  col-lg-6 col-md-6 w-100 "
          >
            Submit
          </button>
          {
            // Show the error message if the showError state is set to true.
            ErrorMessage && (
              <div
                style={{ marginTop: "10px" }}
                className="alert alert-primary"
                role="alert"
              >
                {ErrorMessage}
              </div>
            )
          }
        </div>
      </form>

      {/* validation tracker */}
      <main className="tracker-box pt-3">
        <div className={lowerValidated ? "validated" : "not-validated"}>
          {lowerValidated ? (
            <span className="list-icon ">
              <Icon icon={arrows_circle_check} />
            </span>
          ) : (
            <span className="list-icon">
              <Icon icon={arrows_exclamation} />
            </span>
          )}
          At least one lowercase letter
        </div>
        <div className={upperValidated ? "validated" : "not-validated"}>
          {upperValidated ? (
            <span className="list-icon ">
              <Icon icon={arrows_circle_check} />
            </span>
          ) : (
            <span className="list-icon">
              <Icon icon={arrows_exclamation} />
            </span>
          )}
          At least one uppercase letter
        </div>
        <div className={numberValidated ? "validated" : "not-validated"}>
          {numberValidated ? (
            <span className="list-icon ">
              <Icon icon={arrows_circle_check} />
            </span>
          ) : (
            <span className="list-icon">
              <Icon icon={arrows_exclamation} />
            </span>
          )}
          At least one number
        </div>
        <div className={lengthValidated ? "validated" : "not-validated"}>
          {lengthValidated ? (
            <span className="list-icon ">
              <Icon icon={arrows_circle_check} />
            </span>
          ) : (
            <span className="list-icon">
              <Icon icon={arrows_exclamation} />
            </span>
          )}
          At least 8 characters
        </div>
      </main>
    </div>
  );
}

export default Validation;
