import { Link } from "react-router-dom";

const Card = (props) => {
  return (
    <div className="section1-card">
      <div className="row">
        <div
          className="col-md-6"
          style={{ padding: "30px", paddingLeft: "50px" }}
        >
          <h3 className="tarif-Type">{props.Duree}</h3>
          <p className="section1-card-detail">{props.detail} </p>
        </div>

        <div className="col-md-6">
          <div className="right">
            <div className="price">{props.prix}€</div>
            <Link to={"/adresses"} style={{ textDecoration: "none " }}>
              <button className="price-btn" style={{ background: props.color }}>
                Choisir mon adresse de domiciliation
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Card;
