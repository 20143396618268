import React from "react";
import Header from "../../../../../components/header/Header";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../../actions/authActions";
import SideBar from "../../../../../components/SideBars/VendeurSideBar/SideBar";
import "./MesContrats.css";
import "../../../Espace.css";

const MesContrats = (props) => {
  const { user, isAuthenticated } = props.auth;

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };
  return (
    <div className="espace-container">
      <Header isLoggedIn={isAuthenticated} user={user} onLogoutClick={onLogoutClick}/>
        <div className="espace-vendeur">
        <SideBar className="side-bar"/>
      <div className="home" >
      
      <h1>Mes Contrats</h1>
      
      </div>
      </div>
    </div>
  );
};

MesContrats.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(MesContrats);
