import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { changeField } from "../../../utils/changeFieldPageVendeur";
import "./common.css";

const Offres = (props) => {
  const [formValues, setFormValues] = useState({
    Starter: 0,
    Business: 20,
    Premium: 40,
    PrixParCourrier: 2.5,
  });

  useEffect(() => {
    //get data from props
    if (props.OffresCourrier) {
      setFormValues({
        Starter: props.OffresCourrier.Starter,
        Business: props.OffresCourrier.Business,
        Premium: props.OffresCourrier.Premium,
        PrixParCourrier: props.PrixParCourrier,
      });
    }
  }, [props.OffresCourrier, props.PrixParCourrier]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: parseFloat(value), // Convert to a number
    }));
  };

  const handleNextClick = () => {
    // Store formValues in local storage
    let OffresCourrier = [
      {
        Nom: "Starter",
        Description: "Offre Starter",
        Prix: formValues.Starter,
      },
      {
        Nom: "Business",
        Description: "Offre Business",
        Prix: formValues.Business,
      },
      {
        Nom: "Premium",
        Description: "Offre Premium",
        Prix: formValues.Premium,
      },
    ];

    let adresse = JSON.parse(localStorage.getItem("adresse"));
    adresse.OffresCourrier = OffresCourrier;
    adresse.PrixParCourrier = formValues.PrixParCourrier;
    localStorage.setItem("adresse", JSON.stringify(adresse));

    // Call your changeField function
    changeField("Justificatifs");
  };

  return (
    <form className="row g-3">
      <h2 className="text-center">Les Offres Courriers</h2>
      <hr style={{ color: "white" }} />

      <div className="col-md-4">
        <label className="prix-label">Prix pour l'offre Starter :</label>
      </div>
      <div className="col-md-5">
        <input
          id="Starter"
          name="Starter"
          type="number"
          className="inputs"
          value={formValues.Starter}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-md-4">
        <label className="prix-label">Prix pour l'offre Business :</label>
      </div>
      <div className="col-md-5">
        <input
          id="Business"
          name="Business"
          type="number"
          className="inputs"
          value={formValues.Business}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-md-4">
        <label className="prix-label">Prix pour l'offre Premium :</label>
      </div>
      <div className="col-md-5">
        <input
          id="Premium"
          name="Premium"
          type="number"
          className="inputs"
          value={formValues.Premium}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-md-4">
        <label className="prix-label">Prix Par Courrier :</label>
      </div>
      <div className="col-md-5">
        <input
          id="PrixParCourrier"
          name="PrixParCourrier"
          type="number"
          className="inputs"
          value={formValues.PrixParCourrier}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 d-flex justify-content-center">
        <button
          type="button"
          className="mon-boutonVendeur"
          onClick={handleNextClick}
          value={"Justificatifs"}
        >
          Next
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Offres);
