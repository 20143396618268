export default function DateTimeConvertion(date) {
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "long" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const hours = dateObj.getHours();
  const minutes = dateObj.getMinutes();
  const seconds = dateObj.getSeconds();
  const convertedDate = `${day} ${month}, ${year} ${hours}:${minutes}:${seconds}`;
  return convertedDate;
}
export function DateConvertion(date) {
  const dateObj = new Date(date);
  const month = dateObj.toLocaleString("default", { month: "long" });
  const day = dateObj.getDate();
  const year = dateObj.getFullYear();
  const convertedDate = `${day} ${month}, ${year}`;
  return convertedDate;
}
