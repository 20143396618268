import { Link } from "react-router-dom";
import "./Header.css";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import logo from "./logo.png";
function Header({ isLoggedIn }) {
  const { t, i18n } = useTranslation();

  const onChangeLang = (e) => {
    i18n.changeLanguage(e.target.value);
  };
  useEffect(() => {
    for (
      let index = 0;
      index < document.getElementsByClassName("lang").length;
      index++
    ) {
      const element = document.getElementsByClassName("lang")[index];
      if (element.value === i18n.language) {
        element.setAttribute("selected", "true");
      }
    }
  });

  return (
    <div className="container header-height">
      <header className="header">
        <nav className="navbar navbar-expand-lg  ">
          <div className="container-fluid">
            <Link className="navbar-brand" to="/home">
              <img
                src={logo}
                alt="logo"
                className="logo-img"
                width="53"
                height="58"
              />
            </Link>

            {/** START VERSION MOBILE */}
            <div className="d-flex flex-column justify-content-start align-items-start">
              <div className="d-xl-none d-lg-none d-md-block d-sm-block d-xs-none d-flex flex-row justify-content-between align-items-center">
                {isLoggedIn ? (
                  <span>
                    <Link
                      className="btnaccount btn-md"
                      href="#"
                      rel="noopener noreferrer"
                      to="/mon-compte"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        className="bi bi-person-circle"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                        <path
                          fillRule="evenodd"
                          d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                        />
                      </svg>
                      <span className="hideinsml">
                        &nbsp; {t("header.myaccount")}
                      </span>
                    </Link>
                  </span>
                ) : (
                  <Link
                    className="btnaccount"
                    href="#"
                    rel="noopener noreferrer"
                    to="/login"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-person-fill"
                      viewBox="0 0 16 20"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>
                    {t("header.login")}
                  </Link>
                )}

                <button
                  className="navbar-toggler ml-5"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
              </div>
            </div>

            {/** END VERSION MOBILE */}

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  m-auto mb-2 mb-lg-0 px-3">
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    rel="noopener noreferrer"
                    aria-current="page"
                    to="/home"
                    id="home"
                  >
                    {t("header.home")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    id="adresses"
                    rel="noopener noreferrer"
                    to="/adresses"
                  >
                    {t("header.adress")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    rel="noopener noreferrer"
                    to="/services"
                    id="services"
                  >
                    {t("header.Services")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    rel="noopener noreferrer"
                    to="/blogs"
                    id="blogs"
                  >
                    Blogs
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    rel="noopener noreferrer"
                    to="/tarifs"
                    id="tarifs"
                  >
                    {t("header.prices")}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link"
                    rel="noopener noreferrer"
                    to="/contact"
                    id="contact"
                  >
                    {t("Contact")}
                  </Link>
                </li>
                <li className="nav-item nav-lang">
                  <select className="form-select" onChange={onChangeLang}>
                    <option value="fr" className="lang">
                      FR
                    </option>
                    <option value="en" className="lang">
                      EN
                    </option>
                  </select>
                </li>
              </ul>

              {isLoggedIn ? (
                <div className="d-none d-lg-block d-xxl-block ">
                  <Link
                    className="btnaccount btn-md"
                    href="#"
                    rel="noopener noreferrer"
                    to="/mon-compte"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30"
                      height="30"
                      fill="currentColor"
                      className="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fillRule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                    &nbsp; {t("header.myaccount")}
                  </Link>
                </div>
              ) : (
                <div className="d-none d-lg-block d-xxl-block ">
                  <Link
                    className="btnaccount"
                    href="#"
                    rel="noopener noreferrer"
                    to="/login"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="25"
                      height="25"
                      fill="currentColor"
                      className="bi bi-person-fill"
                      viewBox="0 0 16 20"
                    >
                      <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3Zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z" />
                    </svg>
                    {t("header.login")}
                  </Link>
                </div>
              )}

              <select
                className="form-select d-flex d-none d-lg-block d-xxl-block "
                style={{ width: "70px" }}
                onChange={onChangeLang}
              >
                <option value="fr" className="lang">
                  FR
                </option>
                <option value="en" className="lang">
                  EN
                </option>
              </select>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
}
export default Header;
