import React, { useEffect, useState } from "react";
import Header from "../../../components/header/Header";
import Pagination from "react-paginate";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import "./AdresseList.css";
import { useTranslation } from "react-i18next";
import iconclose from "../../../images/plus.png";
import iconopen from "../../../images/fois.png";
import Footer from "../../../components/footer/Footer";
import { getAddresses, getLength } from "./AdresseListFunctions";
import imgGrp from "../../../images/imgGrp.png";

const AdresseList = (props) => {
  const { user, isAuthenticated } = props.auth;
  const [adresses, setAdresses] = useState([]);
  const [entrepriseType, setEntrepriseType] = useState("PrixAE");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 6; // Change this to the desired number of items per page

  // Extract the selected city from the hash fragment
  const location = useLocation();
  const hash = location.hash; // Get the hash fragment from the URL
  const selectedCity = hash ? hash.slice(1) : "Tous"; // Remove the "#" character

  const hashmap = {
    Tous: "all",
    Paris: "75",
    Essonne: "91",
    Hauts_de_Seine: "92",
    Seine_Saint_Denis: "93",
    Val_de_Marne: "94",
    Val_d_Oise: "95",
    Seine_et_Marne: "77",
    Yvelines: "78",
  };

  useEffect(() => {
    document.title = "Domicil Pro - Adresses";
    document.getElementById("adresses").classList.add("active");
    getItemsLength();
    getItems(currentPage);
  }, [currentPage, selectedCity, entrepriseType]);

  const handlePageChange = (selectedPage) => {
    const selected = selectedPage.selected + 1;
    setCurrentPage(selected);
    getItems(selected);
    window.scrollTo(0, 900);
  };

  const getItemsLength = async () => {
    try {
      const addressesLength = await getLength(
        hashmap[window.location.hash ? window.location.hash.slice(1) : "Tous"]
      );
      setTotalPages(Math.ceil(addressesLength / itemsPerPage));
    } catch (err) {
      console.error(err.message);
    }
  };

  const getItems = async (selected) => {
    let begin = (selected - 1) * itemsPerPage;
    let end = begin + itemsPerPage;
    await getAddresses(
      hashmap[window.location.hash ? window.location.hash.slice(1) : "Tous"],
      begin,
      end
    ).then((res) => {
      setAdresses(res);
    });
  };

  const changeEntrepriseType = (e) => {
    console.log(adresses);
    if (e.target.value === undefined) return;
    const enterpriseTypes = document.querySelectorAll(".enterprise-type");
    enterpriseTypes.forEach((enterpriseType) => {
      enterpriseType.classList.remove("active");
    });
    e.target.classList.add("active");
    setEntrepriseType(e.target.value);
  };

  const handleCityClick = (city) => {
    //add #city to the url
    window.location.hash = city;
    setCurrentPage(1); // Reset currentPage when filtering changes
    getItemsLength();
    getItems(1);
  };

  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState(-1);

  const handleToggle = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };
  console.log(adresses)
  return (
    <div>
      <Header isLoggedIn={isAuthenticated} user={user} />

      <div className=" body-container ">
        <div className="container ">
          <div className="row align-items-center">
            <div className=" col-lg-6 col-12 col-md-12  text-center text-lg-start">
              <h1 className="display-1 mt-5 title-1Home-h1">
                Trouver votre adresse en ligne
              </h1>
              <p className="my-4 text-1Homeadr">{t("home.simple")}</p>
            </div>
            <div className="col-lg-6 col-12 col-md-12 grp-img text-center mt-5">
              <img src={imgGrp} alt="imgGrp" className="imgGrp" />
            </div>
          </div>
        </div>
      </div>

      <div className="bggroupline"></div>

      <div className="container">
        <div className="row justify-content-center col-md-12 mt-5">
          <h2 className="text-center title-2Adresse  mb-5">
            {t("body.more than")}
          </h2>
        </div>
      </div>

      <section style={{ backgroundColor: "#E9F7F5" }}>
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5">
              <div className="nav nav-pills justify-content-center">
                <div className="row">
                  <div className="ville-row row justify-content-center">
                    <div className={`nav-item nav-ville`}>
                      <Link
                        className={`nav-link ville-filtre ${
                          selectedCity === "Tous" ? "active" : ""
                        }`}
                        to={`/adresses#Tous`}
                        onClick={() => handleCityClick("Tous")}
                      >
                        Tous
                      </Link>
                    </div>
                  </div>

                  <div className="ville-row row justify-content-center">
                    <div className={`nav-item nav-ville `}>
                      <Link
                        className={`nav-link ville-filtre ${
                          selectedCity === "Paris" ? "active" : ""
                        }`}
                        to={`/adresses#Paris`}
                        onClick={() => handleCityClick("Paris")}
                      >
                        Paris (75)
                      </Link>
                    </div>
                    <div className={`nav-item nav-ville `}>
                      <Link
                        className={`nav-link ville-filtre ${
                          selectedCity === "Seine_et_Marne" ? "active" : ""
                        }`}
                        to={`/adresses#Seine_et_Marne`}
                        onClick={() => handleCityClick("Seine_et_Marne")}
                      >
                        Seine-et-Marne (77)
                      </Link>
                    </div>
                    <div className={`nav-item nav-ville `}>
                      <Link
                        className={`nav-link ville-filtre ${
                          selectedCity === "Yvelines" ? "active" : ""
                        }`}
                        to={`/adresses#Yvelines`}
                        onClick={() => handleCityClick("Yvelines")}
                      >
                        Yvelines (78)
                      </Link>
                    </div>
                    <div className={`nav-item nav-ville`}>
                      <Link
                        className={`nav-link ville-filtre ${
                          selectedCity === "Essonne" ? "active" : ""
                        }`}
                        to={`/adresses#Essonne`}
                        onClick={() => handleCityClick("Essonne")}
                      >
                        Essonne (91)
                      </Link>
                    </div>
                  </div>

                  <div className="ville-row row justify-content-center">
                    <div className={`nav-item nav-ville `}>
                      <Link
                        className={`nav-link ville-filtre ${
                          selectedCity === "Hauts_de_Seine" ? "active" : ""
                        }`}
                        to={`/adresses#Hauts_de_Seine`}
                        onClick={() => handleCityClick("Hauts_de_Seine")}
                      >
                        Hauts-de-Seine (92)
                      </Link>
                    </div>
                    <div className={`nav-item nav-ville `}>
                      <Link
                        className={`nav-link ville-filtre ${
                          selectedCity === "Seine_Saint_Denis" ? "active" : ""
                        }`}
                        to={`/adresses#Seine_Saint_Denis`}
                        onClick={() => handleCityClick("Seine_Saint_Denis")}
                      >
                        Seine-Saint-Denis (93)
                      </Link>
                    </div>
                    <div className={`nav-item nav-ville `}>
                      <Link
                        className={`nav-link ville-filtre ${
                          selectedCity === "Val_de_Marne" ? "active" : ""
                        }`}
                        to={`/adresses#Val_de_Marne`}
                        onClick={() => handleCityClick("Val_de_Marne")}
                      >
                        Val-de-Marne (94)
                      </Link>
                    </div>
                    <div className={`nav-item nav-ville `}>
                      <Link
                        className={`nav-link ville-filtre ${
                          selectedCity === "Val_d'Oise" ? "active" : ""
                        }`}
                        to={`/adresses#Val-d'Oise`}
                        onClick={() => handleCityClick("Val-d'Oise")}
                      >
                        Val-d'Oise (95)
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row" style={{ width: "100%" }}>
            <div className="col-md-12 mt-2">
              <ul className="nav nav-pills justify-content-center">
                <li className={`nav-item nav-ville `}>
                  <button
                    className={`nav-link juridique-filtre ${
                      entrepriseType === "PrixAE" ? "active" : ""
                    }`}
                    value="PrixAE"
                    onClick={changeEntrepriseType}
                  >
                    Auto-entreprise
                  </button>
                </li>
                <li className={`nav-item nav-ville `}>
                  <button
                    className={`nav-link juridique-filtre ${
                      entrepriseType === "PrixEntreprise" ? "active" : ""
                    }`}
                    onClick={changeEntrepriseType}
                    value="PrixEntreprise"
                  >
                    Entreprise
                  </button>
                </li>
                <li className={`nav-item nav-ville `}>
                  <button
                    className={`nav-link juridique-filtre ${
                      entrepriseType === "PrixSCI" ? "active" : ""
                    }`}
                    onClick={changeEntrepriseType}
                    value="PrixSCI"
                  >
                    SCI
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <hr />
          <div className="row" style={{ width: "100%", padding: "10px" }}>
            {adresses ? (
              adresses.map((adresse) => (
                <div key={adresse._id} className="col-md-6 mt-1">
                  <div className=" card cardAdresses">
                    <div className="zoom-container">
                      <Link to={`/adresse/${adresse._id}`}>
                        <img
                          src={`${process.env.REACT_APP_API_URL}/api/adresseImages/${adresse.Images[0]._id}`}
                          style={{ height: "250px" }}
                          className="card-img-top"
                          alt={adresse.company}
                        />
                      </Link>
                    </div>
                    <div className="card-body">
                      <div className="d-flex justify-content-between align-items-center">
                        <div>
                          <h5 className="card-prix">
                            {adresse[entrepriseType].Prix}€ Par Mois
                          </h5>
                        </div>
                        <div>
                          <Link
                            to={`/adresse/${adresse._id}`}
                            className="btn-savoirAdressesText btn-savoirAdresses"
                          >
                            {t("body.learn more")}{" "}
                          </Link>
                        </div>
                      </div>

                      <br />

                      <h3 className="card-text title-3Adresse">
                        {t("body.city")} {adresse.Location.City}
                      </h3>

                      <h3 className="card-title title-3Adresse">
                        {adresse.company}
                      </h3>
                      <h3 className="card-text Adresse-Complet">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="26"
                          viewBox="0 0 19 26"
                          fill="none"
                        >
                          <path
                            d="M9.5 0C4.53144 0 0.5 3.74388 0.5 8.35714C0.5 15.7857 9.5 26 9.5 26C9.5 26 18.5 15.7857 18.5 8.35714C18.5 3.74388 14.4686 0 9.5 0ZM9.5 13C8.78799 13 8.09196 12.7822 7.49995 12.374C6.90793 11.9659 6.44651 11.3858 6.17403 10.7071C5.90156 10.0284 5.83027 9.28159 5.96917 8.56109C6.10808 7.84059 6.45095 7.17877 6.95442 6.65932C7.45788 6.13986 8.09934 5.78611 8.79768 5.6428C9.49601 5.49948 10.2198 5.57304 10.8777 5.85416C11.5355 6.13529 12.0977 6.61136 12.4933 7.22217C12.8889 7.83298 13.1 8.5511 13.1 9.28571C13.099 10.2705 12.7193 11.2146 12.0444 11.9109C11.3695 12.6073 10.4545 12.9989 9.5 13Z"
                            fill="#00A4B8"
                          />
                        </svg>{" "}
                        {adresse.Location.Label}
                      </h3>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No addresses found.</p>
            )}
          </div>
        </div>

        <div className="adresse-btnwithcircle">
          <div className="d-flex justify-content-center pagination-container">
            {/*<div className="line-area">
              <div className="line" />
              </div>*/}
            <Pagination
              pageCount={totalPages}
              onPageChange={handlePageChange}
              forcePage={currentPage - 1}
              activeClassName="active-page"
              pageClassName="pageNumbers"
              pageLinkClassName="page-link"
              previousClassName="previous-page"
              nextClassName="next-page"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              previousLabel=""
              nextLabel=""
              style={{
                width: "100px",
              }}
            />
          </div>

          <Link to={"/contact"} style={{ textDecoration: "none " }}>
            <button className="adresse-btn-btnwithcircle">
              Contactez-nous{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="16"
                viewBox="0 0 18 16"
                fill="none"
              >
                <path
                  d="M17.5 8C17.5 3.58192 13.6942 0 9 0C4.30579 0 0.5 3.58192 0.5 8C0.5 12.4181 4.30579 16 9 16C13.6942 16 17.5 12.4181 17.5 8ZM8.80998 11.5138C8.749 11.4569 8.70054 11.3893 8.66736 11.3147C8.63417 11.2402 8.61692 11.1602 8.61658 11.0794C8.61623 10.9985 8.63281 10.9184 8.66537 10.8437C8.69792 10.7689 8.74581 10.7008 8.8063 10.6435L10.9444 8.61539H5.48558C5.31217 8.61539 5.14586 8.55055 5.02324 8.43514C4.90062 8.31974 4.83173 8.16321 4.83173 8C4.83173 7.83679 4.90062 7.68026 5.02324 7.56486C5.14586 7.44945 5.31217 7.38462 5.48558 7.38462H10.9444L8.8063 5.35654C8.74582 5.29911 8.69795 5.23103 8.66542 5.15619C8.63289 5.08135 8.61635 5.00122 8.61673 4.92036C8.61711 4.8395 8.6344 4.7595 8.66763 4.68494C8.70086 4.61037 8.74937 4.54269 8.81038 4.48577C8.8714 4.42885 8.94374 4.38379 9.02325 4.35318C9.10277 4.32257 9.18791 4.30699 9.27383 4.30735C9.44733 4.30807 9.61343 4.37363 9.73558 4.48962L12.9799 7.56654C13.1014 7.68181 13.1696 7.83761 13.1696 8C13.1696 8.16239 13.1014 8.31819 12.9799 8.43346L9.73558 11.5104C9.67509 11.5679 9.60314 11.6136 9.52386 11.6449C9.44458 11.6762 9.35951 11.6925 9.27354 11.6928C9.18756 11.6931 9.10237 11.6775 9.02282 11.6468C8.94328 11.6161 8.87095 11.5709 8.80998 11.5138Z"
                  fill="white"
                />
              </svg>
            </button>
          </Link>
        </div>
      </section>

      <div className="container">
        <div className="overbgimg "></div>
        <div className="faq-wrapper text-center ">
          <h3 className="faq-heading pt-5">{t("FAQ.faq title")}</h3>
          <div className="faq-list">
            {t("FAQ.questions", { returnObjects: true }).map(
              (question, index) => (
                <div key={{ index }} className="faq-item">
                  <div
                    className="faq-question d-flex flex-row justify-content-between"
                    onClick={() => handleToggle(index)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === " ") {
                        handleToggle(index);
                      }
                    }}
                    tabIndex={0}
                  >
                    <span>{question}</span>
                    {expandedIndex === index ? (
                      <img
                        src={iconopen}
                        alt={t("FAQ.icon open")}
                        className="faq-icon rounded-circle bg-warning"
                      />
                    ) : (
                      <img
                        src={iconclose}
                        alt={t("FAQ.icon close")}
                        className="faq-icon rounded-circle bg-warning"
                      />
                    )}
                  </div>
                  {expandedIndex === index && (
                    <div className="faq-answer">
                      <p>{t("FAQ.answers", { returnObjects: true })[index]}</p>
                    </div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

AdresseList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdresseList);
