import React, { useState, useEffect, useRef } from "react";

import "./Counter.css";
import PropTypes from "prop-types";

const Counter = ({ startValue, endValue, duration }) => {
  const [count, setCount] = useState(startValue);
  const incrementRef = useRef(null);

  useEffect(() => {
    document.getElementById("home").classList.add("active");
    let start = startValue;
    const increment = Math.ceil((endValue - startValue) / 100);
    const incrementTime = Math.floor(
      duration / ((endValue - startValue) / increment)
    );

    const timer = setInterval(() => {
      start += increment;
      setCount((prevCount) => {
        if (start >= endValue) {
          clearInterval(timer);
          return endValue;
        }
        return prevCount + increment;
      });
    }, incrementTime);

    incrementRef.current = timer;
    return () => clearInterval(incrementRef.current);
  }, [startValue, endValue, duration]);

  return <span>{count}</span>;
};

Counter.propTypes = {
  startValue: PropTypes.number.isRequired,
  endValue: PropTypes.number.isRequired,
  duration: PropTypes.number.isRequired,
};

export default Counter;
