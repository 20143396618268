import React, { useEffect } from "react";
import Header from "../../../../../components/header/Header";
import { Link } from "react-router-dom";
import imgmesannonces from "../../../../../images/imgmesannonces.png"; 
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../../actions/authActions";
import SideBar from "../../../../../components/SideBars/VendeurSideBar/SideBar";
import "./EspaceVendeur.css";
import "../../../Espace.css";


const EspaceVendeur = (props) => {
  const { user, isAuthenticated , isVendeur } = props.auth;

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };

  useEffect(() => {
    console.log(isVendeur);
    window.scrollTo(0, 0);
  });

  return (
    <div className="espace-container">
      <Header isLoggedIn={isAuthenticated} user={user} onLogoutClick={onLogoutClick}/>
        <div className="espace-vendeur">
          <SideBar className="side-bar"/>
          <div className="home" >
          <img src={imgmesannonces} alt="ph" width="400" height="300" />
            {isVendeur ? 
            <h2 >Vous n’avez pas d’annonces déposées sur DomicilPro (pour l’instant !)</h2> : 
            <h2 >Votre compte n'est pas encore activée </h2>
            }
            
            {isVendeur ?
            <p >Service 100% gratuit, photos illimitées, des conseils à toutes les étapes de votre projet. Vous pourrez gérer vos annonces depuis cet espace.</p>
            : <p >veuillez attendre la réponse de l'administrateur.</p>
            }

            {isVendeur ? <Link to="/espace-vendeur/ajouter-une-adresse" className="buttonSubmitEspaceAcheteurHomeText">
            <button  className="buttonSubmitEspaceAcheteurHome">       
                 Ajouter une adresse
            </button>
          </Link> : <p></p>}
            
          </div>
      </div>
    </div>
  );
};

EspaceVendeur.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps, { logoutUser })(EspaceVendeur);
