import React from "react";
import Header from "../../../../components/header/Header";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
import SideBar from "../../../../components/SideBars/AcheteurSideBar/SideBar";
import "./MonProfile.css";
import "../../Espace.css";

const MonProfileAcheteur = (props) => {
  const { user, isAuthenticated } = props.auth;

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };
  return (
    <div className="espace-container">
      <Header
        isLoggedIn={isAuthenticated}
        user={user}
        onLogoutClick={onLogoutClick}
      />
      <div className="espace-vendeur">
        <SideBar className="side-bar" />
        <div className="home">
          <h1>Mon Profile</h1>
          <h2>Informations personnelles</h2>
          <p>Nom : {user.nom}</p>
          <p>Prénom : {user.prenom}</p>
          <p>Email : {user.Email}</p>
          <p>Téléphone : {user.telephone}</p>
          <p>Adresse : {user.adresse}</p>
          <p>Code postal : {user.codePostal}</p>
        </div>
      </div>
    </div>
  );
};

MonProfileAcheteur.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(MonProfileAcheteur);
