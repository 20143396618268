import React, { useState } from 'react';
import { BsPinAngleFill } from 'react-icons/bs';
import { PiDotsThreeBold } from 'react-icons/pi';
import './Annonce.css';

const Annonce = (props) => {
  const { titre, date, onEdit, onDelete,data } = props;
  const [annonceHovered, setAnnonceHovered] = useState(false);

  return (
    
    <div 
      className={`annonce bd-content ps-lg-2 widthAnnonce ${annonceHovered ? 'hovered' : ''}`}
      onMouseEnter={() => setAnnonceHovered(true)}
      onMouseLeave={() => setAnnonceHovered(false)}
    >
      <div className="annonce-details">
        <h3 className="annonce-titre">{titre}</h3>
        <p className="annonce-date">{date}</p>
      </div>
      <div row>
        <div className="annonce-actions">
          <button onClick={onEdit} className="annonce-edit"
                      style={{ color: data.isPinned ? '#00A4B8' : 'inherit' }}
                      >
            <BsPinAngleFill />
          </button>
          <button onClick={onDelete} className="annonce-delete"
          >
            <PiDotsThreeBold />
          </button>
          
        </div>
       
      </div>
      
      
    </div>
    
    
  );
};

export default Annonce;
