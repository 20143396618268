// ListeAnnonces.jsx

import React, { useState,useEffect } from 'react';
import Annonce from './Annonce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/authActions';
import { fetchData } from './AdressesListFunctions';
import { useQuery } from 'react-query';
import { toggleIsPinned } from '../../actions/adresseActions';


const ListeAnnonces = (props) => {
  const { user, isAuthenticated } = props.auth;
  const queryKey = ['adresses', user.id];
  const { data, isLoading, error } = useQuery(queryKey, () => fetchData(props));
  const [pinnedAddresses, setPinnedAddresses] = useState({});

  // Fonction pour trier les données en fonction de isPinned puis par date
  const sortData = (data) => {
    return [...data].sort((a, b) => {
      if (a.isPinned && !b.isPinned) {
        return -1; // a comes first
      }
      if (!a.isPinned && b.isPinned) {
        return 1; // b comes first
      }
      // If neither or both are pinned, sort by date
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA; // newest first
    });
  };

  // Initialisez les annonces avec la propriété isPinned
  const annoncesData = data
    ? data.map((item, index) => ({
        id: index,
        titre: item.Location.Label,
        date: item.createdAt,
        data: item,
        isPinned: false, // Assurez-vous de définir la propriété isPinned
      }))
    : [];

  // Triez les annonces en fonction de isPinned puis par date
  const annoncesValide = annoncesData
    .filter((annonce) => annonce.data.Verifie === 'Validé')
    .sort((a, b) => {
      const isPinnedA = pinnedAddresses[a.data._id];
      const isPinnedB = pinnedAddresses[b.data._id];
    
      // Si l'annonce A est épinglée et l'annonce B ne l'est pas, A vient en premier
      if (isPinnedA && !isPinnedB) {
        return -1;
      } 
      // Si l'annonce B est épinglée et l'annonce A ne l'est pas, B vient en premier
      else if (!isPinnedA && isPinnedB) {
        return 1;
      } 
      // Si les deux annonces sont épinglées ou aucune ne l'est, triez par date
      else {
        return new Date(b.date) - new Date(a.date);
      }
    });
    
    
    
    // Mettre à jour l'état isPinned lorsque les données sont chargées
    useEffect(() => {
      if (data) {
        const pinnedAds = {};
        data.forEach((item) => {
          pinnedAds[item._id] = item.isPinned; // Suppose que les données de l'API incluent un champ isPinned
        });
        setPinnedAddresses(pinnedAds);
      }
    }, [data]);
    
    const handleEdit = (id) => {
      // Fonction d'édition ici, vous pouvez utiliser `id` pour identifier l'annonce à éditer
      props.onEditClick(id);
    };
    
    const handlePin = async (adresseId) => {
      try {
        await props.toggleIsPinned(adresseId, !pinnedAddresses[adresseId]);
        setPinnedAddresses((prevAddresses) => ({
          ...prevAddresses,
          [adresseId]: !prevAddresses[adresseId],
        }));
      } catch (error) {
        console.error(error);
      }
    };
    const [showAllAnnonces, setShowAllAnnonces] = useState(false);
  const handleVoirToutesAnnonces = () => {
    setShowAllAnnonces(true);
  };

  if (error) {
    return <div>Error fetching data from API: {error.message}</div>;
  }

  return (
    <>
      <div className="mesanonnance">
      <div className="r1 row">
          <div className="col-sm-6">
            <h2 className="MesAnnoncesText">Mes Annonces</h2>
          </div>
          <div className=" col-6 text-right">
            <label className="date ">
              <select name="date">
                <option value="13 sep 2023">13 sep 2023</option>
                <option value="14 sep 2023">14 sep 2023</option>
                <option value="15 sep 2023">15 sep 2023</option>
              </select>
            </label>
          </div>
        </div>
        {annoncesValide.length > 0 ? (
          <div className="row">
            {showAllAnnonces
              ? annoncesValide.map((annonce) => (
                  <Annonce
                    key={annonce.id}
                    titre={annonce.titre}
                    date={annonce.date}
                    onEdit={() => handlePin(annonce.data._id)}

                    data={annonce.data}
                    // ... autres props
                  />
                ))
              : annoncesValide.slice(0, 3).map((annonce) => (
                  <Annonce
                    key={annonce.id}
                    titre={annonce.titre}
                    date={annonce.date}
                    onEdit={() => handlePin(annonce.data._id)}
                    data={annonce.data}
                    // ... autres props
                  />
                ))}
          </div>
        ) : (
          // Afficher le message d'avertissement s'il n'y a pas de données
          <div className="affichee">
            ⚠️ Attention ! Aucune adresse n'a encore été validée. Ne manquez pas cette opportunité, procurez-vous la vôtre dès maintenant.
          </div>
        )}
        <div>
          {annoncesValide.length > 3 && !showAllAnnonces && (
            <div className="row VoirTout">
              <hr className="separator"></hr>
              <div className="aff" onClick={handleVoirToutesAnnonces}>
                Voir toutes les annonces
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};


ListeAnnonces.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  toggleIsPinned: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { toggleIsPinned })(ListeAnnonces);