import React, { useState } from "react";
import axios from "axios";

import "./GestionCompte.css";
function EmailValidation({ userid }) {
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [alertMessage, setAlertMessage] = useState(null);

  const handleEmailChange = (event) => {
    const newEmail = event.target.value;
    setEmail(newEmail);

    // Regular expression to validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValid(emailPattern.test(newEmail));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Check if the email is valid
    if (!isValid) {
      setAlertMessage("Please enter a valid email address");
      return;
    }

    try {
      const response = await axios.put(
        process.env.REACT_APP_API_URL + "/api/users/updatemail/" + userid,
        { Email: email },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      // Check the response status code to confirm the update was successful.
      if (response.status === 200) {
        setAlertMessage("Email updated successfully");
        setEmail("");
      } else {
        setAlertMessage("Failed to update email");
      }
    } catch (error) {
      // Handle any errors that occur during the Axios request.
      console.error("Error updating email:", error);
      setAlertMessage("An error occurred while updating the email");
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <h6 className="pt-3">Votre e-mail</h6>

        <div className="mb-3 col-lg-6 col-md-6">
          <input
            id="email"
            type="email"
            className="input-box"
            value={email}
            onChange={handleEmailChange}
          />
          {alertMessage && (
            <div
              style={{ marginTop: "10px" }}
              className="alert alert-primary"
              role="alert"
            >
              {alertMessage}
            </div>
          )}
          <button
            type="submit"
            className="btn-submit  col-lg-6 col-md-6 w-100 "
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

export default EmailValidation;
