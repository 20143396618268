import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginUser } from "../../../actions/authActions";
import Header from "../../../components/header/Header";
import LoginForm from "../../../components/auth/LoginForm";
import "../Auth.css";
import Footer from "../../../components/footer/Footer";
import "./Login.css";

class Login extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      password: "",
      errors: null,
      onnisCected: true,
      redirect: false,
    };
  }
  //code ajouté pour changer le bouton se connecter
  //state = {
  // onnisCected: true // Définissez la valeur en fonction de l'état de connexion de l'utilisateur
  // };
  //fin code ajouté pour changer le bouton se connecter

  componentDidMount() {
    // If logged in and user navigates to Login page, should redirect them to dashboard
    if (this.props.auth.isAuthenticated) {
      this.setState({ redirect: true }); // Use this.setState to update the state
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newState = {};

    if (nextProps.auth.isAuthenticated) {
      newState.redirect = true;
    }

    if (nextProps.errors && Object.keys(nextProps.errors).length > 0) {
      newState.errors = nextProps.errors;
      console.log(nextProps.errors);
    }

    // Return the new state object
    return Object.keys(newState).length > 0 ? newState : null;
  }

  onChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
    this.setState({ errors: null });
  };

  onSubmit = (e) => {
    e.preventDefault();

    let validationError = "";
    const validations = [
      {
        field: "email",
        validationRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Email ou Mot de passe incorrecte",
      },
      {
        field: "password",
        minLength: 8,
        message: "Email ou Mot de passe incorrecte",
      },
    ];
    for (const validation of validations) {
      if (validation.field === "email") {
        if (!validation.validationRegex.test(this.state[validation.field])) {
          validationError = validation.message;
          break;
        }
      } else {
        if (this.state[validation.field].length < validation.minLength) {
          validationError = validation.message;
          break;
        }
      }
    }
    if (
      this.state.password.match(/\d/g) === null ||
      this.state.password.match(/[a-zA-Z]/g) === null
    ) {
      validationError = "Email ou Mot de passe incorrecte";
    }
    if (validationError) {
      this.setState({
        errors: { error: validationError },
      });
      return;
    }

    const userData = {
      email: this.state.email,
      password: this.state.password,
    };

    this.props.loginUser(userData);
  };

  render() {
    if (this.state.redirect) {
      window.location.href = "/mon-compte";
    }
    return (
      <div>
        <div className="az">
        <Header />
        <div className="bodyAuth ">
          <div className="bg1"></div>
          <div className="bg3">
          <div className='bg'> 
            <div className="bg2">
            <div className="register3">
          <LoginForm className='register1'
            onSubmit={this.onSubmit}
            errors={this.state.errors}
            onChange={this.onChange}
            email={this.state.email}
            password={this.state.password}
          />
        </div>
        </div>
        </div>
        </div>
        </div>
        </div>
        <Footer />
      </div>
    );
  }
}

Login.propTypes = {
  loginUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps, { loginUser })(Login);
