import React, { Component,useState,useEffect } from 'react'
import { connect } from "react-redux";
import { changeField } from "../../utils/changeFieldPageVendeur";
import { useNavigate } from 'react-router-dom';
import { refreshtochangestatus } from "../../actions/authActions";
import axios from "axios";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { usePaymentInputs } from 'react-payment-inputs';
import MyPayPalComponent from '../PayPalButton/MyPayPalComponent';

import Pay from "../PayPalButton/Pay"

const apiUrl = process.env.REACT_APP_API_URL;
const clientId = process.env.CLIENT_ID;
const PaymementVendeur = (props) => {
  const { user } = props.auth;
  
  
    const [total, setTotal] = useState(16);
    
  const handleAbonnementChange = (event) => {
    let price;
    switch(event.target.value) {
      case 'Mensuel':
        price = 16; // Mettez le prix mensuel ici
        break;
      case 'Annuel':
        price = 15; // Mettez le prix annuel ici
        break;
      case 'Bisannuel':
        price = 13; // Mettez le prix bisannuel ici
        break;
      default:
        price = 0;
    }
    setTotal(price);
    
  }
  useEffect(() => {
    console.log('Total changed:', total);
}, [total]);
  
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  

  const handleNextClick = () => {
    navigate('/espace-vendeur/Validation');

  };
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvc, setCvc] = useState("");
  const {
    meta,
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps
  } = usePaymentInputs();

  const handleChangeCardNumber = event => {
    setCardNumber(event.target.value);
  };

  const handleChangeExpiryDate = event => {
    setExpiryDate(event.target.value);
  };

  const handleChangeCVC = event => {
    setCvc(event.target.value);
  };
  const handlePayment = async () => {
    
console.log('Request Payload:', { cardNumber, expiryDate, cvc });

    try {
      // Remplacez 'VOTRE_CLE_API' par votre clé API Qonto
      const response = await axios.post(apiUrl + "/api/payment", {
        cardNumber,
        expiryDate,
        cvc,
        // Ajoutez d'autres détails de paiement si nécessaire
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log('Réponse Qonto:', response.data);
      // Traitez la réponse de Qonto ici
    } catch (error) {
      console.error('Erreur Qonto:', error);
      if (error.response) {
        console.error('Réponse HTTP:', error.response.status);
        console.error('Détails de l\'erreur:', error.response.data);
      }
      setError('Une erreur est survenue lors du traitement du paiement.');
    }
  };
 


  
 
  const initialOptions = {
    
"client-id":"AZ70iaGXJjEZsvq356wTnVdfxp0oDmOwpjT4Kk6zdiYi7oB0rGpCd-uYMaWNV0MkhYuXQmGAMp4zKboR",
        currency: "USD",
    intent: "capture",
};






    return (
        <>
        <div className='row headPaiment'>
            <div className="col-xl-7 col-xxl-7 col-lg-7">
                <div className='backgroundPaiment1'>
                 <div className='row'>
                  <div className='TextPaiement col-10'> Ma commande </div>
                  <div className='iconPaiement col-2'></div>
                 </div>
            <div className='backgroundPaiment2'>
              <div className='TextPaiment2'>Choisissez votre Abonnement</div>
              <div className='formPaiment' >
               <input type="radio" id="Mensuel" name="abonnement" value="Mensuel" onChange={handleAbonnementChange} defaultChecked/>
               <label className='textLabel' htmlFor="Mensuel">Mensuel </label><br/>
               <div className='sousTextPaiment'>Sans engagement</div>
               <input type="radio" id="Annuel" name="abonnement" value="Annuel" onChange={handleAbonnementChange}/>
               <label className='textLabel' htmlFor="Annuel"> Annuel </label><br />
               <div className='sousTextPaiment'>1 mois offert</div>
               <input  type="radio" id="Bisannuel" name="abonnement" value="Bisannuel" onChange={handleAbonnementChange}/>
               <label className='textLabel' htmlFor="Bisannuel">Bisannuel </label>
              <div className='sousTextPaiment'>3 mois offerts</div>
            </div>   
            <div className='TextPaiment2'>TOTAL HT: {total}€</div>
            
           </div>
         
        

                        
                </div>
            </div>
            <div className='col-xl-5 col-xxl-5 col-lg-5 Payment'>
            

         <div className='PayBtn' >
          <PayPalScriptProvider options={initialOptions}>
            
           
        <Pay total={total} userEmail={user.Email} userId={user.id} Name={user.Profiles.Nom} Prenom={user.Profiles.Prenom} />
        
        
        </PayPalScriptProvider>
      </div>

      
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <hr style={{ flex: 1 }} />
          <span className='OuPayerParCarte' >Ou payer par carte</span>
          <hr style={{ flex: 1 }} />
        </div>
        <div>
          <div className='TextPaiment3'>Nom propriétaire de la carte bancaire</div>
        </div>
        <div > 
                <input
                
                  type="Text"
                  label="Nom"
                  id="Nom"
                  name="Nom"
                  
                  className="CreateAnnonce-input payment-input   "
                  
                  />
                  
                  </div>
                  <div>
      <div className='TextPaiment3'>Carte bancaire</div>

      <input className="CreateAnnonce-input payment-input"  {...getCardNumberProps({ onChange: handleChangeCardNumber })} value={cardNumber}  placeholder='1234 1234 1234 1234' />
      <div className='Cartes'></div>

      <div className='row'>
        <div className='col-6'>
          <input className="CreateAnnonce-input payment-input"  {...getExpiryDateProps({ onChange: handleChangeExpiryDate })} value={expiryDate}  />
        </div>
        <div className='col-6'>
          <input className="CreateAnnonce-input payment-input" {...getCVCProps({ onChange: handleChangeCVC })} value={cvc}  />
          <div className='CarteCvc'></div>
        </div>
      </div>

      {error && <span>Error: {error}</span>}

      <button type="button" onClick={handlePayment} className="btn button PaymentBtn ">
        Payer
      </button>
    </div>
      </div>

         


        </div>
       
        </>
    )
}
const mapStateToProps = (state) => ({
    auth: state.auth,
  });

  export default connect(mapStateToProps)(PaymementVendeur);
