import React from "react";
import axios from "axios";
import "./pop-up.css";

const Popup = (props) => {
  const updateProfile = async (status) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/profiles/${props.profile._id}/${status}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      if (response.ok) {
        // The demand was successfully updated
        console.log(`Profile ${props.profile._id} updated to ${status}`);
        // Perform any necessary actions after the update
      }
    } catch (error) {
      console.error(error);
      // Handle the error or show an error message to the user
    }
  };
  return (
    <div
      className="modal"
      id="exampleModal"
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Details de Profile</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose} // Optional: Add an event handler to close the pop-up
            ></button>
          </div>
          <div className="modal-body">
            <label
              className="custom-file-upload"
              style={{ width: "150px", height: "160px" }}
            >
              <img
                src={
                  props.profile === null
                    ? ""
                    : `http://localhost:5000/api/profiles/avatar/${props.profile._id}`
                }
                className="rounded-circle"
                style={{ width: "130px", height: "130px" }}
                alt="Avatar"
                id="avatar"
              />
              Custom Upload
            </label>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Email:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.profile === null ? "" : props.profile.User.Email}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Nom:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.profile === null ? "" : props.profile.Nom}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Prenom:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.profile === null ? "" : props.profile.Prenom}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Numero:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.profile === null ? "" : props.profile.Numero}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Carte de Séjour:
              </label>
              <a
                className="form-control"
                id="message-text"
                rel="noreferrer"
                target="_blank"
                href={
                  props.profile === null
                    ? ""
                    : `http://localhost:5000/api/profiles/idcard/${props.profile._id}`
                }
              >
                z
              </a>
            </div>
            {/* Add the content of the demand details here */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={props.onClose} // Optional: Add an event handler to close the pop-up
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => updateProfile("V")}
            >
              Accepter
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => updateProfile("R")}
            >
              Refuser
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
