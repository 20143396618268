import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AcheteurProfileRoute = ({ children ,auth }) => {
  if (auth.isAuthenticated === false) {
    return <Navigate to="/login" />;
  }
  return auth.hasAcheteurProfile === true  ? children : <Navigate to="/profile/acheteur" />;
}

AcheteurProfileRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AcheteurProfileRoute);