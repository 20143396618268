import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { logoutUser } from "../../../actions/authActions";
import { connect } from "react-redux";
import Header from "../../../components/header/Header";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFolder, faKey, faUser } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import "./MonCompte.css";
import { useTranslation } from "react-i18next";
import Footer from "../../../components/footer/Footer";
import Sidebar1 from "../../../components/sidebarDash/Sidebar";
import NavBarDash from "../../../components/navbarDash/navBarDash";
 

const MonCompte = (props) => {
  const {
    user,
    isAuthenticated,
    isAdmin,
    hasVendeurProfile,
    hasAcheteurProfile,
  } = props.auth;

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Domicil Pro - Mon Compte";
  }, []);

  return (
    <>
    <div className="">
      
      
      
      
      <div className="row m-0 p-0">
        <div className="col-1 col-lg-1 col-xl-2 col-md-1 col-sm-1 sidebar-compte">
        <Sidebar1/>
        </div>
        
        <div className="col-11 col-md-11 col-lg-11 col-xl-10 col-sm-11">
          <div className="row">
        <NavBarDash/>
        </div>
        <div className="row">
        <div  className="">
        <div className="bodyDash1 ">
          <div className="verif"></div>
          <div className="verifText">Bienvenue dans votre espace client dédié !</div>
          </div>
          </div>
        </div>
        </div>
      </div>
      <div className="row">
        <div className="col-10">
      <div className="background">
      </div>
      
      </div>
      </div>

      
      </div>
      <Footer/>
      </>)}
      
MonCompte.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
  };
  
  const mapStateToProps = (state) => ({
    auth: state.auth,
  });
  
  export default connect(mapStateToProps, { logoutUser })(MonCompte);
  
   /* <div className="dash-container ">
      <div className="overbgimg "></div>
      <div style={{ height: "100%" }}>
        <Header
          isLoggedIn={isAuthenticated}
          user={user}
          onLogoutClick={onLogoutClick}
        />

        <div className="container container-cards mt-5">
          <div className="row align-items-center  ">
            <div className=" center-align mt-5">
              <h1>{t("dashboard.my account title")}</h1>
              <p className="dash-email">{user.Email}</p>
            </div>

            <div className="col-12 col-md-6 col-lg-4  ">
              <Link
                to={hasVendeurProfile ? "/espace-vendeur" : "/profile/vendeur"}
                className="card link-card "
              >
                <div className="card-content dark-text d-flex  ">
                  <div className="d-flex align-items-start mt-1 ">
                    <FontAwesomeIcon icon={faKey} className="text-warning" />
                  </div>
                  <div className="d-flex flex-column  justify-content-evenly  text-card  ">
                    <h5 className="mb-0  ">{t("dashboard.seller space")}</h5>
                    <p className="mb-0  ">{t("dashboard.anounces")}</p>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
              <Link
                to={
                  hasAcheteurProfile ? "/espace-acheteur" : "/profile/acheteur"
                }
                className="card link-card"
              >
                <div className="card-content dark-text d-flex">
                  <div className="d-flex align-items-start mt-1">
                    <FontAwesomeIcon icon={faFolder} className="text-warning" />
                  </div>
                  <div className="d-flex flex-column justify-content-center text-card">
                    <h5 className="mb-0">{t("dashboard.buyer space")}</h5>
                    <p className="mb-0">{t("dashboard.buyer space texte")}</p>
                  </div>
                </div>
              </Link>
            </div>

            {isAdmin && (
              <div className="col-12 col-md-6 col-lg-4 ">
                <Link
                  to="/espace-admin/liste-de-users"
                  className="card link-card"
                >
                  <div className="card-content dark-text d-flex">
                    <div className="d-flex align-items-start mt-1">
                      <FontAwesomeIcon icon={faUser} className="text-primary" />
                    </div>
                    <div className="d-flex flex-column justify-content-center text-card">
                      <h5 className="mb-0">{t("dashboard.admin space")}</h5>
                      <p className="mb-0">{t("dashboard.Manage the site")}</p>
                    </div>
                  </div>
                </Link>
              </div>
            )}

            <div className="col-12 col-md-6 col-lg-4 s12 m6">
              <Link to="/mon-compte/gestion" className="card link-card">
                <div className="card-content dark-text d-flex">
                  <div className="d-flex align-items-start mt-1">
                    <FontAwesomeIcon icon={faUser} className="text-primary" />
                  </div>
                  <div className="d-flex flex-column justify-content-center text-card">
                    <h5 className="mb-0">{t("dashboard.manage my account")}</h5>
                    <p className="mb-0">
                      {t("dashboard.manage my account texte")}
                    </p>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
        <div className="container center-align mt-5">
          <button className="logout-btn" onClick={onLogoutClick}>
            &nbsp; {t("header.logout")}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="20"
              fill="#ffffff"
              className="bi bi-box-arrow-in-right"
              viewBox="0 0 16 20"
            >
              <path
                fillRule="evenodd"
                d="M6 3.5a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-2a.5.5 0 0 0-1 0v2A1.5 1.5 0 0 0 6.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2h-8A1.5 1.5 0 0 0 5 3.5v2a.5.5 0 0 0 1 0v-2z"
              />
              <path
                fillRule="evenodd"
                d="M11.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H1.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
              />
            </svg>
          </button>
        </div>
      </div>
      <Footer />
    </div>*/

