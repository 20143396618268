import axios from "axios";

export const getLength = async (postcode) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/adresses/length/${postcode}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err.message);
  }
};

export const getAddresses = async (postcode, begin, end) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/adresses/list/${postcode}/${begin}/${end}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response.data;
  } catch (err) {
    console.error(err.message);
  }
};
