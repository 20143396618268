import Card from "./Card";
import "./Section1.css";

const Section1 = (props) => {
  return (
    <section className="latest-blog">
      <div className="tarifs container">
        <div
          className="row justify-content-center"
          style={{ maxWidth: "100%" }}
        >
          <Card Duree="Mensuel" detail="Sans engagement" prix="16" />
          <Card Duree="Annuel" detail="1 mois offert" prix="15" />
          <Card Duree="Bisannuel" detail="3 mois offerts" prix="13" />
          <div className="notetarifs">
            *Tarif de domiciliation inclus dans nos offres, sans frais
            supplémentaires
          </div>
        </div>
      </div>
    </section>
  );
};
export default Section1;
