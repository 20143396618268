import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Header from "../../../../../components/header/Header";
import { useQuery } from "react-query";
import AdminSideBar from "../../../../../components/EspaceAdmin/AdminSideBar";
import { fetchData, deleteBlog } from "./BlogListFunctions";
import DateTimeConvertion from "../../../../../utils/DateTimeConvertion";
import { Link } from "react-router-dom";

const BlogList = (props) => {
  const { user, isAuthenticated } = props.auth;
  // Create a unique query key based on the user's ID
  const queryKey = ["blogs", user.id];

  const { data, isLoading, error } = useQuery(queryKey, fetchData); // Use the useQuery hook to fetch data
  const [currentPage, setCurrentPage] = useState(1); // Added state for current page
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleDelete = (id) => {
    //confirmer la suppression
    let conf = window.confirm("Voulez-vous vraiment supprimer ce blog ?" + id);

    if (conf === true) {
      deleteBlog(id);
      data.filter((item) => item._id !== id);
    }
  };

  const sortData = (data, column, order) => {
    return [...data].sort((a, b) => {
      /*if (column === "Verifie") {
          if (order === "asc") {
            return a[column].localeCompare(b[column]);
          } else {
            return b[column].localeCompare(a[column]);
          }
        } else*/ if (column === "createdAt" || column === "updatedAt") {
        const dateA = new Date(a[column]);
        const dateB = new Date(b[column]);
        if (order === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      }
      return 0;
    });
  };

  const [sortColumn, setSortColumn] = useState("Verifie");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortClick = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  if (isLoading) {
    return (
      <div>
        <Header isLoggedIn={isAuthenticated} user={user} />
        Loading...
      </div>
    ); // Display a loading message while data is being fetched
  }

  if (error) {
    return <div>Error fetching data from API: {error.message}</div>; // Display an error message if there's an error
  }

  // Calculate total number of pages based on data length and rows per page
  const totalPages = Math.ceil(data.length / rowsPerPage);

  // This function handles the change in page
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Calculate the index range for current page
  const sortedData = sortData(data, sortColumn, sortOrder);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, sortedData.length);
  const visibleData = sortedData.slice(startIndex, endIndex);

  return (
    <div>
      <Header isLoggedIn={isAuthenticated} user={user} />
      <button
        className="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"
      >
        Admin Dashboard
      </button>
      <AdminSideBar />
      <div style={{ padding: "20px" }}>
        <h1>Liste de Messages</h1>

        <Link className="btn btn-primary" to={`/espace-admin/ajouter-un-blog`}>
          Ajouter un blog
        </Link>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Title</th>
              <th scope="col" onClick={() => handleSortClick("createdAt")}>
                Date Ajout
                {sortColumn === "createdAt" && (
                  <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                )}
              </th>
              <th scope="col" onClick={() => handleSortClick("updatedAt")}>
                Date Modif
                {sortColumn === "updatedAt" && (
                  <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                )}
              </th>
              <th scope="col">Voir</th>
              <th scope="col">Modifier</th>
              <th scope="col">Supprimer</th>
            </tr>
          </thead>
          <tbody>
            {visibleData.map((item, index) => (
              <tr key={item.id}>
                <th scope="row">{index + 1}</th>
                <td>{item.Title}</td>
                <td>{DateTimeConvertion(item.createdAt)}</td>
                <td>{DateTimeConvertion(item.updatedAt)}</td>
                <td>
                  <button
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Voir
                  </button>
                </td>
                <td>
                  <Link
                    className="btn btn-primary"
                    to={`/espace-admin/modifier-un-blog/${item._id}`}
                  >
                    Modifier
                  </Link>
                </td>
                <td>
                  <button
                    className="btn btn-primary"
                    onClick={() => handleDelete(item._id)}
                  >
                    Supprimer
                  </button>
                </td>
              </tr>
            ))}
            <div>
              <select
                className="justify-content-left"
                style={{ width: "50px" }}
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setCurrentPage(1);
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </select>

              <ul className="pagination justify-content-center">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <li key={index} className="page-item">
                    <button
                      className={`page-link ${
                        index + 1 === currentPage ? "active" : ""
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    &raquo;
                  </button>
                </li>
              </ul>
            </div>
          </tbody>
        </table>
      </div>
    </div>
  );
};

BlogList.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(BlogList);
