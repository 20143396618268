
import React from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import GeneralInfo from './GeneralInfo';
import Prix from './Prix';
import Offres from './Offres';
import Justificatif from './Justificatif';
import "./EditAnnonce.css"

const EditAnnonce = (props) => {
    const { itemId,data } = props;
    const editItem = data.find(annonce => annonce._id=== itemId);

    


  
    return (
      <>
      
      <div className="mesanonnance">
        <div className="r1 row">

          <div className='col-sm-6'>
          <h2 className='MesAnnoncesText'>Modifier Annonce</h2>
          </div>
          
        </div>
        <div className='affichee'>
          
        </div>
        <div className="add-adresse"  id="general-info">
        <div className="container field active" id="GeneralInfo" name="field" style={{display: "block"}}>
          <GeneralInfo data={editItem}/>
        </div>
        <div className="container field" id="Prix" style={{display:"none"}} name="field">
          <Prix data={editItem}/>
        </div>
        <div className="container field" id="OffreCourrier" style={{display:"none"}} name="field">
          <Offres data={editItem}/>
        </div>
        
        <div className="container field" id="CreateAnnoncePage2" style={{display:"none"}} name="field">
          <Justificatif itemId= {itemId} data={editItem}/>
        </div>
        
             
          
        
        

        </div>
   
      </div>
      </>
    );
  }


  EditAnnonce.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(EditAnnonce);
