
import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import Popup from "../../pages/espaces/EspaceAdmin/AdressesList/pop-up/pop-up";
import { fetchData } from "../ListeAnnonce/AdressesListFunctions";
import { useQuery } from "react-query"; 



const AnnonceEnAttente = (props) => {
 

  
    return (
      <>
      
      <div className="mesanonnance">
        <div className="r1 row">

          <div className='col-sm-6'>
          <h2 className='MesAnnoncesText'>Mes adresse</h2>
          </div>
          <div className=' col-6 text-right'>
          <label className='date '>
            <select name="date">
              <option value="13 sep 2023">13 sep 2023</option>
              <option value="14 sep 2023">14 sep 2023</option>
              <option value="15 sep 2023">15 sep 2023</option>
            </select>
          </label>
          </div>
        </div>
        <div className='affichee'>
          
        ⚠️ Oh non ! Vous n'avez pas encore acheté d'adresse. Ne manquez pas l'opportunité d'obtenir la vôtre dès maintenant.
        </div>
      
   
      </div>
      </>
    );
  }


  AnnonceEnAttente.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(AnnonceEnAttente);
