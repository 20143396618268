const allowedExtensions = [
    ".jpg",
    ".jpeg",
    ".png",
    ".gif",
    ".pdf",
    ".doc",
    ".docx",
    ".txt",
    ".zip",
    ".webp"
  ];
  function fileValidation(file, fileOrigin) {

    // check if file is empty
    if (!file) {
      return 'Please insert an ' + fileOrigin;
    }

    // Check if the uploaded file is an image
    if (!allowedExtensions.includes(file.name.slice(file.name.lastIndexOf('.')))) {
      return 'Only image and pdf files  are allowed.';
    }

    if (file.size > 5 * 1024 * 1024) {
      return "La taille de "+ fileOrigin +" doit être inférieure à 5 MB";
    }
    return true;
  }
  module.exports = fileValidation;