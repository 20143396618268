export const changeField = (value) => {
    // hide field that have diplay block and name field
    const element = document.querySelectorAll('[name="field"][style*="display: block"]')[0];
    
    element.classList.remove("active");
    setTimeout(() => {
      element.style.display = "none";
    }, 300);
    

    setTimeout(() => {
      document.getElementById(value).style.display = "block";
      setTimeout(() => {
        document.getElementById(value).classList.add("active");
      }, 300);
    }, 500);
    

  };