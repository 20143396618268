import React from "react";
import { Repondre } from "../AssistanceListFunctions";
import "./pop-up.css";

const Popup = (props) => {
  const [reponse, setReponse] = React.useState("");

  const addReponse = async () => {
    if (reponse === "") {
      document.getElementById("reponse").style.display = "block";
    } else {
      Repondre({
        Reponse: reponse,
        Email: props.assistance.Email,
        Sujet: props.assistance.Sujet,
        id: props.assistance._id,
      });
      document.getElementById("reponse").style.display = "none";
      setReponse("");
      props.onClose(true);
    }
  };

  return (
    <div
      className="modal"
      id="exampleModal"
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Details de l'assistance</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose} // Optional: Add an event handler to close the pop-up
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Nom:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.assistance === null ? "" : props.assistance.Nom}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Email:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.assistance === null ? "" : props.assistance.Email}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Numero:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.assistance === null ? "" : props.assistance.Number}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Sujet:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.assistance === null ? "" : props.assistance.Sujet}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="recipient-name"
                value={
                  props.assistance === null ? "" : props.assistance.Message
                }
              />
            </div>
            <div className="mb-3" id="reponse" style={{ display: "none" }}>
              <label htmlFor="recipient-name" className="col-form-label">
                Reponse:
              </label>
              <textarea
                className="form-control"
                onChange={(e) => setReponse(e.target.value)}
                name="reponse"
                id="recipient-name"
              />
            </div>

            {/* Add the content of the demand details here */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-primary"
              onClick={addReponse}
            >
              Repondre
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
