import React from "react";
import Header from "../../components/header/Header";

import imgprestige from "./images/domicilpro image de prestige.png";
import imgconfidentialite from "./images/domicilpro Confidentialité et sécurité.png";
import imgreductionCFE from "./images/Réduction de votre CFE.png";

import Counter from "../../components/Home/Counter/Counter";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import imgGrp from "../../images/imgGrp.png";
import "bootstrap/dist/css/bootstrap.css";
import imgexpert from "../../images/imgexpert.png";
import courrierImg from "../../images/courrierImg.png";
import adresseImg from "../../images/adresseImg.png";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./Home.css";
import Testimonials from "../../components/Home/testimonials/Testimonials";
import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import HomeSlider from "../../components/Home/HomeSlider/HomeSlider";
import Questions from "../../components/Questions/Questions";

const Home = (props) => {
  const { user, isAuthenticated } = props.auth;
  const { t } = useTranslation("home");
  document.title = "Domicil Pro - Domiciliation entreprise";

  return (
    <div>
      <div className="header-container">
        <Header isLoggedIn={isAuthenticated} user={user} />
      </div>

      <div className=" body-container ">
        <div className="container ">
          <div className="row align-items-center">
            <div className=" col-lg-6 col-12 col-md-12  text-center text-lg-start">
              <h1 className="display-1 mt-5 title-1Home-h1">
                {t("home.Domicile your company in a few clicks!")}
              </h1>
              <p className="my-4 text-1Home">{t("home.simple")}</p>

              <div className="row align-items-center  py-5">
                <div className="col-auto">
                  <Link to={`mon-compte`} className="textDecoration">
                    {" "}
                    <button className="btn-1Home">
                      {t("home.buton1")}
                      <i className="bi bi-arrow-right-circle-fill"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-12 grp-img text-center mt-5">
              <img src={imgGrp} alt="imgGrp" className="imgGrp" />
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <Testimonials />
      </div>
      <section id="hntexpert">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-6">
              <img src={imgexpert} alt="imgexpert" className="imgexpert" />
            </div>
            <div className="col-12 col-md-12 col-lg-6 p-5  ">
              <div>
                <h2 className="mt-5 pb-3 title-1Home-h2" id="title2">
                  {t("home.expertise title")}
                </h2>
                <p className="text-1Home">{t("home.expertise texte")}</p>
              </div>
              <div className="d-lg-flex d-md-flex  d-flex-columns flex-row justify-content-evenly ">
                <div className="expertise-box">
                  <img
                    src={adresseImg}
                    alt="domiciliation"
                    className="adresseImg"
                  />
                </div>
                <div className="expertise-box">
                  <h3 className="counters fw-bold">
                    +<Counter startValue={0} endValue={88} duration={4500} />
                  </h3>
                  <h5 className="counters-description">{t("home.adresses")}</h5>
                </div>
                <div className="expertise-box">
                  <img
                    src={courrierImg}
                    alt="domiciliation"
                    className="courrierImg"
                  />
                </div>
                <div className="expertise-box">
                  <h3 className="counters fw-bold">
                    +
                    <Counter startValue={0} endValue={430000} duration={3000} />
                  </h3>
                  <h5 className="counters-description">{t("home.mail")}</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ marginTop: "100px", marginBottom: "100px" }}>
        <div className="container">
          <div className="container-fluid">
            <h2 className="text-center carouselservice-h2">
              Obtenez votre contrat façilement !
            </h2>
            <HomeSlider />
          </div>
        </div>
      </section>

      <div className="container-fluid p-0 mt-5">
        <div className="bond-project bondGrow">
          <div className="container">
            <div className="row bondGrow d-flex flex-row justify-content-between">
              <div className="bondGcol1 bondImg col-12 col-md-12 col-lg-3"></div>
              <div className="bondGrowabs col-12 col-md-12 col-lg-9 mt-9">
                <div className="row">
                  <div className="bondGcol2 col-12 col-md-12 col-lg-9 mt-9 d-flex flex-colum d-flex align-items-center justify-content-center">
                    <h2
                      className="title-2Home"
                      style={{ paddingInline: "13px", textAlign: "center" }}
                    >
                      {t("home.bond")}
                    </h2>
                  </div>
                  <div className="bondGcol3 col-12 col-md-12 col-lg-3 d-flex flex-colum d-flex align-items-center justify-content-center">
                    <Link to="/contact" className="buttonContactDecoration">
                      <button className="bond-btn">
                        {t("home.button-contact")}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container" style={{ marginTop: "80px" }}>
        <div className="container">
          <h2 className="text-center title-1Home-h2">
            {t("home.Pourquoi opter pour la domiciliation ?")}
          </h2>
          <div className="row mt-4">
            <div className="col-md-4 mt-1">
              <div className="p-3 mt-auto bgcolumn10">
                <img
                  src={imgprestige}
                  className="imgbl2"
                  alt={t("home.Image de prestige")}
                />
                <h3 className="title-3Home">{t("home.Image de prestige")}</h3>
                <p className="texte-1Home">
                  {t(
                    "home.Choisissez votre siège social dans le quartier qui vous plaît."
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-3 mt-auto bgcolumn10">
                <img
                  src={imgconfidentialite}
                  className="imgbl2"
                  alt="{t('home.Confidentialité et sécurité')}"
                />
                <h3 className="title-3Home">
                  {t("home.Confidentialité et sécurité")}
                </h3>
                <p className="texte-1Home">
                  {t(
                    "home.Bénéficier de la plus stricte confidentialité et sécurité maximal."
                  )}
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="p-3 mt-auto bgcolumn10">
                <img
                  src={imgreductionCFE}
                  className="imgbl2"
                  alt={t("home.Réduction de votre CFE")}
                />
                <h3 className="titleh3ome">
                  {t("home.Réduction de votre CFE")}
                </h3>
                <p className="texte-1Home">
                  {t(
                    "home.Rester en actualité d'exonérations et de réductions pour la CFE."
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Questions />

      <Footer />
    </div>
  );
};

Home.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Home);
