import React from "react";
import LoadingScreen from "../../../Loading-Screen/LoadingScreen";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import "./Section1.css";
import { useTranslation } from "react-i18next";
import axios from "axios";

const fetchData = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/adresses/${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    }
  );
  return response.data;
};

const getProximityAddresses = async (id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_URL}/api/adresses/getproximityadresses/${id}`,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    }
  );
  return response.data;
};

const Section1 = () => {
  window.scrollTo(0, 0);
  const [entrepriseType, setEntrepriseType] = React.useState("PrixEntreprise");
  const { id } = useParams();
  const { t } = useTranslation();

  const {
    data: addressData,
    isLoading,
    isError,
  } = useQuery(["addressData", id], () => fetchData(id), {
    enabled: !!id,
  });

  const {
    data: proximityAddresses,
    ProximityAdressesisLoading,
    ProximityAdressesisError,
  } = useQuery(["proximityAddresses", id], () => getProximityAddresses(id), {
    enabled: !!id,
  });

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isError) {
    return <div>Error fetching data</div>;
  }
  return (
    <div className="address-details-container">
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-8 col-md-12">
            <div>
              <span className="address-details-location">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19"
                  height="26"
                  viewBox="0 0 19 26"
                  fill="none"
                >
                  <path
                    d="M9.5 0C4.53144 0 0.5 3.74388 0.5 8.35714C0.5 15.7857 9.5 26 9.5 26C9.5 26 18.5 15.7857 18.5 8.35714C18.5 3.74388 14.4686 0 9.5 0ZM9.5 13C8.78799 13 8.09196 12.7822 7.49995 12.374C6.90793 11.9659 6.44651 11.3858 6.17403 10.7071C5.90156 10.0284 5.83027 9.28159 5.96917 8.56109C6.10808 7.84059 6.45095 7.17877 6.95442 6.65932C7.45788 6.13986 8.09934 5.78611 8.79768 5.6428C9.49601 5.49948 10.2198 5.57304 10.8777 5.85416C11.5355 6.13529 12.0977 6.61136 12.4933 7.22217C12.8889 7.83298 13.1 8.5511 13.1 9.28571C13.099 10.2705 12.7193 11.2146 12.0444 11.9109C11.3695 12.6073 10.4545 12.9989 9.5 13Z"
                    fill="#00A4B8"
                  />
                </svg>
                &nbsp;
                {addressData.Location.Label}
              </span>
              <br />
              <span className="address-details-city">
                {addressData.Location.City}
              </span>
              <span className="address-details-apropos">
                {addressData.APropos}
              </span>
            </div>
          </div>
          <div className="col-lg-4 col-md-12">
            <span className="address-details-prix">
              {addressData.PrixEntreprise.Prix}€ Par mois
            </span>
            <button className="address-details-button">
              Choisir cette adresse
            </button>
          </div>
        </div>
      </div>
      <div className="container py-0">
        <div className="row">
          <div className="col-lg-12 col-md-12">
            <div>...</div>
          </div>
        </div>
      </div>
      <hr
        align="center"
        style={{
          color: "background: #D9D9D9 !important",
          width: "70%",
          marginInline: "auto",
          marginTop: "70px",
          marginBottom: "70px",
        }}
      />

      <div style={{ backgroundColor: "#EFF6F5" }}>
        <div className="container p-5">
          <div className="row" style={{ width: "100%" }}>
            <div className="col-12">
              <div className="address-details-h2 pb-4 pt-4">photos</div>
            </div>
            <div className="col-12">
              {addressData.Images.map((image) => (
                <img
                  key={image._id}
                  src={`${process.env.REACT_APP_API_URL}/api/adresseImages/${image._id}`}
                  alt={`Adresse n° ${image._id}`}
                  className="p-2 address-details-img"
                />
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="container pt-5">
        <div className="row" style={{ width: "100%", padding: "10px" }}>
          <h2 className="address-details-h2 pb-3">
            Autres adresses à proximité
          </h2>
          {proximityAddresses ? (
            proximityAddresses.map((adresse) => (
              <div key={adresse._id} className="col-md-6 mt-1">
                <div className=" card cardAdresses">
                  <div className="zoom-container">
                    <Link to={`/adresse/${adresse._id}`}>
                      <img
                        src={`${process.env.REACT_APP_API_URL}/api/adresseImages/${adresse.Images[0]._id}`}
                        style={{ height: "250px" }}
                        className="card-img-top"
                        alt={adresse.company}
                      />
                    </Link>
                  </div>
                  <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <h5 className="card-prix">
                          {adresse[entrepriseType].Prix}€ Par Mois
                        </h5>
                      </div>
                      <div>
                        <Link
                          to={`/adresse/${adresse._id}`}
                          className="btn-savoirAdressesText btn-savoirAdresses"
                        >
                          {t("body.learn more")}{" "}
                        </Link>
                      </div>
                    </div>

                    <br />

                    <h3 className="card-text title-3Adresse">
                      {t("body.city")} {adresse.Location.City}
                    </h3>

                    <h3 className="card-title title-3Adresse">
                      {adresse.company}
                    </h3>
                    <h3 className="card-text Adresse-Complet">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="19"
                        height="26"
                        viewBox="0 0 19 26"
                        fill="none"
                      >
                        <path
                          d="M9.5 0C4.53144 0 0.5 3.74388 0.5 8.35714C0.5 15.7857 9.5 26 9.5 26C9.5 26 18.5 15.7857 18.5 8.35714C18.5 3.74388 14.4686 0 9.5 0ZM9.5 13C8.78799 13 8.09196 12.7822 7.49995 12.374C6.90793 11.9659 6.44651 11.3858 6.17403 10.7071C5.90156 10.0284 5.83027 9.28159 5.96917 8.56109C6.10808 7.84059 6.45095 7.17877 6.95442 6.65932C7.45788 6.13986 8.09934 5.78611 8.79768 5.6428C9.49601 5.49948 10.2198 5.57304 10.8777 5.85416C11.5355 6.13529 12.0977 6.61136 12.4933 7.22217C12.8889 7.83298 13.1 8.5511 13.1 9.28571C13.099 10.2705 12.7193 11.2146 12.0444 11.9109C11.3695 12.6073 10.4545 12.9989 9.5 13Z"
                          fill="#00A4B8"
                        />
                      </svg>{" "}
                      {adresse.Location.Label}
                    </h3>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No addresses found.</p>
          )}
        </div>
      </div>

      {/*<div className="address-header">
        <h2>{addressData.Location.Ville}</h2>
        <p>{addressData.Location.CodePostal}</p>
      </div>
      <div className="address-info">
        <p>
          Adresse: {addressData.Location.Rue} {addressData.Location.Numero}
        </p>
        <p>Prix Par Courrier: {addressData.OffresCourrier.PrixParCourrier}</p>
      </div>
      <div className="prix-section">
        <h3>Prix:</h3>
        <h5>Quelle est votre profile ? </h5>
        <input
          type="radio"
          id="entreprise"
          checked={entrepriseType === "PrixEntreprise"}
          onClick={(e) => setEntrepriseType(e.target.value)}
          name="profile"
          value="PrixEntreprise"
        />
        <label htmlFor="entreprise" className="adresse-prix-label">
          Entreprise
        </label>
        <br />
        <input
          type="radio"
          id="auto-entrepreneur"
          checked={entrepriseType === "PrixAE"}
          onClick={(e) => setEntrepriseType(e.target.value)}
          name="profile"
          value="PrixAE"
        />
        <label htmlFor="auto-entrepreneur" className="adresse-prix-label">
          Auto-Entrepreneur
        </label>
        <br />
        <input
          type="radio"
          id="sci"
          checked={entrepriseType === "PrixSCI"}
          onClick={(e) => setEntrepriseType(e.target.value)}
          name="profile"
          value="PrixSCI"
        />
        <label htmlFor="sci" className="adresse-prix-label">
          SCI
        </label>
        <hr />
        <h5>A Partir de</h5>
        <h5>
          <strong>{addressData[entrepriseType].Prix}</strong> par mois
        </h5>
      </div>
      <div className="condition-acces">
        <h3>Condition d'Acces:</h3>
        <p>{addressData.ConditionDAcces}</p>
      </div>
      <div className="A-Propos">
        <h3>APropos:</h3>
        <p>{addressData.APropos}</p>
      </div>
      <div className="images-section">
        <h3>Images:</h3>
        {addressData.Images.map((image) => (
          <img
            key={image._id}
            width={"250px"}
            src={`${process.env.REACT_APP_API_URL}/api/adresseImages/${image._id}`}
            alt={`Adresse n° ${image._id}`}
          />
        ))}
      </div>*/}
    </div>
  );
};

export default Section1;
