import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const AcheteurRoute = ({ children ,auth }) => {
  if (auth.isAuthenticated === false) {
    return <Navigate to="/login" />;
  }
  return auth.isAcheteur === true ? children : <Navigate to="/mon-compte" />;
}

AcheteurRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AcheteurRoute);
