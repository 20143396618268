import axios from "axios";

export const fetchData = async (props) => {
    try {
        // Destructure the props object
        const { user, isAuthenticated } = props.auth;

       

       

        // Make the API request
        const response = await axios.get(
            process.env.REACT_APP_API_URL + `/api/adresses/adresses/user/${user.Profiles.Type}`,
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
                },
            }
        );

        console.log(response.data);
        return response.data;
    } catch (error) {
        throw new Error("Error fetching data from API: " + error.message);
    }
};
