import React, { useEffect } from "react";
import Header from "../../components/header/Header";

import services_images_1 from "./images/services_images_1.png";
import services_images_2 from "./images/services_images_2.png";

import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "../Home/Home.css";
import "./Services.css";

import { useTranslation } from "react-i18next";
import Footer from "../../components/footer/Footer";
import BandeBleu from "../../components/Services/BandeBleu/BandeBleu";
import Slidertarifs from "../../components/Tarifs/slider/Slidertarifs";

const Services = (props) => {
  const { user, isAuthenticated } = props.auth;
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
    document.title = "Domicil Pro - Services";
    document.getElementById("services").classList.add("active");
  }, []);
  return (
    <div>
      <div className="header-container">
        <Header isLoggedIn={isAuthenticated} user={user} />
      </div>

      <div className=" body-container ">
        <div className="container ">
          <div className="row align-items-center">
            <div className=" col-lg-6 col-12 col-md-12  text-center text-lg-start">
              <h1 className="display-1 mt-5 title-1Home-h1">
                {t("services.h1")}
              </h1>
              <p className="my-4 text-1Home">{t("home.simple")}</p>

              <div className="row align-items-center  py-5">
                <div className="col-auto">
                  <Link to={`mon-compte`} className="textDecoration">
                    {" "}
                    <button className="btn-1Home">
                      {t("home.buton1")}
                      <i className="bi bi-arrow-right-circle-fill"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 col-md-12 grp-img text-center mt-5">
              <img src={services_images_1} alt="imgGrp" className="imgGrp" />
            </div>
          </div>
        </div>
      </div>

      <section id="hntexpert" style={{ marginTop: "100px" }}>
        <div className="container">
          <div className="row">
            <div className="divimgexpert col-12 col-md-12 col-lg-6">
              <img
                src={services_images_2}
                alt="imgexpert"
                className="imgexpert"
              />
            </div>
            <div className="col-12 col-md-12 col-lg-6  ">
              <div>
                <h2 className="mt-1 pb-1 service-h2" id="title2">
                  {t("services.h2-1")}
                </h2>
                <p className="text-2Service">{t("services.h2-1-text")}</p>
                <h3 className="title-3Service">{t("services.h3-2")}</h3>
                <p className="text-2Service">{t("services.h3-2-text")}</p>
                <h3 className="title-3Service">{t("services.h3-2")}</h3>
                <p className="text-2Service">{t("services.h3-2-text")}</p>
                <h3 className="title-3Service">{t("services.h3-3")}</h3>
                <p className="text-2Service">{t("services.h3-3-text")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <hr
        align="center"
        style={{
          color: "background: #D9D9D9 !important",
          width: "70%",
          marginInline: "auto",
          marginTop: "70px",
          marginBottom: "70px",
        }}
      />

      <section style={{ marginTop: "100px", marginBottom: "100px" }}>
        <div className="container">
          <div className="container-fluid">
            <h2 className="text-center carouselservice-h2">
              {t("services.h2-2")}
            </h2>
            <Slidertarifs />
          </div>
        </div>
      </section>

      <section>
        <div className="container">
          <div className="container-fluid">
            <div className="row justify-content-center">
              <div className="col-lg-12 col-md-12">
                <h2 className="text-center service-h2">{t("services.h2-3")}</h2>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="element">
                  <div className="overlap-wrapper1"></div>
                  <h3 className="text-wrapper">
                    Répondez à notre questionnaire en ligne
                  </h3>
                  <p className="div">
                    Définissez l’adresse de domiciliation et l’option courrier
                    qui vous convient le mieux. Vous pourrez également ajouter
                    d’autres options comme la gestion des formalités juridiques.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="element">
                  <div className="overlap-wrapper2"></div>
                  <h3 className="text-wrapper">
                    Effectuez le paiement en ligne
                  </h3>
                  <p className="div">
                    Finalisez votre demande en effectuant le paiement sur notre
                    plateforme sécurisée.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12">
                <div className="element">
                  <div className="overlap-wrapper3"></div>
                  <h3 className="text-wrapper">
                    Signez votre contrat de domiciliation
                  </h3>
                  <p className="div">
                    Le contrat est impératif pour le dossier de création ou
                    transfert de votre société. Votre signature active le
                    service de gestion de vos courriers.
                  </p>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-5 offset-lg-1 col-md-12">
                <div className="element-right">
                  <div className="overlap-wrapper4"></div>
                  <h3 className="text-wrapper">
                    Recevez votre KBIS et vos statuts
                  </h3>
                  <p className="div">
                    High level overviews. approaches lverall value proposition.
                    Organically grow the holistic world view of disruptive.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 ">
                <div className="element-left">
                  <div className="overlap-wrapper5"></div>
                  <h3 className="text-wrapper">
                    Finalisez votre domiciliation
                  </h3>
                  <p className="div">
                    Nous recevrons automatiquement votre KBIS et vos statuts.
                    Votre domiciliation sera alors complète et conforme.
                  </p>
                </div>
              </div>
              <div className="col-lg-2 col-md-12"></div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 text-center">
                <button className="servicebtn">
                  Commencer maintenant{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="16"
                    viewBox="0 0 18 16"
                    fill="none"
                  >
                    <path
                      d="M17.5 8C17.5 3.58192 13.6942 0 9 0C4.30579 0 0.5 3.58192 0.5 8C0.5 12.4181 4.30579 16 9 16C13.6942 16 17.5 12.4181 17.5 8ZM8.80998 11.5138C8.749 11.4569 8.70054 11.3893 8.66736 11.3147C8.63417 11.2402 8.61692 11.1602 8.61658 11.0794C8.61623 10.9985 8.63281 10.9184 8.66537 10.8437C8.69792 10.7689 8.74581 10.7008 8.8063 10.6435L10.9444 8.61539H5.48558C5.31217 8.61539 5.14586 8.55055 5.02324 8.43514C4.90062 8.31974 4.83173 8.16321 4.83173 8C4.83173 7.83679 4.90062 7.68026 5.02324 7.56486C5.14586 7.44945 5.31217 7.38462 5.48558 7.38462H10.9444L8.8063 5.35654C8.74582 5.29911 8.69795 5.23103 8.66542 5.15619C8.63289 5.08135 8.61635 5.00122 8.61673 4.92036C8.61711 4.8395 8.6344 4.7595 8.66763 4.68494C8.70086 4.61037 8.74937 4.54269 8.81038 4.48577C8.8714 4.42885 8.94374 4.38379 9.02325 4.35318C9.10277 4.32257 9.18791 4.30699 9.27383 4.30735C9.44733 4.30807 9.61343 4.37363 9.73558 4.48962L12.9799 7.56654C13.1014 7.68181 13.1696 7.83761 13.1696 8C13.1696 8.16239 13.1014 8.31819 12.9799 8.43346L9.73558 11.5104C9.67509 11.5679 9.60314 11.6136 9.52386 11.6449C9.44458 11.6762 9.35951 11.6925 9.27354 11.6928C9.18756 11.6931 9.10237 11.6775 9.02282 11.6468C8.94328 11.6161 8.87095 11.5709 8.80998 11.5138Z"
                      fill="white"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <BandeBleu />

      <Footer />
    </div>
  );
};

Services.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Services);
