import { SET_CURRENT_USER, USER_LOADING } from "../actions/types";

const isEmpty = require("is-empty");

const initialState = {
  isAuthenticated: false,
  isAdmin: false,
  isVendeur: false,
  isAcheteur: false,
  hasAdminProfile: false,
  hasVendeurProfile: false,
  hasAcheteurProfile: false,
  user: {},
  loading: false,
};

export default function authReducer(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        isAdmin:
          Array.isArray(action.payload.Roles) &&
          action.payload.Roles.includes("Admin"),
        isVendeur:
          Array.isArray(action.payload.Roles) &&
          action.payload.Roles.includes("Vendeur"),
        isAcheteur:
          Array.isArray(action.payload.Roles) &&
          action.payload.Roles.includes("Acheteur"),
        hasAdminProfile:
          action.payload.Profiles &&
          typeof action.payload.Profiles === "object" &&
          Object.keys(action.payload.Profiles).includes("Admin"),
        hasVendeurProfile:
          action.payload.Profiles &&
          typeof action.payload.Profiles === "object" &&
          Object.keys(action.payload.Profiles).includes("Vendeur"),
        hasAcheteurProfile:
          action.payload.Profiles &&
          typeof action.payload.Profiles === "object" &&
          Object.keys(action.payload.Profiles).includes("Acheteur"),
        user: action.payload,
      };
    case USER_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
