import React from "react";
import axios from "axios";
import "./pop-up.css";

const Popup = (props) => {
  const updateProfile = async (status) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/adresses/validate/${props.adresse._id}/${status}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      if (response.ok) {
        // The demand was successfully updated
        console.log(`Profile ${props.adresse._id} updated to ${status}`);
        // Perform any necessary actions after the update
      }
    } catch (error) {
      console.error(error);
      // Handle the error or show an error message to the user
    }
  };
  return (
    <div
      className="modal"
      id="exampleModal"
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Details de l'adresse</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose} // Optional: Add an event handler to close the pop-up
            ></button>
          </div>
          <div className="modal-body">
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                APropos:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.adresse === null ? "" : props.adresse.APropos}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                ConditionDAcces:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={
                  props.adresse === null ? "" : props.adresse.ConditionDAcces
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                CIN:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={
                  props.adresse === null ? "" : props.adresse.CIN
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                SIRET:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={
                  props.adresse === null ? "" : props.adresse.SIRET
                }
              />
            </div>

            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Prix Entreprise:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={
                  props.adresse === null
                    ? ""
                    : props.adresse.PrixEntreprise.Prix
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Prix SCI:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.adresse === null ? "" : props.adresse.PrixSCI.Prix}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Prix Auto-Entrepreneur:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.adresse === null ? "" : props.adresse.PrixAE.Prix}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Localisation:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={
                  props.adresse === null ? "" : props.adresse.Location.Label
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Prix Par Courrier:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={
                  props.adresse === null
                    ? ""
                    : props.adresse.OffresCourrier.PrixParCourrier.Prix + "€"
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Prix du pack Starter:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={
                  props.adresse === null
                    ? ""
                    : props.adresse.OffresCourrier.Starter.Prix + "€"
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Prix du pack Premium:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={
                  props.adresse === null
                    ? ""
                    : props.adresse.OffresCourrier.Premium.Prix + "€"
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Prix du pack Business:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={
                  props.adresse === null
                    ? ""
                    : props.adresse.OffresCourrier.Business.Prix + "€"
                }
              />
            </div>

            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Images:
              </label>
              {props.adresse === null
                ? ""
                : props.adresse.Images.map((image) => {
                    return (
                      <a
                        key={1}
                        className="form-control"
                        id="message-text"
                        rel="noreferrer"
                        target="_blank"
                        href={
                          props.adresse === null
                            ? ""
                            : `http://localhost:5000/api/adresseImages/${image._id}`
                        }
                      >
                        {image.Url}{" "}
                      </a>
                    );
                  })}
            </div>

            <div className="mb-3">
              <label htmlFor="message-text" className="col-form-label">
                Docs:
              </label>
              {props.adresse === null
                ? ""
                : props.adresse.Docs.map((doc) => {
                    return (
                      <a
                        key={2}
                        className="form-control"
                        id="message-text"
                        rel="noreferrer"
                        target="_blank"
                        href={
                          props.adresse === null
                            ? ""
                            : `http://localhost:5000/api/adresseDocs/${doc._id}`
                        }
                      >
                        {doc.Url}{" "}
                      </a>
                    );
                  })}
            </div>

            {/* Add the content of the demand details here */}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={props.onClose} // Optional: Add an event handler to close the pop-up
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => updateProfile("V")}
            >
              Accepter
            </button>
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => updateProfile("R")}
            >
              Refuser
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
