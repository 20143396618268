import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { changeField } from "../../../utils/changeFieldPageVendeur";

const GeneralInfo = (props) => {

  const [formValues, setFormValues] = useState({
    APropos: "",
    ConditionDAcces: ""
  });

  useEffect(() => {
    //get data from props
    if(props.GeneralInfo){
      setFormValues({
        APropos: props.GeneralInfo.APropos,
        ConditionDAcces: props.GeneralInfo.ConditionDAcces
      });
    }
    
  }, [props.GeneralInfo]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleNextClick = () => {
    
    // Store formValues in local storage
    const adresse = JSON.parse(localStorage.getItem("adresse"));
    const adresseComplete = { ...adresse, ...formValues };
    localStorage.setItem("adresse", JSON.stringify(adresseComplete));

    // Call your changeField function
    changeField("Location");
  };

  return (
    <form className="row g-3">
      <h2 className="text-center">Informations Générales</h2>
      <hr style={{ color: "white" }} />

      <div className="col-md-12">
        <label className="prix-label">A Propos :</label>
      </div>
      <div className="col-md-12">
        <textarea
          id="APropos"
          name="APropos"
          className="text-area"
          placeholder={"A Propos"}
          value={formValues.APropos}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-md-12">
        <label className="prix-label">Condition D'acces :</label>
      </div>
      <div className="col-md-12">
        <textarea
          id="ConditionDAcces"
          name="ConditionDAcces"
          className="text-area"
          placeholder={"Condition D'acces"}
          value={formValues.ConditionDAcces}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-12 d-flex justify-content-center">
        <button
          type="button"
          className="mon-boutonVendeur"
          value={"Location"}
          onClick={handleNextClick}
        >
          Next
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(GeneralInfo);
