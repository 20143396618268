import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { changeField } from "../../utils/changeFieldPageVendeur";


const OffreCourrier = (props) => {
  const [formValues, setFormValues] = useState({
    Starter: props.data.OffresCourrier.Starter.Prix,
    Business: props.data.OffresCourrier.Business.Prix,
    Premium: props.data.OffresCourrier.Premium.Prix,
    PrixParCourrier: props.data.OffresCourrier.PrixParCourrier.Prix,
  });
   console.log("raed",props.data.OffresCourrier);

  useEffect(() => {
    //get data from props
    if (props.OffresCourrier) {
      setFormValues({
        Starter: props.OffresCourrier.Starter,
        Business: props.OffresCourrier.Business,
        Premium: props.OffresCourrier.Premium,
        PrixParCourrier: props.OffresCourrier.PrixParCourrier,
      });
    }
  }, [props.OffresCourrier]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: parseFloat(value), // Convert to a number
    }));
  };

  const handleNextClick = () => {
    // Store formValues in local storage
    let OffresCourrier = [
      {
        Nom: "Starter",
        Description: "Offre Starter",
        Prix: formValues.Starter,
      },
      {
        Nom: "Business",
        Description: "Offre Business",
        Prix: formValues.Business,
      },
      {
        Nom: "Premium",
        Description: "Offre Premium",
        Prix: formValues.Premium,
      },
      {
        Nom: "PrixParCourrier",
        Description: "Offre PrixParCourrier",
        Prix: formValues.PrixParCourrier,
      },
    ];

    let adresse = JSON.parse(localStorage.getItem("adresse"));
    adresse.OffresCourrier = OffresCourrier;
    

    localStorage.setItem("adresse", JSON.stringify(adresse));

    // Call your changeField function
    changeField("CreateAnnoncePage2");
  };

  return (
    <form className="row g-3">
    
      
      <h2 className="text-center textoffreCourriers">Les Offres Courriers</h2>
      <hr style={{ color: "white" }} />

      <div className="col-md-6">
        <div className=" OffreCourrierText">Prix pour l'offre Starter :</div>
      </div>
      <div className="">
        <input
          id="Starter"
          name="Starter"
          type="number"
          className="CreateAnnonce-input  inputOffre"
          value={formValues.Starter}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-md-6">
        <div className=" OffreCourrierText">Prix pour l'offre Business :</div>
      </div>
      <div className="">
        <input
          id="Business"
          name="Business"
          type="number"
          className="CreateAnnonce-input  inputOffre"
          value={formValues.Business}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-md-6">
        <div className=" OffreCourrierText">Prix pour l'offre Premium :</div>
      </div>
      <div className="">
        <input
          id="Premium"
          name="Premium"
          type="number"
          className="CreateAnnonce-input  inputOffre"
           value={formValues.Premium}
          onChange={handleInputChange}
        />
      </div>

      <div className="col-md-6">
        <div className="  OffreCourrierText">Prix Par Courrier </div>
      </div>
      <div className="">
        <input
          id="PrixParCourrier"
          name="PrixParCourrier"
          type="number"
          className="CreateAnnonce-input  inputOffre"
          value={formValues.PrixParCourrier}
          onChange={handleInputChange}
        />
      </div>

      <div  >
        <button
          type="button"
          className="btn button  OffreCourriersBtn"
          value={"CreateAnnoncePage2"}
          onClick={handleNextClick}
        >
          Suivant
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(OffreCourrier);

