import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const DemandeVendeurRoute = ({ children ,auth }) => {
  if (auth.isAuthenticated === false) {
    return <Navigate to="/login" />;
  }
  return auth.hasVendeurProfile === false ? children : <Navigate to="/espace-vendeur" />;
}

DemandeVendeurRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(DemandeVendeurRoute);