import React, { useState, useEffect } from "react";
import axios from "axios";
import EditBlog from "./EditBlog";
import { useParams } from "react-router-dom";
import Header from "../../../../../components/header/Header";
import Footer from "../../../../../components/footer/Footer";

const UpdateBlog = () => {
  const { id } = useParams();
  useEffect(() => {
    viewPostId();
  }, []);

  const [post, setPost] = useState(null);
  const viewPostId = async () => {
    try {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/api/blogs/${id}`)
        .then((res) => {
          setPost(res.data);
        });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  return (
    <>
      <Header />
      {post ? (
        <>
          <EditBlog post={post} />
        </>
      ) : null}
      <Footer />
    </>
  );
};

export default UpdateBlog;
