import axios from "axios";

export const getaccountsnumber = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + "/api/users/getaccountsnumber", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching data from API: " + error.message);
  }
};

export const fetchData = async () => {
  try {
    const response = await axios.get(process.env.REACT_APP_API_URL + "/api/users/getaccounts", {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
      },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching data from API: " + error.message);
  }
};
