import axios from "axios";

export const fetchData = async () => {
  // Define the fetchData function
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/adresses",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    throw new Error("Error fetching data from API: " + error.message);
  }
};
