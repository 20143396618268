import React from "react";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

const DemandeAcheteurRoute = ({ children ,auth }) => {
  if (auth.isAuthenticated === false) {
    return <Navigate to="/login" />;
  }
  return auth.hasAcheteurProfile === false ? children : <Navigate to="/espace-acheteur" />;
}

DemandeAcheteurRoute.propTypes = {
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(DemandeAcheteurRoute);