import React ,{useEffect} from "react";
import Header from "../../../../../components/header/Header";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SideBar from "../../../../../components/SideBars/AdresseSideBar/SideBar";
import "./AjoutAdresse.css";
import "../../../Espace.css"
import GeneralInfo from "../../../../../components/EspaceVendeur/AjouterAdresse/GeneralInfo";
import Location from "../../../../../components/EspaceVendeur/AjouterAdresse/Location";
import Justificatifs from "../../../../../components/EspaceVendeur/AjouterAdresse/Justificatifs";
import Offres from "../../../../../components/EspaceVendeur/AjouterAdresse/Offres";
import Prix from "../../../../../components/EspaceVendeur/AjouterAdresse/Prix";
  
  
const AjoutAdresse = (props) => {
  const { user, isAuthenticated } = props.auth;

  useEffect(() => {
    document.title ="Ajouter une adresse";
  }, []);

  return (
    <div className="espace-container">
      <Header isLoggedIn={isAuthenticated} user={user}/>
        <div className="espace-vendeur">
        <SideBar className="side-bar"/>
        
        <div className="add-adresse"  id="general-info">
        <div className="container field active" id="GeneralInfo" name="field" style={{display: "block"}}>
          <GeneralInfo />
        </div>
        <div className="container field" id="Location" style={{display:"none"}} name="field">
          <Location />
        </div>
        <div className="container field" id="Justificatifs" style={{display:"none"}} name="field">
          <Justificatifs  />
        </div>
        <div className="container field" id="Offres" style={{display:"none"}} name="field">
          <Offres  />
        </div>   
        <div className="container field" id="Prix" style={{display:"none"}} name="field">
          <Prix  />
        </div>   
        
        

        </div>
      </div>
    </div>
  );
};

AjoutAdresse.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(AjoutAdresse);
