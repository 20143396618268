import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Login.css";
import { arrows_exclamation } from "react-icons-kit/linea/arrows_exclamation";
import { arrows_circle_check } from "react-icons-kit/linea/arrows_circle_check";
import Icon from "react-icons-kit";

const RegisterForm = ({
  onSubmit,
  errors,
  onChange,
  email,
  password1,
  nom,
  prenom,
  numero,
}) => {
  
  const [validationResults, setValidationResults] = useState({
    lower: false,
    upper: false,
    number: false,
    length: false,
  });

  const handleValidation = (value) => {
    const lower = /(?=.*[a-z])/;
    const upper = /(?=.*[A-Z])/;
    const number = /(?=.*\d)/;
    const length = /(?=.{8,})/;

    setValidationResults({
      lower: lower.test(value),
      upper: upper.test(value),
      number: number.test(value),
      length: length.test(value),
    });
  };
  
  
  const [isPasswordShow ,setIsPasswordVisible]=useState(false);
  
  return (
    <div style={{ marginTop: "1rem", width: "90%" }} className="row login-div">
      <div className=" col-md-10 offset-md-2">
        <form noValidate onSubmit={onSubmit}>
          <h1 className="title">S'inscrire</h1>
          <h2 className=" sousTitre1">Remplissez vos identifiants et cliquez sur le bouton S'inscrire</h2>
          {errors?.error && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "95%" }}
              role="alert"
            >
              {errors.error}
            </div>
          )}
          <div className="row">
          <div className="col-md-6 d-flex  justify-content-start flex-column">
            <div className="Att">Nom</div>
            <div >
            <input
            onChange={onChange}
              type="text"
              label="Nom"
              id="nom"
              
              className="login-input  "
              placeholder="Vos Nom"
              />
              <div className="imgprof"></div>
              </div>   
          </div>
          <div className="col-md-6 d-flex  justify-content-start flex-column">
          <div className="Att">Prenom</div>
           <div > 
            <input
            onChange={onChange}
              type="text"
              label="Nom"
              id="prenom"
              
              className="login-input  "
              placeholder="Vos Prenom"
              />
              <div className="imgprof1"></div>
              </div>
          </div>
          </div>
          <div className="row">
          <div className="col-md-6 d-flex  justify-content-start flex-column">
          <div className="Att">Email</div>
          <input 
            onChange={onChange}
            value={email}
            id="email"
            type="email"
            className="login-input"
            placeholder="exemple@mail.com"
          />
          </div>
          <div className="col-md-6 d-flex justify-content-start flex-column">
          <div className="Att">Téléphone</div>
          <input
          onChange={onChange}
           placeholder="numéro de telephone" 
           
           className="login-input" type="tel" id="numero"  pattern="[0-9]{8}" required />
          </div>
          </div>
          <div className="col-12 d-flex justify-content-start flex-column">
          <div className="Att1">Mot de Passe</div>
          <div>
          <input
            onChange={(e) => {
              onChange(e);
              handleValidation(e.target.value); // Validate password
            }}
            value={password1}
            id="password1"
            type={ isPasswordShow ? 'text' : 'password'}
            className="login-input1"
            placeholder="Mot de passe"
          />
          <div className="imgeye" onClick={() => setIsPasswordVisible(!isPasswordShow)} >
            
          </div>
          </div>
          <div >
          <Link to="/reinitialisation-mot-de-passe" className="passf">
          Mot de passe oublié?
              </Link>
          </div>
          </div>
          <div className="d-grid gap-2">
            <button type="submit" className="btn button">
              S’inscrire
            </button>
          </div>
          <div className="inline-container">
            <h4 className="inscrivez-vous">
              Vous avez déjà un compte ?
              <Link to="/login" className="inscrivez-vous link-inscription">
                Se Connecter
              </Link>
            </h4>
          </div>
        </form>
      </div>
    </div>
  );

  
};

export default RegisterForm;
