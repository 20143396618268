import React, { Component, useState } from 'react';
import './DashboardApp.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { fetchData } from "../ListeAnnonce/AdressesListFunctions";
import { useQuery } from "react-query"; 
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../actions/authActions";
import ListeAnnonces from '../ListeAnnonce/ListeAnnonces';
import ListeAchat from '../ListeAchat/ListeAchat';
import ListeContrat from '../ListeContrat/ListeContrat';
import EditAnnonce from '../EditAnnonce/EditAnnonce';

const DashboardApp = (props) => {
    const { user, isAuthenticated } = props.auth;
    const [activeItem, setActiveItem] = useState('AnnonceEnAttente'); // Default active item

    const queryKey = ["adresses", user.id];
    const { data, isLoading, error } = useQuery(queryKey, () => fetchData(props));
    const annoncesData = data
  ? data.map((item,index) => ({
      id: index,
      titre:item.Location.Label,
       // Assurez-vous d'ajuster cela en fonction de la structure réelle de vos données
      date: item.createdAt,   // Assurez-vous d'ajuster cela en fonction de la structure réelle de vos données
      data: item,
    }))
  : [];
  
  const annoncesValide = annoncesData.filter((annonce) => annonce.data.Verifie === "Validé");
  
  const annoncesValideCount = annoncesValide.length;
  const formattedAnnoncesValideCount = annoncesValideCount < 10 ? `0${annoncesValideCount}` : annoncesValideCount.toString();
    
    

    return (
        <>
            <div className="containers">
                <div className="row">
                    <div className="">
                        <div className="dashboard">
                            <div className="dashboard-header">
                                <div className="app">
                                    <div className="class1 row">
                                        <div className='col-md-2'>
                                            <h2 className='DashboardText'>DashBoard</h2>
                                        </div>
                                        <div className='col-md-10 text-right'>
                                            <Link to="/dashboard/CreeAnnonce1" className='CreeAnnonce'>
                                                Créer une autre annonce
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="dashboard-stats row">
                                <div className="col-md-3">
                                    <div className="stat-item item1" onClick={() => setActiveItem('AnnonceEnAttente')}>
                                        <div className="stat-label">Adresses</div>
                                        <div className="stat-value">00</div>
                                        <div className="stat-lab st3">acheté</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                    <div className="stat-item item2" onClick={() => setActiveItem('ListeAnnonces')}>
                                        <div className="stat-label">Annonces </div>
                                        <div className="stat-value">{formattedAnnoncesValideCount}</div>
                                        <div className="stat-lab st2">publiés</div>
                                    </div>
                                </div>
                                <div className="col-md-3">
                                <div className="stat-item item3" onClick={() => setActiveItem('ListeContrat')}>

                                        <div className="stat-label">Les contrats </div>
                                        <div className="stat-value">00</div>
                                        <div className="stat-lab st1">validés</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {activeItem === 'AnnonceEnAttente' && <ListeAchat/>}
            {activeItem === 'ListeAnnonces' && <ListeAnnonces/>}
            {activeItem === 'ListeContrat' && <ListeContrat />}

        </>
    );
}

DashboardApp.propTypes = {
    logoutUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(DashboardApp);
