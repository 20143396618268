import React, { useState,useEffect } from 'react';
import Annonce from './Annonce';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchData } from '../ListeAnnonce/AdressesListFunctions';
import { useQuery } from 'react-query';
import './AnnonceEnAttente.css';
import { useNavigate } from 'react-router-dom';
import { toggleIsPinned } from '../../actions/adresseActions';


const AnnonceEnAttente = (props) => {
  const { user } = props.auth;
  const queryKey = ['adresses', user.id];
  const { data, error } = useQuery(queryKey, () => fetchData(props));
  const [pinnedAddresses, setPinnedAddresses] = useState({});
  const navigate = useNavigate();
  const [showAllAnnonces, setShowAllAnnonces] = useState(false);
  


  // Initialisez les annonces avec la propriété isPinned
  const annoncesData = data
    ? data.map((item, index) => ({
        id: index,
        titre: item.Location.Label,
        date: item.createdAt,
        data: item,
        isPinned: false, // Assurez-vous de définir la propriété isPinned
      }))
    : [];

  // Triez les annonces en fonction de isPinned puis par date
const annoncesEnAttente = annoncesData
.filter((annonce) => annonce.data.Verifie === 'En attente')
.sort((a, b) => {
  const isPinnedA = pinnedAddresses[a.data._id];
  const isPinnedB = pinnedAddresses[b.data._id];

  // Si l'annonce A est épinglée et l'annonce B ne l'est pas, A vient en premier
  if (isPinnedA && !isPinnedB) {
    return -1;
  } 
  // Si l'annonce B est épinglée et l'annonce A ne l'est pas, B vient en premier
  else if (!isPinnedA && isPinnedB) {
    return 1;
  } 
  // Si les deux annonces sont épinglées ou aucune ne l'est, triez par date
  else {
    return new Date(b.date) - new Date(a.date);
  }
});



// Mettre à jour l'état isPinned lorsque les données sont chargées
useEffect(() => {
  if (data) {
    const pinnedAds = {};
    data.forEach((item) => {
      pinnedAds[item._id] = item.isPinned; // Suppose que les données de l'API incluent un champ isPinned
    });
    setPinnedAddresses(pinnedAds);
  }
}, [data]);

const handleEdit = (id) => {
  // Fonction d'édition ici, vous pouvez utiliser `id` pour identifier l'annonce à éditer
  props.onEditClick(id);
};

const handlePin = async (adresseId) => {
  try {
    await props.toggleIsPinned(adresseId, !pinnedAddresses[adresseId]);
    setPinnedAddresses((prevAddresses) => ({
      ...prevAddresses,
      [adresseId]: !prevAddresses[adresseId],
    }));
  } catch (error) {
    console.error(error);
  }
};

const handleVoirToutesAnnonces = () => {
  setShowAllAnnonces(true);
};

if (error) {
  return <div>Error fetching data from API: {error.message}</div>;
}

const handleDelete = () => {
  // Fonction de suppression ici
};

  return (
    <>
      <div className="mesanonnance">
        <div className="r1 row">
          <div className="col-sm-6">
            <h2 className="MesAnnoncesText">Mes Annonces</h2>
          </div>
          <div className=" col-6 text-right">
            <label className="date ">
              <select name="date">
                <option value="13 sep 2023">13 sep 2023</option>
                <option value="14 sep 2023">14 sep 2023</option>
                <option value="15 sep 2023">15 sep 2023</option>
              </select>
            </label>
          </div>
        </div>
        {annoncesEnAttente.length > 0 ? (
          <div className="row">
            {showAllAnnonces ? (
              // Rendre la liste complète des annonces ici
              annoncesEnAttente.map((annonce) => (
                <Annonce
                  key={annonce.id}
                  titre={annonce.titre}
                  date={annonce.date}
                  onEdit={() => handleEdit(annonce.data._id)}
                  onPin={() => handlePin(annonce.data._id)}
                  onDelete={() => handleDelete(annonce.data._id)}
                  data={annonce.data}
                />
              ))
            ) : (
              // Rendre un nombre limité d'annonces
              annoncesEnAttente.slice(0, 3).map((annonce) => (
                <Annonce
                  key={annonce.id}
                  titre={annonce.titre}
                  date={annonce.date}
                  onEdit={() => handleEdit(annonce.data._id)}
                  onPin={() => handlePin(annonce.data._id)}
                  onDelete={() => handleDelete(annonce.data._id)}
                  data={annonce.data}
                />
              ))
            )}
          </div>
        ) : (
          // Afficher le message d'avertissement s'il n'y a pas de données
          <div className="affichee">
            ⚠️ Attention ! Aucune adresse. Ne manquez pas cette opportunité, procurez-vous la vôtre dès maintenant.
          </div>
        )}
        <div>
          {annoncesEnAttente.length > 3 && !showAllAnnonces && (
            <div className="row VoirTout">
              <hr className="separator"></hr>
              <div className="aff" onClick={handleVoirToutesAnnonces}>
                Voir toutes les annonces
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

AnnonceEnAttente.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  toggleIsPinned: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { toggleIsPinned })(AnnonceEnAttente);
