import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { refreshtochangestatus } from "../../actions/authActions";
import Header from "../../components/header/Header";
import axios from "axios";
import { useTranslation } from "react-i18next";
import fileValidation from "../../utils/fileValidation";
import Footer from "../../components/footer/Footer";

import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";

const DemandeAcheteur = (props) => {
  const { t } = useTranslation();
  const { user, isAuthenticated } = props.auth;
  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    address: "",
    numero: "",
    details: "",
    files: null, // Added files property to store uploaded files
  });
  const [error, setError] = useState(null);

  const [options, setOptions] = useState([]);
  const previousController = useRef();

  const getData = (searchTerm) => {
    if (searchTerm.length < 5) return [];
    if (previousController.current) {
      previousController.current.abort();
    }
    const controller = new AbortController();
    const signal = controller.signal;
    previousController.current = controller;
    axios(
      `${process.env.REACT_APP_API_URL}/api/locations/api-gouv/` + searchTerm,
      {
        signal,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
      .then(function (response) {
        console.log(response.data);
        const updatedOptions = response.data.map((p) => {
          return { label: p.label };
        });
        setOptions(updatedOptions);
        return response.data;
      })
      .catch(function (error) {
        if (axios.isCancel(error)) {
          // Handle canceled request
          console.log("Request canceled:", error.message);
        } else {
          // Handle other errors
          console.error("An error occurred:", error);
        }
      });
  };

  const onInputChange = (event, value, reason) => {
    if (value) {
      getData(value);
      setFormData({ ...formData, address: value });
    } else {
      setOptions([]);
    }
  };

  const onChange = (e) => {
    try {
      if (e.target.name === "file") {
        // If the changed field is "file", store the uploaded files
        setFormData({ ...formData, [e.target.name]: e.target.files[0] });
        document.getElementById("avatar").src = URL.createObjectURL(
          e.target.files[0]
        );
      } else {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
    } catch (error) {
      //delete the file if it's not valid
      setFormData({ ...formData, [e.target.name]: null });
      document.getElementById("avatar").src =
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJ4AAACYCAMAAAAiAqBUAAAAnFBMVEX///85wO3t7e3i4uL6+vry8vL29vbm5ubh4eHw8PD8/Pzp6enl5eX/5/L///yGwu2/5/2fwu3s0+5y0/eZzfBZwe3///SSwO05wPDT///z4/Oyz+95w+6Mz/LM4/js2vCm2vf/8fWsx+1Fx/Hf8f/Z+//Zzu5ZzvWy6P9swO3/2++S2/3M0/B/xe7Z1vCZ0/PMyu2/yO3z///l+//eYZL/AAADpUlEQVR4nO2baUPbMAyGE8fO4bhhVxjbYFDYyWDn//9vS9YyKNixHCmtPuj5HNlvZMuWrywTBEEQBEEQBEEQhL1jS6PayhX1QOGqVpnSHlrTFm2UK0ZB2v6TZK0exRZOGX1wbcqFZIyynTqgQmsq10zWrxtXmcM0s1a1KuOflcNn+3ehVkUDdIttij0LtIO4lO8HgXtsYpNc2/A/ZhktT9BtO6Ot5lmlY+qZfphtmIKqZjtBV4pSiQfbompQ7aIRol1SwD6lcQt2wBLfe0wNGMrnUVIUTVLIggUvpE9TFVvWC/Q/68hGLePo47dFxuxDmpaurA2KdESlLW1oj4q2vIp0ftPUvZm2wJZ8MjeE3Y+yrDvo/tgWCwxUuqAaXajjjLZU4H92b9+d9nme92fvQcVStYmCDMjd+W1+zwWk3IbEfboAfLS+zHe4+gAwInEfxHmro/wxz+JWFO6zdbznedRB9EGKjmHiv9g996jL849RQ4Uf+6poltd98qrLP0fLLtEzuXbRT1Zf/fLyL1FT9MIIEBgB50Hchw6O+P8d+3veyJuYLaBtsPbBtt1D6wLi9jqoLv8WNUbGLsD8RVjey6gx4PenADgfJQ/X+SxgvkXJy1BZXwlIk1F9L2sxa3tI10BFLi42IMaYcQ8ZGyDXI2YNWPcJAtqoRcy544YuQh5or2Z+xpKNO0sIecBF0Nx8L4MNXUFq2Gczs+WUKlC289YaSVWgbGet1NKqQNnOWOemVvGUlBkxdZdgAyo0FtgEfgRqYJl/fgYFNSyj8gkQqEktmhKsbm77baxe/A/W9fe7MOl/xCIYlRJEjB8PJ/2rkX53apsWiEqoJl3fnYczvYdMJqWo7jMV9t1EFr/LVE6P28KdWApd93FhUf8h1+HhrrH2pQEBToITMHIhGTTvfsLVDWH9O1AMchkedP76NEXeSShxxm5RhezBcbEhEB3YLaDQBlr3K01eoHXRG2iB/zu+jEvawZ/Z4+9l+Ddvj1+nqTvxLnnxm7eB2KWRR7D17d/dJ5FHcXDgDw6SvkdyauU/tEocWLxLcpozP6/7uiT3Xf3xFEFz5Bc4MO1uYOnUyJlPHdkhNu/jZu6H9cyvOnC/KML9mg3zS0rcr3hxvyDH/Xoh88uZ3K+2cr8YzP1aNfdL6dyv9GfMH0Rk3J+TcH+MkzF/ypRxfwiWMX9GN8L6EeII6yecm/oZP4C9l8H1+fAWxo+vBUEQBEEQBEEQBEEQBOb8Be9pJnKMeY/YAAAAAElFTkSuQmCC";
      console.log(error);
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    // Form validation
    const { nom, prenom, address, numero, file } = formData;

    const fields = {
      nom: { value: nom, minLength: 3 },
      prenom: { value: prenom, minLength: 3 },
      address: { value: address, minLength: 3 },
      numero: { value: numero, minLength: 8 },
    };

    for (const fieldName in fields) {
      const field = fields[fieldName];
      if (!field.value || field.value.length < field.minLength) {
        setError(
          `${fieldName} doit avoir au minimum ${field.minLength} caractères`
        );
        return;
      }
    }

    // validate the file
    let fileError = fileValidation(file, "photo");
    if (fileError !== true) {
      setError(fileError);
      return;
    }

    const demande = {
      Type: "Acheteur",
      Nom: nom,
      Prenom: prenom,
      Numero: numero,
      User: user.id,
      Adresse: address,
    };

    try {
      // Create a FormData object to send the data including the file
      const formData = new FormData();
      console.log(file);

      formData.append("avatar", file);
      Object.entries(demande).forEach(([key, value]) => {
        formData.append(key, value);
      });
      // Make API call to submit the form
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/profiles`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );
      console.log("Form submitted");
      refreshtochangestatus();
      console.log("Response:", response.data);
      setError(null);
    } catch (error) {
      // Handle error
      console.error("Error submitting form:", error);
      setError("Une erreur est survenue lors de la soumission du formulaire.");
    }
  };

  return (
    <div style={{ minHeight: "100vh", position: "relative" }}>
      <Header isLoggedIn={isAuthenticated} user={user} />
      <div className="demande">
        <div className="container">
          <form
            className="row g-3"
            style={{ paddingBottom: "50px", paddingTop: "50px" }}
          >
            <h1>{t("demande.create your buyer profile")}</h1>
            <label
              className="custom-file-upload mon-photo"
              style={{ width: "150px", height: "160px" }}
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAJ4AAACYCAMAAAAiAqBUAAAAnFBMVEX///85wO3t7e3i4uL6+vry8vL29vbm5ubh4eHw8PD8/Pzp6enl5eX/5/L///yGwu2/5/2fwu3s0+5y0/eZzfBZwe3///SSwO05wPDT///z4/Oyz+95w+6Mz/LM4/js2vCm2vf/8fWsx+1Fx/Hf8f/Z+//Zzu5ZzvWy6P9swO3/2++S2/3M0/B/xe7Z1vCZ0/PMyu2/yO3z///l+//eYZL/AAADpUlEQVR4nO2baUPbMAyGE8fO4bhhVxjbYFDYyWDn//9vS9YyKNixHCmtPuj5HNlvZMuWrywTBEEQBEEQBEEQhL1jS6PayhX1QOGqVpnSHlrTFm2UK0ZB2v6TZK0exRZOGX1wbcqFZIyynTqgQmsq10zWrxtXmcM0s1a1KuOflcNn+3ehVkUDdIttij0LtIO4lO8HgXtsYpNc2/A/ZhktT9BtO6Ot5lmlY+qZfphtmIKqZjtBV4pSiQfbompQ7aIRol1SwD6lcQt2wBLfe0wNGMrnUVIUTVLIggUvpE9TFVvWC/Q/68hGLePo47dFxuxDmpaurA2KdESlLW1oj4q2vIp0ftPUvZm2wJZ8MjeE3Y+yrDvo/tgWCwxUuqAaXajjjLZU4H92b9+d9nme92fvQcVStYmCDMjd+W1+zwWk3IbEfboAfLS+zHe4+gAwInEfxHmro/wxz+JWFO6zdbznedRB9EGKjmHiv9g996jL849RQ4Uf+6poltd98qrLP0fLLtEzuXbRT1Zf/fLyL1FT9MIIEBgB50Hchw6O+P8d+3veyJuYLaBtsPbBtt1D6wLi9jqoLv8WNUbGLsD8RVjey6gx4PenADgfJQ/X+SxgvkXJy1BZXwlIk1F9L2sxa3tI10BFLi42IMaYcQ8ZGyDXI2YNWPcJAtqoRcy544YuQh5or2Z+xpKNO0sIecBF0Nx8L4MNXUFq2Gczs+WUKlC289YaSVWgbGet1NKqQNnOWOemVvGUlBkxdZdgAyo0FtgEfgRqYJl/fgYFNSyj8gkQqEktmhKsbm77baxe/A/W9fe7MOl/xCIYlRJEjB8PJ/2rkX53apsWiEqoJl3fnYczvYdMJqWo7jMV9t1EFr/LVE6P28KdWApd93FhUf8h1+HhrrH2pQEBToITMHIhGTTvfsLVDWH9O1AMchkedP76NEXeSShxxm5RhezBcbEhEB3YLaDQBlr3K01eoHXRG2iB/zu+jEvawZ/Z4+9l+Ddvj1+nqTvxLnnxm7eB2KWRR7D17d/dJ5FHcXDgDw6SvkdyauU/tEocWLxLcpozP6/7uiT3Xf3xFEFz5Bc4MO1uYOnUyJlPHdkhNu/jZu6H9cyvOnC/KML9mg3zS0rcr3hxvyDH/Xoh88uZ3K+2cr8YzP1aNfdL6dyv9GfMH0Rk3J+TcH+MkzF/ypRxfwiWMX9GN8L6EeII6yecm/oZP4C9l8H1+fAWxo+vBUEQBEEQBEEQBEEQBOb8Be9pJnKMeY/YAAAAAElFTkSuQmCC"
                className="rounded-circle "
                style={{ width: "130px", height: "130px" }}
                alt="Avatar"
                id="avatar"
              />
              <input
                type="file"
                name="file"
                id="avatar-input"
                accept="image/*"
                onChange={onChange}
              />
              <div className="d-flex justify-content-center">
                {t("demande.customupload")}
              </div>
            </label>
            <hr style={{ color: "white" }} />
            <div className="col-md-6">
              <input
                onChange={onChange}
                id="nom"
                type="text"
                name="nom"
                className="inputs"
                placeholder={t("demande.name")}
              />
            </div>
            <div className="col-md-6 ">
              <input
                type="text"
                className="inputs"
                id="prenom"
                name="prenom"
                onChange={onChange}
                placeholder={t("demande.first name")}
              />
            </div>
            <div className="col-md-6">
              <Autocomplete
                id="combo-box-demo"
                options={options}
                onInputChange={onInputChange}
                getOptionLabel={(option) => option?.label || ""}
                style={{ width: 300 }}
                value={formData.Rue} // Set the value directly from formValues
                onChange={(event, newValue) => {
                  setFormData((prevValues) => ({
                    ...prevValues,
                    Rue: newValue,
                  }));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="address"
                    id="address"
                    name="address"
                    onChange={onChange}
                    placeholder="50 Rue Lecourbe 75015 Paris"
                  />
                )}
              />
            </div>
            <div className="col-md-1 form-suffix">
              <select className="form-control selects" name="suffix">
                <option>+33</option>
                <option>+34</option>
              </select>
            </div>
            <div className="col-md-5">
              <input
                type="text"
                onChange={onChange}
                className="inputs"
                id="numero"
                name="numero"
                placeholder={t("demande.phonenumber")}
              />
            </div>

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
            <div className="col-12 d-flex justify-content-center">
              <button
                type="button"
                className="mon-boutonVendeur"
                onClick={onSubmit}
              >
                Envoyer
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
};

DemandeAcheteur.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(DemandeAcheteur);
