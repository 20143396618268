import React ,{useEffect} from "react";
import Header from "../../../../../components/header/Header";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SideBar from "../../../../../components/SideBars/AdresseSideBar/SideBar";
import "./ModifAdresse.css";
import axios from "axios";
import "../../../Espace.css"
import GeneralInfo from "../../../../../components/EspaceVendeur/AjouterAdresse/GeneralInfo";
import Location from "../../../../../components/EspaceVendeur/AjouterAdresse/Location";
import Justificatifs from "../../../../../components/EspaceVendeur/AjouterAdresse/Justificatifs";
import Offres from "../../../../../components/EspaceVendeur/AjouterAdresse/Offres";
import Prix from "../../../../../components/EspaceVendeur/AjouterAdresse/Prix";
import { useQuery } from "react-query";
  
  
const ModifAdresse = (props) => {
  const { user, isAuthenticated } = props.auth;

  const queryKey = "adresseData";


  const fetchData = async () => {
    try {
      const adresseId = props.match.params.id;
      const response = await axios.get(process.env.REACT_APP_API_URL + "/api/adresses/" + adresseId , {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      });

      // Handle the response data as needed
      const adresseToEdit = response.data;
      if (adresseToEdit.PubliePar._id!==user.Profiles.Vendeur) {
        props.history.push("/espace-vendeur");
      }
      console.log(adresseToEdit);

      return{  
        GeneralInfo: {
          APropos: adresseToEdit.APropos,
          ConditionDAcces: adresseToEdit.ConditionDAcces,
        },
        Location: {
          Ville: adresseToEdit.Location.Ville,
          CodePostal: adresseToEdit.Location.CodePostal,
          Rue: adresseToEdit.Location.Rue,
          Numero: adresseToEdit.Location.Numero,
        },
        Justificatifs: {
          Docs: adresseToEdit.Docs,
          Images: adresseToEdit.Images,
        },
        OffresCourrier: {
          Business: adresseToEdit.OffresCourrier.Business.Prix,
          Premuim: adresseToEdit.OffresCourrier.Premium.Prix,
          Starter: adresseToEdit.OffresCourrier.Starter.Prix,
          PrixParCourrier: adresseToEdit.OffresCourrier.PrixParCourrier
        },
        Prix: {
          PrixAE: adresseToEdit.PrixAE.Prix,
          PrixEntreprise: adresseToEdit.PrixEntreprise.Prix,
          PrixSCI: adresseToEdit.PrixSCI.Prix,
        }
      };
    
      
      // Your logic to handle adresseToEdit goes here
    } catch (error) {
      // Handle errors here
      props.history.push("/espace-vendeur");
      console.error("An error occurred:", error);
    }
  };

   // Use the useQuery hook
   const { data: adresseToModify, isLoading, isError } = useQuery(queryKey, fetchData);

   useEffect(() => {
    document.title = "Modifier une adresse";
  }, []);

  

  return (
    <div className="espace-container">
      <Header isLoggedIn={isAuthenticated} user={user}/>
        <div className="espace-vendeur">
        <SideBar className="side-bar"/>
        {isLoading ? (
            <p>Loading...</p>
          ) : isError ? (
            <p>Error occurred while fetching data.</p>
          ) : (
        
        <div className="add-adresse"  id="general-info">
        <div className="container field active" id="GeneralInfo" name="field" style={{display: "block"}}>
          <GeneralInfo GeneralInfo={adresseToModify.GeneralInfo}/>
        </div>
        <div className="container field" id="Location" style={{display:"none"}} name="field">
          <Location Location={adresseToModify.Location} />
        </div>
        <div className="container field" id="Justificatifs" style={{display:"none"}} name="field">
          <Justificatifs Justificatifs={adresseToModify.Justificatifs} />
        </div>
        <div className="container field" id="Offres" style={{display:"none"}} name="field">
          <Offres  OffresCourrier={adresseToModify.OffresCourrier} />
        </div>   
        <div className="container field" id="Prix" style={{display:"none"}} name="field">
          <Prix Prix={adresseToModify.Prix} />
        </div>   
        
        

        </div>
          )}
      </div>
    </div>
  );
};

ModifAdresse.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = (state) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(ModifAdresse);
