import React from "react";
import "./Section4.css";
const Section4 = (props) => {
  return (
    <div className="section4">
      <div className="container">
        <div className="container-fluid">
          {
            //first row
          }
          <div className="row my-0">
            <div className="col-12 col-md-4 col-lg-4"></div>
            <div className="col-12 col-md-4 col-lg-4 text-center">
              <span className="spanlabelrecd">Recommandée</span>
            </div>
            <div className="col-12 col-md-4 col-lg-4"></div>
          </div>

          {
            //second row
            //first
          }

          <div className="row justify-content-center">
            <div className="col-12 col-md-4 col-lg-4 my-1 px-1 hovered-subscription">
              <div
                className="col-12 col-md-12"
                style={{ maxWidth: "90%", backgroundColor: "#FFFFFF" }}
              >
                <div className="section4-text" style={{ maxWidth: "100%" }}>
                  <h3 className="section4-h3">À la carte </h3>
                  <p className="section4-p">
                    Obtenez un ensemble de réexpédition depuis votre espace
                    client.
                  </p>
                  <p className="">
                    <h3 className="section4-prix">Dès 2,50€</h3>
                  </p>
                </div>
              </div>
            </div>

            {
              //seconde
            }

            <div className="col-12 col-md-4 col-lg-4 my-1 px-1 hovered-subscription">
              <div
                className="col-12 col-md-12"
                style={{ maxWidth: "100%", backgroundColor: "#339b4a0d" }}
              >
                <div className="section4-text" style={{ maxWidth: "100%" }}>
                  <h3 className="section4-h3">Abonnement Business </h3>
                  <p className="section4-p">
                    Chaque mois, votre courrier est réexpédié à votre adresse de
                    destination.
                  </p>
                  <p className="">
                    <h3 className="section4-prix">+10€</h3>
                  </p>
                </div>
              </div>
            </div>

            {
              //third
            }

            <div className="col-12 col-md-4 col-lg-4 my-1 px-1 hovered-subscription">
              <div
                className="col-12 col-md-12 "
                style={{ maxWidth: "100%", backgroundColor: "#00a4b80d" }}
              >
                <div className="section4-text" style={{ maxWidth: "100%" }}>
                  <h3 className="section4-h3">Abonnement Premium </h3>
                  <p className="section4-p">
                    Nous réexpédions votre courrier chaque jour vers l'adresse
                    de votre préférence.
                  </p>
                  <p className="">
                    <div className="section4-prix">+40€</div>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Section4;
