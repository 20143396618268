import React, { useState } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

const Justificatifs = (props) => {
  const { user } = props.auth;
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    images: null,
    docs: null,
  });

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files,
    }));
  };

  const handleSubmit = async () => {
    const adresse = JSON.parse(localStorage.getItem("adresse"));
    adresse.OffresCourrier = JSON.stringify(adresse.OffresCourrier);
    adresse.Location = JSON.stringify(adresse.Location);
    const form = new FormData();
    form.append("PubliePar", user.Profiles.Type);

    Object.keys(adresse).forEach((key) => {
      form.append(key, adresse[key]);
    });
    Array.from(formData.images).forEach((image) => {
      form.append("images", image);
    });
    Array.from(formData.docs).forEach((doc) => {
      form.append("docs", doc);
    });

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/adresses`,
        form,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      if (response.status !== 201) {
        // Handle error
        console.log(response.data);
        return;
      }
      //redirect to home page
      props.history.push("/espace-vendeur/mes-adresses");

      // Handle response or success message
      console.log(response.data);

      // Clear data from local storage after submitting
      localStorage.removeItem("address");
    } catch (error) {
      // Handle error
    }
  };

  return (
    <form className="row g-3">
      <h2 className="text-center">Justificatifs</h2>
      <hr style={{ color: "white" }} />

      <div className="col-md-12">
        <label className="prix-label">Images :</label>
        <input
          type="file"
          id="images"
          name="images"
          multiple
          accept=".jpg, .png, .pdf, .jpeg, .webp"
          className="text-area"
          onChange={handleFileChange}
        />
      </div>

      <div className="col-md-12">
        <label className="prix-label">Docs :</label>
        <input
          type="file"
          id="docs"
          name="docs"
          multiple
          accept=".jpg, .png, .pdf"
          className="text-area"
          onChange={handleFileChange}
        />
      </div>

      <div className="col-12 d-flex justify-content-center">
        <button
          type="button"
          className="mon-boutonVendeur"
          onClick={handleSubmit}
        >
          {t("demande.send")}
        </button>
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Justificatifs);
