import React, { useEffect, useState, useRef } from "react";
import "./BandeBleu.css";

const Counter = ({ startValue, endValue, duration }) => {
  const [count, setCount] = useState(startValue);
  const incrementRef = useRef(null);

  useEffect(() => {
    let start = startValue;
    const increment = Math.ceil((endValue - startValue) / 100);
    const incrementTime = Math.floor(
      duration / ((endValue - startValue) / increment)
    );

    const timer = setInterval(() => {
      start += increment;
      setCount((prevCount) => {
        if (start >= endValue) {
          clearInterval(timer);
          return endValue;
        }
        return prevCount + increment;
      });
    }, incrementTime);

    incrementRef.current = timer;
    return () => clearInterval(incrementRef.current);
  }, [startValue, endValue, duration]);

  return (
    <span>
      {count.toLocaleString("en-EN", { maximumSignificantDigits: 3 })}
    </span>
  );
};

const BandeBleu = (props) => {
  return (
    <div className="container-fluid p-0 mt-5">
      <div className="bond-blue py-4">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12 col-lg-4 py-5">
              <div className="text-counter">
                <Counter startValue={1} endValue={56} duration={3000} />k
              </div>
              <div className="text-adresse">adresses en Île-de-France</div>
              <div className="text-desc">
                Bénéficiez des meilleures <br /> adresses de domiciliation à
                <br />
                Paris et en Île-de-France
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4 py-5">
              <div className="bande-element">
                <div className="text-counter">
                  <Counter startValue={1} endValue={25} duration={3000} />
                  K+
                </div>
                <div className="text-adresse">
                  villes <br />
                  disponibles
                </div>
                <div className="text-desc">
                  Profitez de plus de 55 villes <br /> pour domicilier votre
                  entreprise
                </div>
              </div>
            </div>

            <div className="col-12 col-md-12 col-lg-4 py-5">
              <div className="text-counter">
                <Counter startValue={0} endValue={2918} duration={3000} />
              </div>
              <div className="text-adresse">adresses en Île-de-France</div>
              <div className="text-desc">
                Installez votre siège social <br /> partout en Île-de-France
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default BandeBleu;
