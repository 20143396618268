import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Icon from "react-icons-kit";
import axios from "axios";
import { connect } from "react-redux";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import "../Auth.css";
import "./ResetPassword.css";
import { useParams } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { arrows_exclamation } from "react-icons-kit/linea/arrows_exclamation";
import { arrows_circle_check } from "react-icons-kit/linea/arrows_circle_check";

const ResetPassword = (props) => {
  const { code } = useParams();
  const [Password, setPassword] = useState("");
  const [validationResults, setValidationResults] = useState({
    lower: false,
    upper: false,
    number: false,
    length: false,
  });
  const [ErrorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    if (props.auth.isAuthenticated) {
      window.location.href = "/mon-compte";
    }
    const tokenDecoded = jwt_decode(code);
    const expiry_time = tokenDecoded.expiry_time; // Get token expiry time

    const currentTimestamp = new Date();
    const tokenExpiryTimestamp = new Date(expiry_time * 1000); // Convert seconds to milliseconds

    // Check if the token has expired
    if (currentTimestamp >= tokenExpiryTimestamp) {
      setErrorMessage("Le lien a été expiré");
    }
  }, [props.auth, code]);

  const handleValidation = (value) => {
    const lower = /(?=.*[a-z])/;
    const upper = /(?=.*[A-Z])/;
    const number = /(?=.*\d)/;
    const length = /(?=.{8,})/;

    setValidationResults({
      lower: lower.test(value),
      upper: upper.test(value),
      number: number.test(value),
      length: length.test(value),
    });

    setPassword(value);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Validate password
    if (!validationResults.length) {
      alert("Password must be at least 8 characters");
      return;
    }

    // Define the data to be sent in the POST request
    const data = {
      Password,
      token: code,
    };

    try {
      // Make the POST request using Axios
      const response = await axios.post(
        process.env.REACT_APP_API_URL + "/api/auth/reset-password",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      if (response.status === 200) {
        alert(response.data.message);
        window.location.href = "/login";
      } else {
        alert(response.data.error);
      }

      // Handle the response (e.g., show a success message)
      console.log("POST request successful", response.data);
    } catch (error) {
      // Handle errors (e.g., show an error message)
      console.error("Error making POST request", error);
      alert("Error changing password");
    }
  };

  return (
    <div>
      <div className="overbgimg "></div>
      <Header />
      <div className="forgot-ground">
        <div
          style={{ marginTop: "4rem", width: "90%" }}
          className="row login-div"
        >
          <div className="col-md-5 offset-md-2">
            {ErrorMessage ? (
              <>
                <div className="alert alert-danger " role="alert">
                  {ErrorMessage}
                </div>
                <button
                  className="btn button"
                  onClick={() =>
                    (window.location.href = "/reinitialisation-mot-de-passe")
                  }
                >
                  Reenvoyer le code
                </button>
              </>
            ) : (
              <>
                <h1 className="title">Saisissez votre nouveau mot de passe</h1>
                <form noValidate onSubmit={handleFormSubmit}>
                  <div className="inline-container"></div>
                  <input
                    id="password"
                    type="password"
                    className="login-input"
                    value={Password}
                    onChange={(e) => handleValidation(e.target.value)}
                    placeholder="Veuillez entrer votre nouveau mot de passe"
                  />
                  {/* Validation tracker */}
                  <main
                    className="tracker-box"
                    style={{ padding: "0px 5px 10px 30px" }}
                  >
                    {renderValidationResult(
                      "lower",
                      "At least one lowercase letter"
                    )}
                    {renderValidationResult(
                      "upper",
                      "At least one uppercase letter"
                    )}
                    {renderValidationResult("number", "At least one number")}
                    {renderValidationResult("length", "At least 8 characters")}
                  </main>
                  <div className="d-grid gap-2">
                    <button type="submit" className="btn button">
                      Envoyer
                    </button>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );

  function renderValidationResult(validationType, message) {
    return (
      <div
        className={
          validationResults[validationType] ? "validated" : "not-validated"
        }
      >
        {validationResults[validationType] ? (
          <span className="list-icon ">
            <Icon icon={arrows_circle_check} />
          </span>
        ) : (
          <span className="list-icon">
            <Icon icon={arrows_exclamation} />
          </span>
        )}
        {message}
      </div>
    );
  }
};

ResetPassword.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(ResetPassword);
