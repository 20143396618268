import React, { useState } from 'react'
import { BiHome, BiBookAlt } from 'react-icons/bi'
import { MdDashboard } from "react-icons/md";
import { IoPersonAdd } from "react-icons/io5";
import { FaCalendarDay } from "react-icons/fa6";
import { TbBrandTelegram, TbTypography } from "react-icons/tb";
import { BsHeadset } from "react-icons/bs";
import { IoMdSettings } from "react-icons/io";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./sidebar.css"
import { FaBars } from "react-icons/fa6";
import { connect } from "react-redux";
import {  FaTimes } from 'react-icons/fa';
import logo from "../header/logo.png";


const Sidebar1 = () => {
  
  return (
    <div className=''>
      <div className='row'>
      
        <div className='  sidebar'>
        
          <div className='sidebar-sticky'>
            
            
            <div className=' navbar-collapse ' id='sidebarCollapse'>
              <div className='menu--list menu1 nav flex-column '>
                
              
              <Link className="navbar-brand" to="/home">
              <img
                src={logo}
                alt="logo"
                className="logo-img1"
                width="58"
                height="63"
                
              />
            </Link>
                <div className="frame87">
                  
                
              
              
                  <Link to="/dashboard/dashboardAccueil" className='item  active'>
                    <div className='iconDash'></div>
                    
                  </Link>
                  <Link to="/dashboard/CompteDash" className='item  active'>
                    <div className='iconAjoute'></div>
                    
                  </Link>
                  <div className='sideBar3'>
                  <Link to="/espace-vendeur/EditeProfile" className='item  active'>
                    
                    <div className='EditIcon '></div>
                    
                  </Link>
                  <Link to="/espace-vendeur/Notification" className='item  active'>
                    <div className='NotificationsIcon'></div>
                    
                  </Link>
                  </div>
                  <Link to="/dashboard/AnnonceDash" className='item  active'>
                    <div className='iconCalendrier'></div>
                    
                  </Link>
                  <Link to="/dashboard/DomicilezDash" className='item  active'>
                    <div className='iconDomicil'></div>
                    
                  </Link>
                </div>
                
              </div>
              
            </div>
          </div>
        </div>
        
        <div className=''>
          <div className='EditSidebar'>
                 <div className='sideBar2'>
                  <Link to="/espace-vendeur/EditeProfile" className='item  active'>
                    <div className='Vector16 '></div>
                    <div className='EditIcon '></div>
                    <div className='Edittexts'>Editer le profil</div>
                  </Link>
                  <Link to="/espace-vendeur/Notification" className='item  active'>
                    <div className='NotificationsIcon'></div>
                    <div className='Notiftexts'>Notification</div>
                  </Link>
                  </div>
                  
          </div>
        </div>
      </div>
    </div> 
  );
};

export default Sidebar1;

   