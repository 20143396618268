import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { changeField } from "../../utils/changeFieldPageVendeur";
import { useNavigate } from 'react-router-dom';
import { refreshtochangestatus } from "../../actions/authActions";


const apiUrl = process.env.REACT_APP_API_URL;

const CreateAnnoncePage2 = (props) => {
    const { user } = props.auth;
    const { t } = useTranslation();
    const navigate = useNavigate();
  
    const [formValues, setFormValues] = useState({
      SIRET:props.data.SIRET,
      CIN:props.data.CIN,
      
    });
    const [formData, setFormData] = useState({
      
      docs: props.data.Docs,
      images: props.data.Images,
    });
    
    const [errors, setErrors] = useState({
      CIN: '',
      SIRET: '',
    });


  
    useEffect(() => {  
      if (props.CreateAnnoncePage2) {
        setFormValues({
          CIN: props.CreateAnnoncePage2.CIN,
          SIRET: props.CreateAnnoncePage2.SIRET,
        });
    
        // Initialiser les erreurs avec les mêmes conditions
        setErrors({
          CIN: props.CreateAnnoncePage2.CIN.length !== 8 ? 'CIN doit avoir la longueur requise' : '',
          SIRET: props.CreateAnnoncePage2.SIRET.length !== 14 ? 'SIRET doit avoir la longueur requise' : '',
        });
      }
    }, [props.CreateAnnoncePage2]);
  
   
    const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Validate CIN and SIRET lengths
    if ((name === 'CIN' && value.length !== 8) || (name === 'SIRET' && value.length !== 14)) {
      setErrors({ ...errors, [name]: `${name} must have the required length` });
    } else {
      setErrors({ ...errors, [name]: '' });
    }

    setFormValues({ ...formValues, [name]: value });
  };




    const handleSubmit = async () => {
      let adresse = JSON.parse(localStorage.getItem("adresse"));
      adresse = { ...adresse, ...formValues };
      localStorage.setItem("adresse", JSON.stringify(adresse));

      adresse.Location = JSON.stringify(adresse.Location);

      adresse.OffresCourrier = JSON.stringify(adresse.OffresCourrier);
      const form = new FormData();
      form.append("PubliePar", user.Profiles.Type);
  
      Object.keys(adresse).forEach((key) => {
        form.append(key, adresse[key]);
      });
      Array.from(formData.docs).forEach((doc) => {
        form.append("docs", doc);
      });
      Array.from(formData.images).forEach((image) => {
        form.append("images", image);
      });
     
      
      const CIN1=formValues.CIN.toString()
      const SIRET1=formValues.SIRET.toString()
      
      
  
      
      
      
      try {
        
             const response = await axios.put(apiUrl + `/api/adresses/${props.itemId}`
             ,form ,
        {
          headers: {
            "Content-Type": "multipart/form-data",
             Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        });
  
        if (response.status !== 200) {
          // Handle error
          console.log(response.data);
          
          return;
        }




       // Check if there are any errors
    const errorFields = {};

    // Validate CIN and SIRET lengths
    if (CIN1.length !== 8) {
      errorFields['CIN'] = 'CIN doit avoir la longueur requise';
    }

    if (SIRET1.length !== 14) {
      errorFields['SIRET'] = 'SIRET doit avoir la longueur requise';
    }

    // Validate if docs are uploaded
    if (formData.docs.length == 0) {
      errorFields['docs'] = 'Docs sont obligatoires';
    }

    setErrors(errorFields);

    // If there are errors, return without proceeding
    if (Object.keys(errorFields).length > 0) {
      return;
    }

    // If there are no errors, proceed with your logic (e.g., submit the form)
   

    navigate('/dashboard/dashboardAccueil');
       

        
  
        // Handle response or success message
        console.log(response.data);
  
        // Clear data from local storage after submitting
        localStorage.removeItem("address");
      } catch (error) {
        // Handle error
      }
    }
    /*const handleFileChange = (e) => {
      const file = e.target.files[0];
  
      // Validate if a file is selected
      if (!file) {
        setErrors({ ...errors, docs: 'Docs are mandatory' });
      } else {
        setErrors({ ...errors, docs: '' });
      }
  
      setFormValues({ ...formValues, docs: file });
    };*/
    const handleFileChange = (e) => {
      const { name, files } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: files,
      }));
    };
    
    return (
      
        <div  style={{ marginTop: "1.5rem", width: "100%" }} className="row ">
          <div className=" col-md-11 ">
            <form enctype="multipart/form-data"  >
              <div className="row">
                <div className="CrAnAtt">CIN</div>
                <div >
                <input
  onChange={handleInputChange}
  type="number"
  label="CIN"
  id="CIN"
  name="CIN"
  value={formValues.CIN}
  className="CreateAnnonce-input"
  placeholder="CIN"
/>

                  
                  
                 {errors?.CIN && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "100%", marginTop: "5px"}}
              role="alert"
            >
              {errors.CIN}
            </div>
          )}
                </div>  
                </div>
                <div className="row">
              <div className="CrAnAtt">SIRET</div>
               <div className="col-12"> 
               <input
               id="SIRET"
               name="SIRET"
               type="number"
               placeholder="SIRET"
               className="CreateAnnonce-input "
               value={formValues.SIRET}
               onChange={handleInputChange}
               
              />
               {errors?.SIRET && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "100%", marginTop: "5px"}}
              role="alert"
            >
              {errors.SIRET}
            </div>
          )}
                  </div>
              </div>
              <div className="  " enctype="multipart/form-data" >
        <div className="CrAnAtt">Images </div>
        <div>
        <input
          type="file"
          id="images"
          name="images"
          multiple
          accept=".jpg, .png, .pdf, .jpeg, .webp"
          className="CreateAnnonce-input "
          onChange={handleFileChange}
          
        />
         {props.data === null
                ? ""
                : props.data.Images.map((image) => {
                    return (
                      <a
                        key={1}
                        
                        id="message-text"
                        rel="noreferrer"
                        target="_blank"
                        href={
                          props.data === null
                            ? ""
                            : `http://localhost:5000/api/adresseImages/${image._id}`
                        }
                      >
                        {image.Url}{" "}
                      </a>
                    );
                  })}
             

        {errors?.images && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "100%", marginTop: "5px"}}
              role="alert"
            >
              {errors.images}
            </div>
          )}
                    
        </div>
      </div>
             
      <div className="  " enctype="multipart/form-data">
        <div className="CrAnAtt">Docs</div>
        <div>
        <input
          type="file"
          id="docs"
          name="docs"
          multiple
          accept=".jpg, .png, .pdf, .jpeg, .webp"
          className="CreateAnnonce-input "
          onChange={handleFileChange}
        />
        {props.data === null
                ? ""
                : props.data.Docs.map((doc) => {
                    return (
                      <a
                        key={2}
                        className="form-control"
                        id="message-text"
                        rel="noreferrer"
                        target="_blank"
                        href={
                          props.data === null
                            ? ""
                            : `http://localhost:5000/api/adresseDocs/${doc._id}`
                        }
                      >
                        {doc.Url}{" "}
                      </a>
                    );
                  })}
        {errors?.docs && (
            <div
              className="alert alert-danger"
              style={{ marginLeft: "30px", width: "100%", marginTop: "5px"}}
              role="alert"
            >
              {errors.docs}
            </div>
          )}
        </div>
        </div>
      <div className="">
        <button
          type="button"
          className="btn button creeAnnonceBtn"
          onClick={handleSubmit}
          
        >
          {t("demande.send")}
        </button>
       </div>
       
       
              
            </form>
          </div>
          
        </div>
        
      );
    
      
    };


  const mapStateToProps = (state) => ({
    auth: state.auth,
  });

  export default connect(mapStateToProps)(CreateAnnoncePage2);