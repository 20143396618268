import React from "react";
import { connect } from "react-redux";
import "../SideBar.css";
import "./AdresseSideBar.css";
import { changeField } from "../../../utils/changeFieldPageVendeur";

const SideBar = (props) => {
  const { user } = props.auth;

  

  const changeActive = (e) => {
    changeField(e.target.value);
    const active = document.querySelector(".nav-link.active");
    active.classList.remove("active");
    e.target.classList.add("active");
  };

  return (
    <div className="sidebar-adresse-container d-flex flex-column flex-shrink-0 p-3 ">
      <div className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none">
        <svg className="bi pe-none me-2" width="40" height="32"></svg>
        <span className="fs-4" style={{color:"black"}}>Ajouter votre Adresse</span>
      </div>
      <hr />
      <ul className="nav nav-pills flex-column mb-auto">
        <li className="nav-item">
          <button onClick={(e)=> changeActive(e)} id="GeneralInfo-nav-link" value={'GeneralInfo'} className="nav-link active"  aria-current="page" >
            <svg className="bi pe-none me-2" width="16" height="16"></svg>
            Informations Générale
          </button>
        </li>
        <li>
          <button onClick={(e)=> changeActive(e)} id="Location-nav-link" value={'Location'} className="nav-link " >
            <svg className="bi pe-none me-2" width="16" height="16"></svg>
            Localisation
          </button>
        </li>
        <li>
          <button onClick={(e)=> changeActive(e)} id="Prix-nav-link" value={'Prix'} className="nav-link">
            <svg className="bi pe-none me-2" width="16" height="16"></svg>
            Prix
          </button>
        </li>
        <li>
          <button onClick={(e)=> changeActive(e)} id="Offres-nav-link" value={'Offres'} className="nav-link ">
            <svg className="bi pe-none me-2" width="16" height="16"></svg>
            Offres Courriers
          </button>
        </li>
        <li>
          <button onClick={(e)=> changeActive(e)} id="Justificatifs-nav-link" value={'Justificatifs'} className="nav-link">
            <svg className="bi pe-none me-2" width="16" height="16"></svg>
            Justificatifs
          </button>
        </li>
      </ul>


      <hr />
      <div className="dropdown">
        <button
          className="d-flex align-items-center text-black text-decoration-none dropdown-toggle"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <img src="https://github.com/mdo.png" alt="" width="32" height="32" className="rounded-circle me-2" />
          <strong>{user.Email}</strong>
        </button>
        <ul className="dropdown-menu dropdown-menu-dark text-small shadow">
          <li>
            <a className="dropdown-item" href="/espace-vendeur/ajouter-une-adresse">
              Ajouter une adresse
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="/espace-vendeur/mon-profile">
              Profile
            </a>
          </li>
          <li>
            <a className="dropdown-item" href="/help">
              Help Center
            </a>
          </li>
          <li>
            <hr className="dropdown-divider" />
          </li>
          <li>
            <a className="dropdown-item" href="/mon-compte">
              Sign out
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(SideBar);
