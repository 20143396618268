import React, { useState } from "react";
import "./Questions.css";

import { useTranslation } from "react-i18next";

import iconclose from "./images/plus.png";
import iconopen from "./images/fois.png";

const Questions = () => {
  const { t } = useTranslation();
  const [expandedIndex, setExpandedIndex] = useState(-1);
  const handleToggle = (index) => {
    if (expandedIndex === index) {
      setExpandedIndex(-1);
    } else {
      setExpandedIndex(index);
    }
  };

  return (
    <div className="container-fluid mt-5">
      <div className="overbgimg "></div>
      <div className="container">
        <div className="faq-wrapper text-center ">
          <h3 className="faq-heading pt-5">Questions fréquentes</h3>
          <div className="faq-list">
            {t("home.questions", { returnObjects: true }).map(
              (question, index) => (
                <div key={{ index }} className="faq-item">
                  {" "}
                  {/* Ajout de la marge en bas */}
                  <div
                    className="faq-question d-flex flex-row justify-content-between"
                    onClick={() => handleToggle(index)}
                    onKeyDown={(event) => {
                      if (event.key === "Enter" || event.key === " ") {
                        handleToggle(index);
                      }
                    }}
                    tabIndex={0}
                  >
                    <span>{question}</span>
                    {expandedIndex === index ? (
                      <img
                        src={iconopen}
                        alt={t("home.icon open")}
                        className="faq-icon rounded-circle bg-warning"
                      />
                    ) : (
                      <img
                        src={iconclose}
                        alt={t("home.icon close")}
                        className="faq-icon rounded-circle bg-warning"
                      />
                    )}
                  </div>
                  {expandedIndex === index && (
                    <div className="faq-answer">
                      <p>{t("home.answers", { returnObjects: true })[index]}</p>
                    </div>
                  )}
                  {/*<div className="faq-divider"></div> */}
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Questions;
