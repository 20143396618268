import React, { useEffect } from "react";

import Slidertarifs from "../../components/Tarifs/slider/Slidertarifs";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import tarif from "./images/tarif.png";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./Tarifs.css";
import Section2 from "../../components/Tarifs/Section2/Section2";
import Section1 from "../../components/Tarifs/Section1/Section1";
import Section4 from "../../components/Tarifs/Section4/Section4";
import Questions from "../../components/Questions/Questions";

const Tarifs = (props) => {
  const { user, isAuthenticated } = props.auth;

  useEffect(() => {
    document.title = "Domicil Pro - Tarifs";
    document.getElementById("tarifs").classList.add("active");
  }, []);

  return (
    <div>
      <Header isLoggedIn={isAuthenticated} user={user} />

      <div className=" body-container ">
        <div className="container ">
          <div className="row align-items-center">
            <div className=" col-lg-6 col-12 col-md-12  text-center text-lg-start">
              <h1 className="display-1 mt-5 pt-lg-6 title-1Home-h1">
                Trouvez les meilleurs tarifs de domiciliation chez Domicil'Pro
              </h1>
              <p className="my-4 text-1Homeperso">
                Que vous soyez une petite start-up ou une grande entreprise,
                nous avons des forfaits de domiciliation flexibles qui
                répondront à vos exigences. Avec Domicil'Pro, vous bénéficierez
                non seulement de tarifs avantageux, mais aussi de services de
                haute qualité.
              </p>
            </div>
            <div className="col-lg-6 col-12 col-md-12 grp-img text-center mt-5">
              <img src={tarif} alt="imgGrp" className="imgGrp" />
            </div>
          </div>
        </div>
      </div>

      <section
        className="divbgpatern"
        style={{ marginTop: "100px", marginBottom: "100px" }}
      >
        <div className="container">
          <div className="container-fluid">
            <h2 className="text-center titleh2-tarifs">
              Tarifs de domiciliation
            </h2>
            <Section1 />
          </div>
        </div>
      </section>

      <section style={{ marginTop: "100px", marginBottom: "100px" }}>
        <Section2 />
      </section>

      <section style={{ marginTop: "100px", marginBottom: "100px" }}>
        <div className="container">
          <div className="container-fluid">
            <h2 className="text-center slidercarousel-h2">
              Comment fonctionne le courrier ?
            </h2>
            <Slidertarifs />
          </div>
        </div>
      </section>

      <section style={{ marginTop: "100px", marginBottom: "100px" }}>
        <div className="container">
          <div className="container-fluid">
            <h2 className="text-center carouselservice-h2">
              Préférez-vous une réception physique de
              <br />
              votre courrier ?
            </h2>
            <Section4 />
          </div>
        </div>
      </section>

      <Questions />

      <Footer />
    </div>
  );
};

Tarifs.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(Tarifs);
