import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser } from "../../../../actions/authActions";
import Header from "../../../../components/header/Header";
import { useQuery } from "react-query"; // Import the useQuery hook from React Query
import Popup from "./pop-up/pop-up";
import AdminSideBar from "../../../../components/EspaceAdmin/AdminSideBar";
import { fetchData } from "./ProfilesListFunctions";
import { useState } from "react";
import DateTimeConvertion from "../../../../utils/DateTimeConvertion";

const ProfilesList = (props) => {
  const { user, isAuthenticated } = props.auth;
  // Create a unique query key based on the user's ID
  const queryKey = ["profiles", user.id];

  const { data, isLoading, error } = useQuery(queryKey, fetchData); // Use the useQuery hook to fetch data
  const [currentPage, setCurrentPage] = useState(1); // Added state for current page
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const sortData = (data, column, order) => {
    return [...data].sort((a, b) => {
      if (column === "Type") {
        if (order === "asc") {
          return a[column].localeCompare(b[column]);
        } else {
          return b[column].localeCompare(a[column]);
        }
      } else if (column === "createdAt" || column === "updatedAt") {
        const dateA = new Date(a[column]);
        const dateB = new Date(b[column]);
        if (order === "asc") {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      }
      return 0;
    });
  };

  const [sortColumn, setSortColumn] = useState("Type");
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortClick = (column) => {
    if (column === sortColumn) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortColumn(column);
      setSortOrder("asc");
    }
  };

  const [showPopup, setShowPopup] = React.useState(false);
  const [selectedprofile, setSelectedprofile] = React.useState(null);

  const handleVoirClick = (profile) => {
    setSelectedprofile(profile);
    setShowPopup(!showPopup);
  };

  const onLogoutClick = (e) => {
    e.preventDefault();
    props.logoutUser();
  };

  if (isLoading) {
    return (
      <div>
        <Header
          isLoggedIn={isAuthenticated}
          user={user}
          onLogoutClick={onLogoutClick}
        />
        Loading...
      </div>
    ); // Display a loading message while data is being fetched
  }

  if (error) {
    return <div>Error fetching data from API: {error.message}</div>; // Display an error message if there's an error
  }

  // Calculate total number of pages based on data length and rows per page
  const totalPages = Math.ceil(data.length / rowsPerPage);

  // This function handles the change in page
  const handlePageChange = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  // Calculate the index range for current page
  const sortedData = sortData(data, sortColumn, sortOrder);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = Math.min(startIndex + rowsPerPage, sortedData.length);
  const visibleData = sortedData.slice(startIndex, endIndex);

  return (
    <div>
      <Header
        isLoggedIn={isAuthenticated}
        user={user}
        onLogoutClick={onLogoutClick}
      />
      <button
        className="btn btn-primary"
        type="button"
        data-bs-toggle="offcanvas"
        data-bs-target="#offcanvasExample"
        aria-controls="offcanvasExample"
      >
        Admin Dashboard
      </button>
      <AdminSideBar />
      <div style={{ padding: "20px" }}>
        <h1>Liste de profiles</h1>

        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Email</th>
              <th scope="col" onClick={() => handleSortClick("Type")}>
                Type
                {sortColumn === "Type" && (
                  <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                )}
              </th>
              <th scope="col" onClick={() => handleSortClick("createdAt")}>
                Date Ajout
                {sortColumn === "createdAt" && (
                  <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                )}
              </th>
              <th scope="col" onClick={() => handleSortClick("updatedAt")}>
                Date Modif
                {sortColumn === "updatedAt" && (
                  <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>
                )}
              </th>
              <th scope="col">Etat</th>
              <th scope="col">Voir</th>
            </tr>
          </thead>
          <tbody>
            {visibleData.map((item, index) => (
              <tr key={item.id}>
                <th scope="row">{index + 1}</th>
                <td>{item.User && item.User.Email ? item.User.Email : ""}</td>
                <td>{item.Type}</td>
                <td>{DateTimeConvertion(item.createdAt)}</td>
                <td>
                  {item.createdAt !== item.updatedAt
                    ? DateTimeConvertion(item.updatedAt)
                    : ""}
                </td>
                <td>{item.Verifie}</td>
                <td>
                  <button
                    onClick={() => handleVoirClick(item)}
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                  >
                    Voir
                  </button>
                </td>
              </tr>
            ))}
            <div>
              <select
                className="justify-content-left"
                style={{ width: "50px" }}
                onChange={(e) => {
                  setRowsPerPage(Number(e.target.value));
                  setCurrentPage(1);
                }}
              >
                <option value={5}>5</option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
              </select>

              <ul className="pagination justify-content-center">
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                  >
                    &laquo;
                  </button>
                </li>
                {Array.from({ length: totalPages }).map((_, index) => (
                  <li key={index} className="page-item">
                    <button
                      className={`page-link ${
                        index + 1 === currentPage ? "active" : ""
                      }`}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </button>
                  </li>
                ))}
                <li className="page-item">
                  <button
                    className="page-link"
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                  >
                    &raquo;
                  </button>
                </li>
              </ul>
            </div>
          </tbody>
        </table>
        <Popup profile={selectedprofile} onClose={() => setShowPopup(false)} />
      </div>
    </div>
  );
};

ProfilesList.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, { logoutUser })(ProfilesList);
