import React,{useState, useEffect} from "react";
import { PayPalButtons ,usePayPalScriptReducer,FUNDING} from "@paypal/react-paypal-js";
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;

const Pay = ({ total,userEmail,userId,Name,Prenom }) =>{
    useEffect(() => {
        console.log('Total in Pay component:', total);
        // Your payment logic here
      }, [total]);
    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();
    const [currency, setCurrency] = useState(options.currency);
    const navigate = useNavigate();

    const onCurrencyChange = ({ target: { value } }) => {
        setCurrency(value);
        dispatch({
            type: "resetOptions",
            value: {
                ...options,
                currency: value,
            },
        });
    }
    
    const onCreateOrder = (data,actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: total.toString(),
                    },
                     
                
                    description:` Email: ${userEmail} Nom: ${Name} Prenom: ${Prenom}`,
            
            
           
            
            
                   
                },
            ],
        });
    }

    const onApproveOrder = (data,actions) => {
        return actions.order.capture().then((details) => {
            const name = details.payer.name.given_name;
            
            navigate('/espace-vendeur/Validation');

            
        });
    }
    useEffect(() => {
        // Load PayPal SDK script
        const script = document.createElement('script');
        script.src = 'https://www.paypal.com/sdk/js?client-id=YOUR_CLIENT_ID&vault=true&intent=subscription';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const createSubscription = (data, actions) => {
        return actions.subscription.create({
            plan_id: 'P-5Y774273YA232221HMXTN4XA'
        });
    };

    const onApprove = (data, actions) => {
        alert(data.subscriptionID);
        // You can add additional handling here after subscription approval
    };


    return (
        <div className="checkout">
            {isPending ? <p>LOADING...</p> : (
                <>
                <div id="paypal-button-container-P-5Y774273YA232221HMXTN4XA">
            <PayPalButtons
                style={{ layout: 'horizontal', color: 'blue', shape: 'rect', label: 'paypal' }}
                createOrder={(data, actions) => onCreateOrder(data, actions)}
                        onApprove={(data, actions) => onApproveOrder(data, actions)}
                fundingSource={FUNDING.PAYPAL}
            />
        </div>
                    
                    
                </>
            )}
        </div>
    );}
export default Pay;