import React, { Component } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import { checkTokenExpiration } from "./actions/authActions";
import RouteList from "./route/RouteList";
import "./App.css";
import { QueryClient, QueryClientProvider } from "react-query";

// Check for token to keep the user logged in
checkTokenExpiration();

class App extends Component {
  render() {
    let queryClient = new QueryClient();
    return (
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Router>
            <div className="App">
              <RouteList />
            </div>
          </Router>
        </Provider>
      </QueryClientProvider>
    );
  }
}

export default App;
