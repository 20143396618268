import axios from "axios";

export const fetchData = async () => {
  // Define the fetchData function
  try {
    const response = await axios.get(
      process.env.REACT_APP_API_URL + "/api/blogs",
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching data from API: " + error.message);
  }
};

export const deleteBlog = async (id) => {
  try {
    const response = await axios.delete(
      process.env.REACT_APP_API_URL + "/api/blogs/" + id,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error deleting data from API: " + error.message);
  }
};
