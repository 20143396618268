import React, { Component } from 'react'
import './Validation.css'

export default class Validation extends Component {
  render() {
    return (
      <div  className="Backgroundvalidation">
         <div className='TextValidation'>
         ⚠️ Votre compte est en attente de validation. Restez à l'écoute ! Nous vous enverrons un e-mail dès que votre inscription sera approuvée. 
         </div>
      </div>
    )
  }
}
