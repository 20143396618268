import React from "react";
import "./LoadingScreen.css";

const LoadingScreen = (props) => {
  return (
    <div className="text-center">
      <button className="btn btn-primary text-center" type="button" disabled>
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
        Loading...
      </button>
    </div>
  );
};
export default LoadingScreen;
