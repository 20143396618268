
import React, { useState,useRef ,useEffect} from "react";
import './ModifierCompte.css'
import axios from "axios";

import { useNavigate } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";





const ModifierCompte = (props) => {
  const { user } = props.auth;
  
  const [formData, setFormData] = useState({
    nom: user.Profiles.Nom,
    prenom: user.Profiles.Prenom,
    address: "",
    numero: user.Profiles.Numero,
    email:user.Email,
    password: "",

    avatar: avatarUrl, // Added files property to store uploaded files
    
  });
  const [avatarUrl, setAvatarUrl] = useState(null);

  useEffect(() => {
    async function fetchAvatar() {
      try {
        const response = await axios.get(`/api/profiles/avatar/${user.Profiles.Type}`, {
          responseType: "arraybuffer",  // Indique à Axios de traiter la réponse comme un tableau d'octets (binaire)
        });

        // Convertit les données binaires en URL pour l'image
        const blob = new Blob([response.data], { type: response.headers["content-type"] });
        const url = URL.createObjectURL(blob);

        setAvatarUrl(url);
      } catch (error) {
        console.error("Error fetching avatar:", error);
      }
    }

    fetchAvatar();

    // Cleanup function (if needed)
    return () => {
      // Cleanup logic (if needed)
    };
  }, [user.Profiles.Acheteur]);
  
  
 
  const [error, setError] = useState(null);

  const previousController = useRef();
  
  
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();
  const onSubmit = async (e) => {
    e.preventDefault();
    const { nom, prenom, email, numero, password, avatar } = formData;
    
    const data = new FormData();
    data.append("Nom", nom);
    data.append("Prenom", prenom);
   
    data.append("Numero", numero);
    

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/profiles/${user.Profiles.Type}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",

            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      console.log("Form submitted");
      console.log("Response:", response.data);
    } catch (error) {
      console.error("An error occurred:", error);
    }

    
    const dataAv = new FormData();
  dataAv.append("Avatar", avatar[0]);  // Assurez-vous que 'avatar' est un tableau contenant le fichier
  try {
    const responseAvatar = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/profiles/avatar/${user.Profiles.Type}`,
      dataAv,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );

    console.log("Avatar updated successfully:", responseAvatar.data);
  } catch (error) {
    console.error("An error occurred while updating avatar:", error);
  }

  if(password != ""){

  const dataPas = new FormData();
  dataAv.append("Password", password);  // Assurez-vous que 'avatar' est un tableau contenant le fichier

  try {
    const responseAvatar = await axios.put(
      `${process.env.REACT_APP_API_URL}/api/users/updatepassword/${user.id}`,
      dataAv,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
        },
      }
    );

    console.log("Avatar updated successfully:", responseAvatar.data);
  } catch (error) {
    console.error("An error occurred while updating avatar:", error);
  }}
  navigate('/espace-vendeur/EditeProfile/EditValid');
};

  
  
  

  
  const [file, setFile]=useState();
  
  function getFile(event){
    setFile(URL.createObjectURL(event.target.files[0]))
    const { name, files } = event.target;
  setFormData((prevData) => ({
    ...prevData,
    [name]: files,
  }));
  }
  function deleteImage() {
    setFile(null);
  }
  
  
  const [isPasswordShow ,setIsPasswordVisible]=useState(false);
  
  return (
    <div style={{ marginTop: "1rem", width: "100%" }} className="row ">
      
      <div className=" BgCompte">
        
        <form noValidate onSubmit={onSubmit} enctype="multipart/form-data">
         
         
          <div className="row" style={{ marginLeft: "1%",marginTop:"70px" }}>
            <div className="col-11 col-lg-8 col-xl-8">
          <div className="row"
          >
            
          <div className="col-md-6 d-flex  justify-content-start flex-column">
            <div className="Attrib">Nom</div>
            <div >
            <input
            value={formData.nom}
              onChange={handleChange}
              type="text"
              label="Nom"
              id="nom"
              name="nom"
              className="login-input  "
              placeholder="son nom"
              />
              
              </div>   
          </div>
          <div className="col-md-6 d-flex  justify-content-start flex-column">
          <div className="Attrib">Prenom</div>
           <div > 
            <input
            value={formData.prenom}
           onChange={handleChange}
              type="text"
              label="Nom"
              id="prenom"
              name="prenom"
              className="login-input  "
              placeholder="son prenom"
              />
              
              </div>
          </div>
          </div>
          <div className="row">
            
          <div className="col-md-6 d-flex  justify-content-start flex-column">
          <div className="Attrib">Email</div>
          <input 
          name="Email"
          value={formData.email}
           
            
            id="email"
            type="email"
            className="login-input"
            placeholder="son mail"
          />
          </div>
          <div className="col-md-6 d-flex justify-content-start flex-column" enctype="multipart/form-data">
          <div className="Attrib">Téléphone</div>
          <input 
           value={formData.numero}
           onChange={handleChange}
           placeholder="numéro de telephone" 
           name="numero"
           className="login-input" type="tel" id="numero"  pattern="[0-9]{8}" required />
          </div>
          </div>
          <div className="col-12 d-flex justify-content-start flex-column">
          <div className="Att1">Mot de Passe</div>
          <div>
          <input
            onChange={handleChange}
            name="password"
            
            
            id="password"
            type={ isPasswordShow ? 'text' : 'password'}
            className="login-input1"
            placeholder="Votre mot de passe"
          />
          
          </div>
         
          </div>
          <br /><br />
          
          
          </div>
          <div className="col-4 col-lg-4 col-xl-4">
            <div className="EditImage">
            {file ? (
        // Display the selected file
        <img src={file} alt="Selected File" height="203px" width="209"/>
      ) : (
        // Display the avatarUrl if no file is selected
        <img src={avatarUrl} alt="Avatar" height="203px" width="209"/>
      )}
              
              <div className="ajouteImageText" > <label>
              
               <input
                
                 type="file"
                 name="avatar"
                 id="avatar-input"
                 accept="image/*"
                 onChange={getFile}
                 
               /> Modifier Votre photo
               
             </label></div>
              <div className="SupprimePhototxt"  onClick={deleteImage}>Supprimer votre photo</div>

            </div>
            </div>
            </div>
            {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
          <div className="col-12 col-lg-8 col-xl-8 d-grid gap-2">
            <button type="submit" className="btn button" onClick={onSubmit}>
              <div>Valider</div>
            </button>
          </div>
          
        </form>
      </div>
    </div>
  );

  
};
ModifierCompte.propTypes = {
  auth: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  
});

export default connect(mapStateToProps)(ModifierCompte);

