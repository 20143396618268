import React, { useState } from "react";
import "./GestionCompte.css";
import Validation from "./validation";
import EmailValidation from "./EmailValidation";

const EmailAndPasswordForm = ({ user }) => {
  const [showEmail, setShowEmail] = useState(true);
  const [showFormEmail, setShowFormEmail] = useState(false);
  const [buttonTextEmail, setButtonTextEmail] = useState("Modifier ");
  const [newTextEmail, setNewTextEmail] = useState("");
  const [buttonIconEmail, setButtonIconEmail] = useState("bi bi-arrow-down");

  const [showPassword, setShowPassword] = useState(true);
  const [showFormPassword, setShowFormPassword] = useState(false);
  const [buttonTextPassword, setButtonTextPassword] = useState("Modifier");
  const [buttonIconPassword, setButtonIconPassword] =
    useState("bi bi-arrow-down");

  const toggleEmail = () => {
    setShowEmail(!showEmail);
    setShowFormEmail(!showFormEmail);
    setButtonTextEmail(showEmail ? "Annuler" : "Modifier");
    setButtonIconEmail(showEmail ? "bi bi-arrow-up" : "bi bi-arrow-down");
    setNewTextEmail(
      "La nouvelle adresse e-mail servira à la fois de nom d'utilisateur et de nouvelle adresse de contact pour les agences et les particuliers"
    );
  };

  const togglePassword = () => {
    setShowPassword(!showPassword);
    setShowFormPassword(!showFormPassword);
    setButtonTextPassword(showPassword ? "Annuler" : "Modifier");
    setButtonIconPassword(showPassword ? "bi bi-arrow-up" : "bi bi-arrow-down");
  };

  const renderFormEmail = () => {
    return <EmailValidation userid={user.id} />;
  };

  const renderFormPassword = () => {
    return <Validation userid={user.id} />;
  };

  return (
    <div>
      <div className="lg-offset-2">
        <h5 className="pt-3">Adresse e-mail</h5>
        <div className="d-flex justify-content-end align-items-start align-items-md-center">
          <button className="edit-button " onClick={toggleEmail}>
            {buttonTextEmail}
            <i className={buttonIconEmail}></i>
          </button>
        </div>
        {showEmail ? (
          <div className="email-content">
            <p id="emailText">{user.Email}</p>
            <hr className="my-2" />
          </div>
        ) : (
          <div>
            <h7>{newTextEmail}</h7>
            {renderFormEmail()}
          </div>
        )}

        <h5 className="pt-3">Mot de passe</h5>
        <div className="d-flex justify-content-end align-items-start align-items-md-center">
          <button className="edit-button" onClick={togglePassword}>
            {buttonTextPassword} <i className={buttonIconPassword}></i>{" "}
          </button>
        </div>
        {showPassword ? (
          <div className="email-content">
            **********
            <hr className="my-2" />
          </div>
        ) : (
          <div>{renderFormPassword()}</div>
        )}
      </div>
    </div>
  );
};

export default EmailAndPasswordForm;
