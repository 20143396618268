import React from "react";
import axios from "axios";
import "./pop-up.css";

const Popup = (props) => {
  const [motif, setMotif] = React.useState("");
  const updateProfile = async (status) => {
    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/profiles/verify/${props.profile._id}/${status}`,
        { motif: motif }, // Include the motif data in the request body
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );

      if (response.ok) {
        // The demand was successfully updated
        console.log(`Profile ${props.profile._id} updated to ${status}`);
        // Perform any necessary actions after the update
      }
    } catch (error) {
      console.error(error);
      // Handle the error or show an error message to the user
    }
  };

  const addMotif = async (status) => {
    if (motif === "") {
      alert("Veuillez saisir le motif de refus");
      document.getElementById("motif").style.display = "block";
    } else {
      alert("Motif ajouté avec succés");
      updateProfile(status);
      document.getElementById("motif").style.display = "none";
      setMotif("");
    }
  };

  return (
    <div
      className="modal"
      id="exampleModal"
      aria-labelledby="exampleModalLabel"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Details de Profile</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={props.onClose} // Optional: Add an event handler to close the pop-up
            ></button>
          </div>
          <div className="modal-body">
            <label
              className="custom-file-upload"
              style={{ width: "150px", height: "160px" }}
            >
              <img
                src={
                  props.profile === null
                    ? ""
                    : `http://localhost:5000/api/profiles/avatar/${props.profile._id}`
                }
                className="rounded-circle"
                style={{ width: "130px", height: "130px" }}
                alt="Avatar"
                id="avatar"
              />
              Custom Upload
            </label>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Email:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={
                  props.profile === null
                    ? ""
                    : props.profile.User.Email === null
                    ? ""
                    : props.profile.User.Email
                }
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Nom:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.profile === null ? "" : props.profile.Nom}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Prenom:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.profile === null ? "" : props.profile.Prenom}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Numero:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.profile === null ? "" : props.profile.Numero}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="recipient-name" className="col-form-label">
                Adresse:
              </label>
              <input
                type="text"
                className="form-control"
                id="recipient-name"
                value={props.profile === null ? "" : props.profile.Adresse}
              />
            </div>
            {props.profile !== null && props.profile.Type === "Vendeur" ? (
              <div className="mb-3">
                <label htmlFor="message-text" className="col-form-label">
                  Carte d'identité:
                </label>
                <a
                  className="form-control"
                  id="message-text"
                  rel="noreferrer"
                  target="_blank"
                  href={
                    props.profile === null
                      ? ""
                      : `http://localhost:5000/api/profiles/idcard/${props.profile._id}`
                  }
                >
                  {props.profile.CarteIdentite}
                </a>
              </div>
            ) : (
              ""
            )}

            <div className="mb-3" id="motif" style={{ display: "none" }}>
              <label htmlFor="recipient-name" className="col-form-label">
                Motif:
              </label>
              <textarea
                className="form-control"
                onChange={(e) => setMotif(e.target.value)}
                name="motif"
                id="recipient-name"
              />
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              onClick={props.onClose} // Optional: Add an event handler to close the pop-up
            >
              Close
            </button>
            {props.profile !== null &&
            props.profile.Verifie !== "Validé" &&
            props.profile.Type === "Vendeur" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => updateProfile("V")}
              >
                Accepter
              </button>
            ) : (
              ""
            )}

            {props.profile !== null &&
            props.profile.Verifie !== "Refusé" &&
            props.profile.Type === "Vendeur" ? (
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => addMotif("R")}
              >
                Refuser
              </button>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;
