import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useTranslation } from "react-i18next";
import "./HomeSlider.css";

import iconetape1 from "./images/icon-etape1.svg";
import iconetape2 from "./images/icon-etape2.svg";
import iconetape3 from "./images/icon-etape3.svg";

const HomeSlider = () => {
  //translate
  const { t } = useTranslation();
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1080,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="row">
      <div className="col-sm-12 col-lg-12 col-md-12">
        <div className="row">
          <div className="col-sm-12 col-lg-12 col-md-12 my-1">
            <div className="row">
              <div className="col-sm-12 col-lg-12 col-md-12 ">
                <Slider {...settings}>
                  <div className="slider-item">
                    <div className="slidercarousel-block1 slidercarousel-item-style">
                      <div className="slidercarousel-icon">
                        <img src={iconetape1} alt="icon etape" />
                      </div>
                      <div className="slidercarousel-title">
                        <h3>Sélectionnez votre siège social</h3>
                      </div>
                      <div className="slidercarousel-content">
                        <p>
                          Plus de 80 adresses de prestige pour votre siège
                          social à Paris et partout en France.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="slider-item">
                    <div className="slidercarousel-block2 slidercarousel-item-style">
                      <div className="slidercarousel-icon">
                        <img src={iconetape2} alt="icon etape" />
                      </div>
                      <div className="slidercarousel-title">
                        <h3>Choisissez votre option courrier</h3>
                      </div>
                      <div className="slidercarousel-content">
                        <p>
                          Numérisation en continu ou réexpédition du courrier,
                          sélectionnez l’option qui vous convient le mieux.
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="slider-item">
                    <div className="slidercarousel-block3 slidercarousel-item-style">
                      <div className="slidercarousel-icon">
                        <img src={iconetape3} alt="icon etape" />
                      </div>
                      <div className="slidercarousel-title">
                        <h3>Obtenez votre contrat</h3>
                      </div>
                      <div className="slidercarousel-content">
                        <p>
                          Obtenez votre contrat rapidement et signez-le en
                          ligne, sans frais de dossier.
                        </p>
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSlider;
