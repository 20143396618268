import React, { useState } from "react";
import axios from "axios";
import "./Footer.css";
import logo from "../../images/logo.png";
import { useTranslation } from "react-i18next";

import message from "./images/ic-mail.svg";
import telephone from "./images/ic-phone.svg";
import localisation from "./images/ic-location.svg";

function Footer() {
  const { t } = useTranslation();
  // State to handle the Email input and success message
  const [Email, setEmail] = useState("");
  const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
  const [subscriptionError, setSubscriptionError] = useState("");

  // Function to handle the newsletter subscription
  const handleSubscribe = async () => {
    try {
      //validate the email
      if (!Email) {
        setSubscriptionError(0);
        setSubscriptionSuccess(true);
        return;
      }
      //Check Email Format
      let re = /\S+@\S+\.\S+/;
      if (!re.test(Email)) {
        setSubscriptionError(1);
        setSubscriptionSuccess(true);
        return;
      }

      // Replace 'YOUR_API_ENDPOINT' with the actual API endpoint URL for subscribing
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/newsletter/subscribe`,
        { Email: Email },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
          },
        }
      );
      console.log("response:", response.data);

      // On successful subscription, set the success message state
      setSubscriptionSuccess(true);
      setSubscriptionError(response.data.message);
    } catch (error) {
      console.error("Failed to subscribe to the newsletter:", error);
    }
  };

  return (
    <footer className="footer footer-color pt-5">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="d-flex align-items-center mb-2">
              <img className="ft-logo" src={logo} alt="ft logo " />
            </div>
            <p className="poppins medium-text mb-5">
              {t(
                "footer.join us on our networks to share with the community and follow the latest news."
              )}
            </p>
            <div className="ft-socialmedia pt-2">
              <a href="www.twitter.com" className="ft-twitter">
                <span className="sr-only"></span>
              </a>
              <a href="www.facebook.com" className="ft-facebook">
                <span className="sr-only"></span>
              </a>
              <a href="www.linkedin.com" className="ft-linkedin">
                <span className="sr-only"></span>
              </a>
              <a href="www.instagram.com" className="ft-instagram">
                <span className="sr-only"></span>
              </a>
              <a href="www.pinterest.com" className="ft-pinterest">
                <span className="sr-only"></span>
              </a>
              <a href="www.skype.com" className="ft-skype">
                <span className="sr-only"></span>
              </a>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <h3 className="title-underline poppins mt-5 mb-3">
              {t("footer.about")}
            </h3>
            <ul>
              <li className="ft-item">
                <a href="/apropos">
                  <span>&bull;</span> {t("footer.who we are ?")}
                </a>
              </li>
              <li className="ft-item">
                <a href="/apropos">
                  <span>&bull;</span> {t("footer.join us")}
                </a>
              </li>
              <li className="ft-item">
                <a href="/apropos">
                  <span>&bull;</span> {t("footer.partnership")}
                </a>
              </li>
              <li className="ft-item">
                <a href="/apropos">
                  <span>&bull;</span> {t("footer.press")}
                </a>
              </li>
              <li className="ft-item">
                <a href="/apropos">
                  <span>&bull;</span> {t("footer.ggu / legal notice")}
                </a>
              </li>
            </ul>
          </div>
          <div className="col-lg-3 col-md-6">
            <h3 className="title-underline poppins mt-5 mb-3">
              {t("footer.contact")}
            </h3>
            <div className="contact-info">
              <ul>
                <li className="d-flex flex-row align-items-start mb-4">
                  <img src={localisation} alt={t("footer.contact_adress")} />
                  <span className="poppins ft-sptext pt-1">
                    {t("footer.contact_adress")}
                  </span>
                </li>
                <li className="d-flex flex-row align-items-start mb-4">
                  <img src={telephone} alt={t("footer.contact_adress")} />
                  <span className="poppins ft-sptext pt-1">
                    <a href={`tel:${t("footer.contact_phone_link")}`}>
                      {t("footer.contact_phone")}
                    </a>
                  </span>
                </li>
                <li className="d-flex flex-row align-items-start mb-4">
                  <img src={message} alt={t("footer.contact_adress")} />
                  <span className="poppins ft-sptext pt-1">
                    <a href={`mailto:${t("footer.contact_mail_link")}`}>
                      {t("footer.contact_mail")}
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-6 ">
            <h3 className="title-underline poppins mt-5 mb-3">
              {t("footer.newsletter")}
            </h3>
            <div className="newsletter-input input-rectangle">
              <input
                type="Email"
                name="ftnewsletter"
                className="ft-newsletter-input"
                value={Email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>

            <button
              className="ft-newsletter-btn mb-2"
              onClick={handleSubscribe}
            >
              {t("footer.subscribe")}
            </button>
            {subscriptionSuccess && (
              <p className="poppins ft-sptext">
                {t("footer." + subscriptionError)}
              </p>
            )}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
