import React, { useState, useEffect } from "react";
import "./LatestBlog.css";

import { Link } from "react-router-dom";

const LatestBlog = (props) => {
  return (
    <section className="latest-blog">
      <div className="blogs container">
        <div className="label">
          <div className="h2-blogs">Latest Articles</div>
        </div>
        {props.blogs.map((blog) => (
          <div className="row" key={blog._id}>
            <div class="col-lg-12 col-md-12">
              <div className="blog" style={{ width: "100%" }}>
                <img
                  className="image"
                  alt={blog.Title}
                  src={`${process.env.REACT_APP_API_URL}/api/blogs/get-blog-image/${blog._id}`}
                />
                <p className="november">
                  {new Date(blog.createdAt).toLocaleDateString()} |{" "}
                  {blog.Category}
                </p>
                <Link to={`/blog/${blog._id}`} rel="noopener noreferrer">
                  <p className="text-wrapper">{blog.Title}</p>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default LatestBlog;
